import React, { ChangeEvent, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import MDButton from 'material-ui/components/MDButton';
import DeleteIcon from 'assets/icons/delete';

import { Box } from '@mui/system';
import { useFilters } from 'pages/smart-automation-upsert/context';
import { IOperator, IShopifyDataSourceFilter } from 'services/smart-automation/smart-automation.types';
import { Chip, Grid, IconButton, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';


import * as Yup from 'yup';

const aggregateFilterSchema = Yup.object().shape({
  dataSourceFilter: Yup.object().shape({
    id: Yup.string().required('Required'),
  }),
  operator: Yup.object().shape({
    id: Yup.number().required('Required'),
  }),
  value: Yup.string().when('operator.id', {
    // IsNotBlank or AllTime
    is: (id: number) => id !== 8 && id !== 12,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired(),
  })
  .strict()
  .nullable(false)
  .defined(),
  integrationOrderFilters: Yup.array().of(
    Yup.object().shape({
      dataSourceFilter: Yup.object().shape({
        id: Yup.string().required('Required'),
      }),
      operator: Yup.object().shape({
        id: Yup.number().required('Required'),
      }),
      value: Yup.string().when('operator.id', {
        is: (id: number) => id !== 8 && id !== 12,
        then: Yup.string().required('Required'),
        otherwise: Yup.string().notRequired(),
      })
      .strict()
      .nullable(false)
      .defined(),
    })
  ).notRequired(),
});

const filterSchema = Yup.object().shape({
  dataSourceFilter: Yup.object().shape({
    id: Yup.string().required('Required'),
  }),
  operator: Yup.object().shape({
    id: Yup.number().required('Required'),
  }),
  value: Yup.string().when('operator.id', {
    // IsNotBlank or AllTime
    is: (id: number) => id !== 8 && id !== 12,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired(),
  })
  .strict()
  .nullable(false)
  .defined(),
  aggregateFilters: Yup.array().of(aggregateFilterSchema).notRequired(),
});

interface INewShopifyFilters {
  initialData: any,
  canEdit: boolean
}

const NewShopifyFilters: React.FC<INewShopifyFilters> = ({ initialData, canEdit }) => {

  const SINGLE_PURCHASE_CUSTOMER_OPTION = 'SinglePurchaseCustomer';
  const ALL_TIME_OPTION = 12;
  const IS_NOT_BLANK_OPTION = 'IsNotNull';
  const IS_WITHIN_LAST_DAYS = 'IsWithinLastDays';
  const IS_PRIOR_LAST_DAYS = 'IsPriorToLastDays';

  const { setShopifyIntegrationOrderFilters } = useFilters();

  const {
    filterOptions,
    shopifyIntegrationOrderFilters,
    handleAddFilter,
    handleAddAnd,
    handleAddOr,
    handleRemoveFilter,
    handleColumnChange,
    handleOperatorChange,
    handleValueChange,
    handleAggregateColumnChange,
    handleAggregateValueChange,
    handleAggregateOperatorChange,
    handleRemoveAggregate,
    handleAddAggregateAnd,
    handleAddAggregateOr
  } = useFilters();
  const [errors, setErrors] = useState<Record<string, string>>({});

  const hasManyOrGroups = shopifyIntegrationOrderFilters.length > 1

  const validateField = async (
    parentIndex: number,
    groupIndex: number,
    aggregateIndex?: number,
    idx?: number
  ) => {
    let targetFilter;
  
    if (aggregateIndex !== undefined) {
      targetFilter =
        shopifyIntegrationOrderFilters[parentIndex]?.integrationOrderFilters[groupIndex]?.dataSourceFilter
          ?.aggregateFilters?.[aggregateIndex]?.integrationOrderFilters?.[idx || 0];
    } else {
      targetFilter = shopifyIntegrationOrderFilters[parentIndex]?.integrationOrderFilters[groupIndex];
    }
  
    if (!targetFilter) {
      setErrors((prev) => ({
        ...prev,
        [`${parentIndex}-${groupIndex}${aggregateIndex !== undefined ? `-aggregate-${aggregateIndex}` : ''}`]:
          aggregateIndex !== undefined ? 'Aggregate filter is missing.' : 'Filter is missing.',
      }));
      return false;
    }
  
    try {
      const schema = aggregateIndex !== undefined ? aggregateFilterSchema : filterSchema;
      await schema.validate(targetFilter, { abortEarly: false });
      setErrors((prev) => ({
        ...prev,
        [`${parentIndex}-${groupIndex}${aggregateIndex !== undefined ? `-aggregate-${aggregateIndex}` : ''}`]: '',
      }));
      sessionStorage.setItem('hasFilterErrors', JSON.stringify(false));
      return true;
    } catch (err) {
      const yupError = err as Yup.ValidationError;
      if (yupError instanceof Yup.ValidationError) {
        setErrors((prev) => ({
          ...prev,
          [`${parentIndex}-${groupIndex}${aggregateIndex !== undefined ? `-aggregate-${aggregateIndex}` : ''}`]:
            yupError.errors[0],
        }));
        sessionStorage.setItem('hasFilterErrors', JSON.stringify(true));
      }
      return false;
    }
  };
  
  const handleBlur = (parentIndex: number, groupIndex: number, aggregateIndex?: number, idx?: number) => {
    validateField(parentIndex, groupIndex, aggregateIndex, idx);
  };

  const sortByName = (data: IShopifyDataSourceFilter[]) => {
    if (!data) return;
  
    return data.sort((a, b) => (a.operationName || "").localeCompare(b.operationName || ""));
  };
  
  useEffect(() => {
    if(!initialData) return
    setShopifyIntegrationOrderFilters(initialData.userCreatedFilters)
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData])

  useEffect(() => {
    const validateAllFields = async () => {
      for (let groupIndex = 0; groupIndex < shopifyIntegrationOrderFilters.length; groupIndex++) {
        const group = shopifyIntegrationOrderFilters[groupIndex];
        for (let index = 0; index < group.integrationOrderFilters.length; index++) {
          await validateField(groupIndex, index);
        }
      }
    };

    validateAllFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopifyIntegrationOrderFilters]); 

  return (
    <React.Fragment>
        {shopifyIntegrationOrderFilters.length === 0 && (
          <Box>
            <MDButton sx={{ width: 200 }} onClick={handleAddFilter}>
              Add Filter
            </MDButton>
          </Box>
        )}
        
        {
          shopifyIntegrationOrderFilters.length > 0 && (
            <React.Fragment>
              {shopifyIntegrationOrderFilters.filter(i => i.integrationOrderFilters.length > 0).map((filterGroup, parentIndex) => (
                <Box key={parentIndex} sx={{ border: '1px solid rgba(0,0,0,.3)', background: '#fbfbfb', borderRadius: '5px', padding: '1rem', position: 'relative'}}>
                  { hasManyOrGroups && (
                    <Grid item width={54} sx={{ position: 'absolute', top: '25px' }}>
                      {parentIndex !== 0 && <Chip clickable={false} label="OR" color="primary" size="medium" sx={{ marginTop: -15, marginBottom: '-35px', width: '100%', fontWeight: 700 }} />}
                    </Grid>
                  )}
                  <Grid container gap={1} display={{ xs: "none", lg: "flex", marginLeft: '3.8rem' }}>
                    <Grid item width={'465px'}>
                      <Typography variant="h6">Filter</Typography>
                    </Grid>
                    <Grid item width={'277px'}>
                      <Typography variant="h6">Operator</Typography>
                    </Grid>
                    <Grid item flex={1} >
                      <Typography variant="h6">Value</Typography>
                    </Grid>
                  </Grid>
                  {filterGroup.integrationOrderFilters.map((filter, groupIndex) => (
                    <Box key={groupIndex} sx={{ marginBottom: '12px'}} >
                      <Grid container gap={1} >
                        {groupIndex > 0 && (
                          <Grid item width={54}>
                            <Chip
                              label="AND"
                              clickable={false}
                              color="secondary"
                              sx={{ marginTop: -6, fontWeight: 700 }}
                            />
                          </Grid>
                        )}
                        <Grid item lg={5} width={'400px'} ml={groupIndex === 0 ? '3.85rem' : 0}>
                          <Select
                            value={filter.dataSourceFilter?.id || ''}
                            aria-label="Column"
                            disabled={filter.dataSourceFilter?.aggregateFilters !== undefined || !canEdit}
                            onChange={(event) => {
                              handleColumnChange(parentIndex, groupIndex, event);
                            }}
                            onBlur={() => handleBlur(parentIndex, groupIndex)}
                            error={!!errors[`${parentIndex}-${groupIndex}`] && !filter.dataSourceFilter?.id}
                            >
                            <MenuItem value="" disabled>
                              Select a column
                            </MenuItem>
      
                            {sortByName(filterOptions)?.map(({ id, operationName }) => (
                              <MenuItem key={id} value={id || ''}>
                                {operationName}
                              </MenuItem>
                            ))}
                          </Select>
                          {(errors[`${parentIndex}-${groupIndex}`] && !filter.dataSourceFilter?.id) && (
                            <Typography variant="caption" color="error" sx={{ display: 'block', position: 'absolute'}}>
                              {errors[`${parentIndex}-${groupIndex}`]}
                            </Typography>
                          )}
                        </Grid>
      
                        <Grid item lg={3} width={'285px'} position={'relative'}>
                          <Select
                            value={filter.operator?.id || ''}
                            aria-label="Operator"
                            onChange={(event) => {
                              handleOperatorChange(parentIndex, groupIndex, event);
                            }}
                            onBlur={() => handleBlur(parentIndex, groupIndex)}
                            error={!!errors[`${parentIndex}-${groupIndex}`] && !filter.operator?.id}
                            disabled={
                              (filter.dataSourceFilter?.name === IS_WITHIN_LAST_DAYS || filter.dataSourceFilter?.name === IS_PRIOR_LAST_DAYS) ||
                              filter.dataSourceFilter?.name === SINGLE_PURCHASE_CUSTOMER_OPTION || 
                              !filter.dataSourceFilter || 
                              filter.dataSourceFilter.operatorOptions.length === 0 || 
                              !canEdit
                            }
                          >
                            <MenuItem value="" disabled>
                              Select an operator
                            </MenuItem>
                            {filter.dataSourceFilter?.operatorOptions?.map(({ id, operatorShortName }: IOperator) => (
                              <MenuItem key={id} value={id}>
                                {operatorShortName}
                              </MenuItem>
                            ))}
                          </Select>
                          {(errors[`${parentIndex}-${groupIndex}`] && !filter.operator?.id) && (
                            <Typography variant="caption" color="error" sx={{ display: 'block', position: 'absolute'}}>
                              {errors[`${groupIndex}-${groupIndex}`]}
                            </Typography>
                          )}
                        </Grid>
      
                        <Grid item width={'180px'}>
                          <TextField
                            aria-label="Value"
                            placeholder="Type your value here"
                            sx={{ width: '100%'}}
                            value={filter.value}
                            type={filter.dataSourceFilter?.valueType?.toLowerCase() || 'text'}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                              const newValue = event.target.value;
                              const valueType = filter.dataSourceFilter?.valueType?.toLowerCase();

                              if (valueType === "number") {
                                const numericValue = Number(newValue);

                                if (numericValue <= 0 && (filter.dataSourceFilter?.name === IS_WITHIN_LAST_DAYS || filter.dataSourceFilter?.name === IS_PRIOR_LAST_DAYS)) {
                                  return;
                                }
                              }

                              handleValueChange(parentIndex, groupIndex, event);
                            }}
                            onBlur={() => handleBlur(parentIndex, groupIndex)}
                            error={!!errors[`${parentIndex}-${groupIndex}`] && !filter.value}
                            disabled={filter.dataSourceFilter?.name === SINGLE_PURCHASE_CUSTOMER_OPTION || (filter.operator?.operator === IS_NOT_BLANK_OPTION || filter.operator?.id === ALL_TIME_OPTION) || !filter.operator  || !canEdit}
                          />
                           {(errors[`${parentIndex}-${groupIndex}`] && !filter.value) && (filter.operator?.operator !== IS_NOT_BLANK_OPTION && filter.operator?.id !== ALL_TIME_OPTION) &&(
                            <Typography variant="caption" color="error" sx={{ display: 'block', position: 'absolute'}}>
                                {errors[`${groupIndex}-${groupIndex}`]}
                              </Typography>
                            )}
                        </Grid>
      
                        <Grid item>
                          <IconButton 
                            disabled={!canEdit}
                            sx={{ 
                              marginLeft: '-8px', 
                              opacity: !canEdit ? 0.6 : 1,
                              cursor: !canEdit ? 'not-allowed !important' : 'pointer', }} size="large" onClick={() => canEdit && handleRemoveFilter(parentIndex, groupIndex)}>
                            <DeleteIcon  sx={{ opacity: !canEdit ? 0.6 : 1, cursor: !canEdit ? 'not-allowed !important' : 'pointer',
                            }}/>
                          </IconButton>
                        </Grid>
                      </Grid>
      
                      {filter?.dataSourceFilter?.aggregateFilters && (
                        <Box
                          sx={{
                            padding: '1rem 1rem',
                            borderRadius: '5px',
                            background: '#fdfdfd',
                            border: filter?.dataSourceFilter?.aggregateFilters ? '1px solid rgba(0,0,0,.2)' : 'none',
                            margin: '1rem 3.8rem'
                          }}
                        >
                          {filter?.dataSourceFilter?.aggregateFilters?.length > 0 &&
                            filter?.dataSourceFilter?.aggregateFilters.map((aggregate, aggregateIndex) => (
                              <Box key={aggregateIndex} sx={{ position: 'relative', border: '1px solid rgba(0,0,0,.15)', background: 'white', borderRadius: '5px', marginBottom: '.5rem', padding: '1rem 0 0 1rem'}}>
                                { filter?.dataSourceFilter?.aggregateFilters  && filter?.dataSourceFilter?.aggregateFilters.length > 1 && (
                                  <Grid item width={54} sx={{ position: 'absolute', top: '25px' }}>
                                    { aggregateIndex !== 0 && <Chip clickable={false} label="OR" color="primary" size="medium" sx={{ marginTop: -15, marginBottom: '-35px', width: '100%', fontWeight: 700 }} />}
                                  </Grid>
                                )}
                                {
                                  groupIndex === 0 && (
                                    <Grid container gap={1} display={{ xs: "none", lg: "flex", marginLeft: '3.8rem', marginBottom: '.5rem' }}>
                                    <Grid item width={'387px'}>
                                      <Typography variant="h6">Filter</Typography>
                                    </Grid>
                                    <Grid item width={'280px'}>
                                      <Typography variant="h6">Operator</Typography>
                                    </Grid>
                                    <Grid item flex={1} >
                                      <Typography variant="h6">Value</Typography>
                                    </Grid>
                                  </Grid>        
                                  )
                                }
                                {aggregate.integrationOrderFilters?.length > 0 &&
                                  aggregate.integrationOrderFilters.map((f, idx) => (
                                    <Grid container gap={1} key={idx} mb={1.5}>
                                      {idx > 0 && (
                                        <Grid item width={54}>
                                          <Chip
                                            label="AND"
                                            clickable={false}
                                            color="secondary"
                                            sx={{ marginTop: -6, fontWeight: 700 }}
                                          />
                                        </Grid>
                                      )}
                                      <Grid item width={'365px'} sx={{ marginLeft: idx === 0 ? '3.8rem' : 0 }}>
                                        <Select
                                          value={f.dataSourceFilter?.id || ''}
                                          aria-label="Column"
                                          disabled={(idx === 0 && f.dataSourceFilter?.name === 'FixedDate') || !canEdit}
                                          onChange={(event) => handleAggregateColumnChange(parentIndex, groupIndex, aggregateIndex, idx, event)}
                                          onBlur={() => validateField(parentIndex, groupIndex, aggregateIndex, idx)}
                                          error={!!errors[`${parentIndex}-${groupIndex}-aggregate-${aggregateIndex}`] && !f.dataSourceFilter?.id}
                                        >
                                          <MenuItem value="" disabled>
                                            Select a column
                                          </MenuItem>
                                          {filterOptions
                                            ?.filter((i) =>  i.canBeUsedByParentAggregate)
                                            ?.map(({ id, operationName }) => (
                                              <MenuItem key={id} value={id || ''}>
                                                {operationName}
                                              </MenuItem>
                                            ))}
                                        </Select>
                                        {(errors[`${parentIndex}-${groupIndex}-aggregate-${aggregateIndex}`] && !f.dataSourceFilter?.id) && (
                                          <Typography
                                            variant="caption"
                                            color="error"
                                            sx={{ display: 'block', position: 'absolute' }}
                                          >
                                            {errors[`${parentIndex}-${groupIndex}-aggregate-${aggregateIndex}`]}
                                          </Typography>
                                        )}
                                      </Grid>
                                      <Grid item width={'279px'}>
                                        <Select
                                          value={f.operator?.id || ''}
                                          aria-label="Operator"
                                          onChange={(event) => handleAggregateOperatorChange(parentIndex, groupIndex, aggregateIndex, idx, event)}
                                          onBlur={() => validateField(parentIndex, groupIndex, aggregateIndex, idx)}
                                          error={!!errors[`${parentIndex}-${groupIndex}-aggregate-${aggregateIndex}`] && !f.operator?.id}
                                          disabled={(!f.dataSourceFilter || f.dataSourceFilter.operatorOptions.length === 0) || (f.dataSourceFilter?.name === IS_WITHIN_LAST_DAYS || f.dataSourceFilter?.name === IS_PRIOR_LAST_DAYS) || !canEdit}
                                        >
                                          <MenuItem value="" disabled>
                                            Select an operator
                                          </MenuItem>
                                          {f.dataSourceFilter?.operatorOptions?.map(({ id, operatorShortName }) => (
                                            <MenuItem key={id} value={id}>
                                              {operatorShortName}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                        {(errors[`${parentIndex}-${groupIndex}-aggregate-${aggregateIndex}`] && !f.operator?.id) && (
                                          <Typography
                                            variant="caption"
                                            color="error"
                                            sx={{ display: 'block', position: 'absolute' }}
                                          >
                                            {errors[`${parentIndex}-${groupIndex}-aggregate-${aggregateIndex}`]}
                                          </Typography>
                                        )}
                                      </Grid>
                                      <Grid item width={'170px'}>
                                        <TextField
                                          aria-label="Value"
                                          placeholder="Type your value here"
                                          value={f.value}
                                          sx={{ width: '100%' }}
                                          type={f.dataSourceFilter?.valueType?.toLowerCase() || 'text'}
                                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            const newValue = event.target.value;
                                            const valueType = f.dataSourceFilter?.valueType?.toLowerCase();

                                            if (valueType === "number") {
                                              const numericValue = Number(newValue);

                                              if (numericValue <= 0 && (f.dataSourceFilter?.name === IS_WITHIN_LAST_DAYS || f.dataSourceFilter?.name === IS_PRIOR_LAST_DAYS)) {
                                                return;
                                              }
                                            }

                                            handleAggregateValueChange(parentIndex, groupIndex, aggregateIndex, idx, event)
                                          }
                                            
                                          }
                                          onBlur={() => validateField(parentIndex, groupIndex, aggregateIndex, idx)}
                                          error={!!errors[`${parentIndex}-${groupIndex}-aggregate-${aggregateIndex}`] && !f.value}
                                          disabled={!f.operator || f.operator?.operator === IS_NOT_BLANK_OPTION || f.operator?.id === ALL_TIME_OPTION || !canEdit}
                                        />
                                        {(errors[`${parentIndex}-${groupIndex}-aggregate-${aggregateIndex}`] && !f.value) && (
                                          <Typography
                                            variant="caption"
                                            color="error"
                                            sx={{ display: 'block', position: 'absolute' }}
                                          >
                                            {errors[`${parentIndex}-${groupIndex}-aggregate-${aggregateIndex}`]}
                                          </Typography>
                                        )}
                                      </Grid>
                                      <Grid item sx={{ width: '25px' }}>
                                        <Tooltip title={aggregateIndex === 0 ? 'At least one "Any Order Date" filter is required for aggregate filters.' : ''}>
                                        <IconButton
                                          sx={{
                                            marginLeft: '-10px',
                                            opacity: aggregateIndex === 0 && f.dataSourceFilter?.id === 28 ? 0.6 : 1,
                                            cursor: aggregateIndex === 0 && f.dataSourceFilter?.id === 28 ? 'not-allowed !important' : 'pointer',
                                          }}
                                          size="large"
                                          disabled={(aggregateIndex === 0 && f.dataSourceFilter?.id === 28) || !canEdit}
                                          onClick={() => {
                                            if (!(aggregateIndex === 0 && f.dataSourceFilter?.id === 28)) {
                                              handleRemoveAggregate(parentIndex, groupIndex, aggregateIndex, idx);
                                            }
                                          }}
                                        >
                                          <DeleteIcon
                                            sx={{
                                              opacity: (!canEdit || (aggregateIndex === 0 && f.dataSourceFilter?.id === 28)) ? 0.6 : 1,
                                              cursor: (!canEdit ||  (aggregateIndex === 0 && f.dataSourceFilter?.id === 28)) ? 'not-allowed !important' : 'pointer',
                                            }}
                                          />
                                        </IconButton>
                                        </Tooltip>
                                      </Grid>
                                    {canEdit && filter?.dataSourceFilter?.aggregateFilters &&
                                      idx === aggregate.integrationOrderFilters?.length -1  && (
                                          <Box display={'flex'} gap={1} mt={1} ml={'3.8rem'}>
                                            <Chip
                                              label="AND"
                                              color="secondary"
                                              variant="outlined"
                                              component="button"
                                              onClick={() => handleAddAggregateAnd(parentIndex,groupIndex,aggregateIndex)}
                                              clickable
                                              sx={{ fontWeight: 700 }}
                                              icon={<AddIcon style={{ width: 20, height: 20 }} />}
                                            />
                                            <Chip
                                              label="OR"
                                              color="primary"
                                              variant="outlined"
                                              component="button"
                                              clickable
                                              onClick={() => handleAddAggregateOr(parentIndex, groupIndex)}
                                              sx={{ fontWeight: 700 }}
                                              icon={<AddIcon style={{ width: 20, height: 20 }} />}
                                            />
                                          </Box>
                                        )
                                      }
                                    </Grid>
                                  ))}
                              </Box>
                          ))}
                        </Box>
                      )}


                      {
                        groupIndex === filterGroup.integrationOrderFilters?.length - 1 && (
                          <Box sx={{ margin: '1.5rem 2rem 0 3.8rem'}}>
                            <Chip
                              label="AND"
                              color="secondary"
                              variant="outlined"
                              component="button"
                              clickable
                              sx={{ fontWeight: 700 }}
                              icon={<AddIcon style={{ width: 20, height: 20 }} />}
                              onClick={() => handleAddAnd(parentIndex)}
                            />
                          </Box>
                        )
                      }

                    </Box>
                  ))}
                </Box>
              ))}
      
              {canEdit && shopifyIntegrationOrderFilters.length > 0 && (
                <Box display={'flex'} gap={1} mt={1}>
                  <Chip
                    label="OR"
                    color="primary"
                    variant="outlined"
                    component="button"
                    clickable
                    sx={{ fontWeight: 700 }}
                    icon={<AddIcon style={{ width: 20, height: 20 }} />}
                    onClick={handleAddOr}
                  />
                </Box>
              )}
            </React.Fragment>
          )
        }
    </React.Fragment>
  );
};

export default NewShopifyFilters;
