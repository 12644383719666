
import { useEffect, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { IObjectResult } from 'services/endpoint';
import { loadStripe, PaymentMethod } from '@stripe/stripe-js';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IProfileNotification, NotificationTypeEnum } from 'models/profile';
import { Alert, Box, CircularProgress, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';

import usePaymentsService from 'newStandard/src/services/payments';
import MDButton from 'material-ui/components/MDButton';
import ProfileService from 'services/profile';
import PaymentManager from '../PaymentManager';

function FailedPaymentModalContent() {
  const queryClient = useQueryClient();
  const { getLoggedInProfile, setDefaultPricing } = ProfileService();
  const { retryPaymentWithNewMethod, getSubscriptionLink } = usePaymentsService();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | null>(null);
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);
  const [dialogDetails, setDialogDetails] = useState<IProfileNotification>();

  const { data: profile } = useQuery({ queryKey: ['loggedInProfile'], queryFn: getLoggedInProfile });

  const {
    data: linkData,
    isLoading,
  } = useQuery({
    queryKey: ['getSubscriptionLink'],
    queryFn: getSubscriptionLink,
    enabled: false,
  });

  //Here
  const onSuccess = (result: IObjectResult<{}>) => {
    if (result.hasErrors && result.errors) setErrorMessage(result.errors[0]);
    else queryClient.invalidateQueries({ queryKey: ['loggedInProfile'] });
  };

  const { mutate: cancelSubscription, isPending: isCanceling } = useMutation({
    mutationFn: () => setDefaultPricing(!shouldRedoSubscription),
    onSuccess,
  });

  const { mutate: retryPayment, isPending: isPaying } = useMutation({
    mutationFn: retryPaymentWithNewMethod,
    onSuccess,
  });

  const isDisabled = isCanceling || isPaying || isLoading;

  const shouldRedoSubscription =
    dialogDetails?.notificationType === NotificationTypeEnum.StripeSubscriptionInvoicePaymentFailed;

  useEffect(() => {
    const typesToShow = [
      NotificationTypeEnum.StripeSubscriptionPastDueFailed,
      NotificationTypeEnum.StripeSubscriptionInvoicePaymentFailed,
    ];
    setDialogDetails(profile?.notifications.find((n) => typesToShow.includes(n.notificationType) && !n.isViewed));
  }, [profile]);

  useEffect(() => {
    if (!shouldRedirect || !linkData) return;
    setShouldRedirect(false);
    if (linkData.hasErrors && linkData.errors) return setErrorMessage(linkData.errors[0]);
    queryClient.invalidateQueries({ queryKey: ['loggedInProfile'] });
    window.location.href = linkData.payload;
  }, [linkData, shouldRedirect, queryClient]);

  const handleSubmit = async () => {
    retryPayment(selectedPaymentMethod?.id ?? '');
  };

  return (
    <Dialog open={!!dialogDetails}>
      <DialogContent>
        <DialogTitle sx={{ mb: 1 }}>{dialogDetails?.title}</DialogTitle>
        <Typography fontSize={16} mb={1}>
          {dialogDetails?.message}
        </Typography>
        {!shouldRedoSubscription && (
            <PaymentManager data={null} handleSelectedPayment={(value) => {setSelectedPaymentMethod(value)} } />
        )}
        {errorMessage && (
          <Alert severity="error" sx={{ mt: 1 }} onClose={() => setErrorMessage('')}>
            {errorMessage}
          </Alert>
        )}
        <Box display={'flex'} gap={1} mt={2}>
          {shouldRedoSubscription && (
            <MDButton fullWidth color="light" disabled={isDisabled} onClick={() => cancelSubscription()}>
              {isCanceling ? <CircularProgress size={18} color="inherit" /> : 'Cancel Subscription'}
            </MDButton>
          )}
          <MDButton
            fullWidth
            onClick={() => handleSubmit()}
            disabled={!selectedPaymentMethod || isDisabled}
          >
            {isPaying || isLoading ? <CircularProgress size={18} color="inherit" /> : 'Confirm'}
          </MDButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default function FailedPaymentModal() {
  return (
    <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? '')}>
      <FailedPaymentModalContent />
    </Elements>
  );
}
