import React, { useState } from 'react';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { saveAs } from "file-saver";

import MDBox from 'material-ui/components/MDBox';
import MDCard from 'material-ui/components/MDCard';
import MDButton from 'material-ui/components/MDButton';
import MDTypography from 'material-ui/components/MDTypography';
import EnhancedTable from './table';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const exportTransactionsToCSV = (jsonData: {createdDate: string, transactionType: string, notes: string, amount: string}[], fileName: string = "TransactionSummary") => {
  if (!jsonData || jsonData.length === 0) {
    return;
  }

  // 🔹 Mapeamento de colunas formatadas
  const columnMapping: { [key: string]: string } = {
    createdDate: "Transaction Date",
    transactionType: "Transaction Type",
    notes: "Notes",
    amount: "Amount",
  };

  // 🔹 Garante que os cabeçalhos do CSV sejam os nomes formatados
  const csvContent = [
    Object.keys(jsonData[0]).map((key) => `"${columnMapping[key] || key}"`).join(","), // Cabeçalhos formatados
    ...jsonData.map((row) =>
      Object.values(row)
        .map((value) => `"${String(value).replace(/"/g, '""')}"`) // Escapa aspas duplas corretamente
        .join(",")
    ),
  ].join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  saveAs(blob, `${fileName}.csv`);
};


const TransactionSummary: React.FC = () => {

  const [ dataToExport, setDataToExport] = useState([]);

  return (
    <MDBox sx={{ maxWidth: '1376px', margin: '0 auto' }}>
      <MDCard color="white" boxShadow={false} border={false} sx={{ position: 'relative', margin: '.25rem' }}>
        <MDBox px={4}>
          <MDBox>
            <Grid container sx={{ height: '65px' }} alignItems="center" justifyContent="space-between">
              <MDTypography sx={{ fontSize: 18 }} fontWeight="bold" data-testid="automation-title">
                Transaction Summary
              </MDTypography>
              <MDButton 
                disabled={dataToExport?.length === 0}
                sx={{ cursor: 'pointer !important' }} 
                variant="transparent" 
                color="secondary"
                startIcon={<FileDownloadOutlinedIcon />} // Ícone do CSV
                onClick={() => {
                  const now = new Date();
                  const timestamp = now.toISOString().slice(0, 10);
                  exportTransactionsToCSV(dataToExport, `TransactionSummary_${timestamp}`);
                }}
              >
                Download Transactions
              </MDButton>
            </Grid>
            <Box sx={{ border: '2px solid #eef2ff', position: 'absolute', left: 0, top: '65px', width: '100%' }} />
          </MDBox>
        </MDBox>
        <Box p={4}>
          <EnhancedTable setDataToExport={(data: any) => setDataToExport(data)}/>
        </Box>
      </MDCard>
    </MDBox>
  );
};

export default TransactionSummary;
