import React from 'react';

import LockIcon from '@mui/icons-material/Lock';

import { Box } from '@mui/system';
import { Skeleton, Tooltip, Typography } from '@mui/material';
import { useAutomationContext } from 'pages/automations/context';
import { isAllowedPermission } from 'helpers/getUserInfos';
import { useSelfManagementPlanModal } from 'context/selfmanageplan-context';

interface IDefault {
  isLoading: boolean
}

const DefaultTemplate: React.FC<IDefault> = ({ isLoading }) => {
  const { automationDetail } = useAutomationContext();

  const { showModal, setTierToUpgrade } = useSelfManagementPlanModal();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', margin: '1rem 0 0 0', minHeight: '300px' }}>
      <Box sx={{ display: 'flex', margin: '1rem 0 0 0' }}>
        <Box sx={{ width: '45%'}}>
          <Typography variant='h5' sx={{ opacity: .6, color: 'black' }}>Name</Typography>
          <Tooltip title={automationDetail?.name ?? ''}>
            { isLoading ? (
              <Skeleton width={'200px'} height={20} />
            ) : (
              <Typography variant='h5' sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'black', maxWidth: '200px' }}>
                {automationDetail?.name}
              </Typography>
            )}
          </Tooltip>
        </Box>
        <Box sx={{ width: '33%'}}>
          <Typography variant='h5' sx={{ opacity: .6, color: 'black' }}>ID</Typography>
          { isLoading ? (
              <Skeleton width={'100px'} height={20} />
            ) : (
              <Typography variant='h5' sx={{ color: 'black' }}>{automationDetail?.integrationOrderId ?? automationDetail?.id}</Typography>
            )}
        </Box>
        <Box sx={{ width: '25%'}}>
          <Typography variant='h5' sx={{ opacity: .6, color: 'black' }}>Status</Typography>
          { isLoading ? (
              <Skeleton width={'30px'} height={20} />
            ) : (
              <Box>
                <Typography variant='h5' sx={{ color: 'black' }}>{automationDetail?.status}</Typography>
              </Box>
            )}
        </Box>
      </Box>
      
      <Box sx={{ display: 'flex', margin: '1rem 0 0 0'}}>
        <Box sx={{ width: '45%'}}>
          <Typography variant='h5' sx={{ opacity: .6, color: 'black' }}>Queued</Typography>
          { isLoading ? (
              <Skeleton width={'30px'} height={20} />
            ) : (
              <Typography variant='h5' sx={{ color: 'black' }}>{automationDetail?.queued}</Typography>
            )}
        </Box>
        <Box sx={{ width: '33%'}}>
          <Typography variant='h5' sx={{ opacity: .6, color: 'black' }}>In Production</Typography>
          { isLoading ? (
              <Skeleton width={'30px'} height={20} />
            ) : (
              <Typography variant='h5' sx={{ color: 'black' }}>{automationDetail?.inProduction}</Typography>
            )}
        </Box>
        <Box sx={{ width: '25%'}}>
          <Typography variant='h5' sx={{ opacity: .6, color: 'black' }}>Mailed</Typography>
          { isLoading ? (
              <Skeleton width={'30px'} height={20} />
            ) : (
              <Typography variant='h5' sx={{ color: 'black' }}>{automationDetail?.mailed}</Typography>
            )}
        </Box>
      </Box>

      <Box sx={{ display: 'flex', margin: '1rem 0 0 0' }}>
        <Box sx={{ width: '45%'}}>
          <Typography variant='h5' sx={{ opacity: .6, color: 'black' }}>QR Scans</Typography>
          { isLoading ? (
              <Skeleton width={'30px'} height={20} />
            ) : (
              <Typography variant='h5' sx={{ color: 'black' }}>
                {
                  isAllowedPermission('TrackableQRCodes') ? (
                    <React.Fragment>
                      {automationDetail?.qrScanCount}
                    </React.Fragment>
                  ) : (
                    <LockIcon sx={{ fontSize: '1rem', color: '#F05A2A' }} onClick={() => {setTierToUpgrade('Core Tier');showModal('upgrade')}} />
                  )
                }
              </Typography>
            )}
        </Box>
        <Box sx={{ width: '33%'}}>
          <Typography variant='h5' sx={{ opacity: .6, color: 'black' }}>Calls Received</Typography>
          { isLoading ? (
              <Skeleton width={'30px'} height={20} />
            ) : (
              <Typography variant='h5' sx={{ color: 'black' }}>{automationDetail?.callsReceivedCount}</Typography>
            )}
        </Box>
        <Box sx={{ width: '25%'}}>
          <Typography variant='h5' sx={{ opacity: .6, color: 'black' }}>Total Call Time</Typography>
          { isLoading ? (
              <Skeleton width={'30px'} height={20} />
            ) : (
              <Typography variant='h5' sx={{ color: 'black' }}>{automationDetail?.totalCallTime}</Typography>
            )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', margin: '1rem 0' }}>
        <Box sx={{ width: '60%'}}>
          <Typography variant='h5' sx={{ opacity: .6, color: 'black' }}>Monthly Sends Remaining</Typography>
          { isLoading ? (
              <Skeleton width={'30px'} height={20} />
            ) : (
              <Typography variant='h5' sx={{ color: 'black' }}>{!automationDetail?.monthlySendsRemaining ? 'No Limit' : automationDetail?.monthlySendsRemaining }</Typography>
            )}
        </Box>
      </Box>
    </Box>
  );
}

export default DefaultTemplate;