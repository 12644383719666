import { useState } from 'react';
import { PaymentMethod } from '@stripe/stripe-js';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Alert,
  Box,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';

import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import useOrderRecipientService from 'newStandard/src/services/orderRecipient';
import { IOrder, OrderStatus } from 'newStandard/src/services/order/types';
import ReviewProofs from 'newStandard/src/components/ReviewProofs';
import MDButton from 'material-ui/components/MDButton';
import OrderService from 'services/order';

import SelectProductionSpeed from './SelectProductionSpeed';
import StripeCheckoutButton from './StripeCheckoutButton';
import SelectDeliveryOption from './SelectDeliveryOption';
import CheckoutWrapper from './CheckoutWrapper';
import OrderSummary from './OrderSummary';
import SuccessModal from './SuccessModal';
import { defaultSearchParams } from 'components/table/table.utils';
import Loader from 'components/loader';
import PaymentManager from 'newStandard/src/components/PaymentManager';

const DEFAULT_ERROR_MESSAGE = 'An unexpected error occurred. Please try again or contact billing@lettrlabs.com';

export default function CheckoutStep() {
  const { getOrderRecipients } = useOrderRecipientService();
  const { getOrderCheckout, postOrderCheckout } = OrderService();
  const { template, setTemplate, changeStep, save, isSaving } = useWorkflowContext<IOrder>();
  const [isProofApproved, setIsProofApproved] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<PaymentMethod>();
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { mutate: postCheckout, isPending } = useMutation({
    mutationFn: () => postOrderCheckout(template.id),
    onSuccess: (response) => {
      if (response.paymentStatus === 'success') setShowSuccess(true);
      else setErrorMessage(DEFAULT_ERROR_MESSAGE);
    },
  });

  const searchParams = { ...defaultSearchParams, pageSize: 10 };
  const { data } = useQuery({
    queryKey: ['orderRecipients', template.id, searchParams],
    queryFn: () => getOrderRecipients(template.id, searchParams),
  });

  const {
    data: { payload: checkout } = {},
    refetch,
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: ['checkout', 'order', template.id],
    queryFn: () => getOrderCheckout(template.id),
    enabled: false,
  });

  const { potentiallyUndeliverable = 0 } = data?.payload.recipientWarningDetails ?? {};
  const showSkeleton = isSaving || isRefetching || isLoading;
  const isConfirmDisabled = isPending || showSkeleton || !isProofApproved;
  const showCheckoutButton = template.orderStatus !== OrderStatus.Paid && isProofApproved;
  const showWarning = potentiallyUndeliverable > 0;

  const handleChange = (updatedOrder: IOrder) => {
    setTemplate(updatedOrder);
    save(updatedOrder, refetch);
  };

  return (
    <CheckoutWrapper>
      {errorMessage && (
        <Alert severity="error" onClose={() => setErrorMessage('')} sx={{ mb: 1 }}>
          {errorMessage}
        </Alert>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={isProofApproved ? 6 : 12} xl={isProofApproved ? 7 : 12}>
          <Paper variant="outlined" sx={{ borderRadius: 4, p: 2 }}>
            <ReviewProofs
              collapse={isProofApproved}
              isProofApproved={isProofApproved}
              setIsProofApproved={setIsProofApproved}
            />
          </Paper>
          <Collapse in={isProofApproved}>
            <SelectProductionSpeed checkout={checkout} onChange={(updatedOrder) => save(updatedOrder, refetch)} />
            <SelectDeliveryOption checkout={checkout} isLoading={showSkeleton} onChange={handleChange} />
          </Collapse>
        </Grid>
        <Grid item xs={isProofApproved ? 12 : 0} lg={isProofApproved ? 6 : 0} xl={isProofApproved ? 5 : 0}>
          <Collapse in={isProofApproved}>
            {showWarning && (
              <Alert severity="warning" sx={{ py: 0, alignItems: 'center', mb: 1, borderRadius: 4 }}>
                <Box display={'flex'} gap={1} alignItems={'center'} fontWeight={'bold'} fontSize={{ xs: 14, xl: 16 }}>
                  Warnings:
                  <Chip label={`Potentially Undeliverable Addresses: ${potentiallyUndeliverable}`} />
                </Box>
              </Alert>
            )}
            <OrderSummary checkout={checkout} isLoading={showSkeleton} />
            {!!checkout?.balance && (
              <PaymentManager data={checkout?.savedPaymentMethods}  handleSelectedPayment={(value: PaymentMethod) => setSelectedCard(value)} />
            )}
          </Collapse>
        </Grid>
      </Grid>
      <Grid container mt={2} alignItems={'center'}>
        <Grid item lg={4} display={{ xs: 'none', lg: 'block' }}>
          <MDButton color="light" onClick={() => changeStep(2)} sx={{ width: 100 }}>
            Back
          </MDButton>
        </Grid>
        <Grid item xs={12} lg={4} textAlign={'center'}>
          <Typography fontSize={12} maxWidth={{ lg: 350 }}>
            If you have any questions or encountered any issues, please click on the Service Bell button on the bottom-right corner.
          </Typography>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Stack width={'100%'} maxWidth={{ xs: '100%', lg: 385 }} alignItems={'end'} sx={{ float: 'inline-end' }}>
            <Box width={'100%'} display={'flex'} justifyContent={{ xs: 'space-between', lg: 'flex-end' }} gap={1}>
              <MDButton
                color="light"
                onClick={() => changeStep(2)}
                disabled={isPending}
                sx={{ width: 100, display: { xs: 'flex', lg: 'none' } }}
              >
                Back
              </MDButton>
              {showCheckoutButton &&
                (checkout?.stripeToken ? (
                  <StripeCheckoutButton
                    selectedCard={selectedCard}
                    setErrorMessage={setErrorMessage}
                    stripeToken={checkout.stripeToken}
                    isConfirmDisabled={isConfirmDisabled}
                    setShowSuccess={setShowSuccess}
                  />
                ) : (
                  <MDButton
                    fullWidth
                    disabled={isConfirmDisabled}
                    onClick={() => postCheckout()}
                    sx={{ maxWidth: { xs: '100%', lg: 160 } }}
                  >
                    {isPending ? <CircularProgress size={18} color="inherit" /> : 'Place your order'}
                  </MDButton>
                ))}
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <SuccessModal show={showSuccess} close={() => setShowSuccess(false)} />
      <Dialog open={showSkeleton}>
        <DialogContent>
          <Loader />
        </DialogContent>
      </Dialog>
    </CheckoutWrapper>
  );
}
