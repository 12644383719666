import React from 'react';

import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { IDealSubscription } from 'services/checkout/checkout.types';
import { Dialog, Skeleton, Typography } from '@mui/material';

import Logo from 'assets/images/logo-blue.png'
import Product from './Product';
import Payments from '../Components/Payments/Unauthenticated';
import CheckoutWrapper from 'newStandard/src/components/Checkout/CheckoutWrapper';
import useUnauthenticatedCheckoutService from 'services/checkout/UnauthenticatedService';

const CheckoutAnonymousSession: React.FC = () => {
    const { id } = useParams();

    const { get } = useUnauthenticatedCheckoutService();

    const { data: BillingSessionIndentifierData, isLoading: isLoadingBillingSessionIndentifierData } = useQuery({ 
        queryKey: ['billingSessionIndentifier'], 
        queryFn: () => get(id ?? ''),
        enabled: !!id
    });
    

    const getTotalPrice = (items: IDealSubscription[]) => {
        if(!items) return

        return items.reduce((total, item) => total + item.totalPrice, 0)?.toLocaleString("en-US");
    };

    return (
        <Dialog open={true} fullScreen fullWidth>
            <Box sx={{ display: 'flex', minHeight: '90vh' }}>

            <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%', padding: '2.3rem 4%'}}>
                    <Box component={'img'} src={Logo} width={160} alt='Logo LettrLabs Colorful' />
                   
                    <Typography variant='h1' sx={{ fontSize: '22px', color: 'black', marginTop: '2rem'}}>
                        Subscriptions
                    </Typography>

                    {
                        !isLoadingBillingSessionIndentifierData && BillingSessionIndentifierData?.payload?.dealSubscriptions?.map((sub, idx: number) => (
                            <Product key={idx} data={sub} />
                        ))
                    }

                    {
                        isLoadingBillingSessionIndentifierData && (
                            <React.Fragment>
                                {
                                    [0,1,2].map(l => (
                                        <Box key={l} sx={{ display: 'flex' }}>
                                            <Skeleton width={'60px'} height={'100px'} />
                                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: '1rem', width: '100%' }}>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                                    <Typography component={'b'} sx={{ fontWeight: 'bold', fontSize: '15px' }}>
                                                        <Skeleton width={'320px'} height={'45px'} />
                                                    </Typography>
                                                    <Typography component={'b'} sx={{ display: 'flex', gap: 1, alignItems: 'center', fontSize: '13px', opacity: '.7'}}>
                                                        <Skeleton width={'80px'} height={'20px'} /> / <Skeleton width={'60px'} height={'20px'} />
                                                    </Typography>
                                                </Box>
                                                <Typography variant='h5' sx={{ fontWeight: 'normal', color: 'grey' }}>
                                                    <Skeleton width={'180px'} height={'20px'} />
                                                </Typography>
                                                <Typography variant='h5' sx={{ fontWeight: 'normal', color: 'grey' }}>
                                                    <Skeleton width={'140px'} height={'20px'} />
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ))
                                }
                            </React.Fragment>
                        )
                    }

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '2rem 0', margin: '2rem 0', borderTop: '1px solid rgba(0,0,0,.3)'}}>
                        <Typography variant='h1' sx={{ fontSize: '18px', color: 'black !important', marginTop: '.25rem'}}>
                            Total Charged Today
                        </Typography>
                        {
                            isLoadingBillingSessionIndentifierData ? (
                                <Skeleton width={'140px'} height={'60px'} />
                            ) : (
                                <Typography variant='h1' sx={{ fontSize: '19px', fontWeight: 'normal !important', color: 'black !important', marginTop: '.25rem'}}>
                                    $ {getTotalPrice(BillingSessionIndentifierData?.payload?.dealSubscriptions ?? [])}
                                </Typography>
                            )
                        }
                    </Box>
            </Box>

            <Box sx={{ padding: '5rem 8rem ', width: '50%', height: '100vh', boxShadow: '-5px 0px 19px 4px rgba(0, 0, 0, 0.04)'}}>
                <Box sx={{ width: '100%', maxWidth: '600px', margin: '0 auto'}}>
                    <CheckoutWrapper isLoading={false}>
                        <Typography variant='h1' sx={{ fontSize: '22px', color: 'black', marginTop: '1.9rem', marginBottom: '2rem'}}>
                            Select Payment Method
                        </Typography>
                        <Payments data={BillingSessionIndentifierData} />
                    </CheckoutWrapper>

                    <Box sx={{ margin: '4rem 0 0 0', display: 'flex', justifyContent: 'center' }}>
                        <Typography variant='caption' sx={{ fontSize: '14px', paddingRight: '1rem', marginRight: '1rem', borderRight: '1px solid rgba(0,0,0,.1)' }}>Powered by <b>LettrLabs</b></Typography>
                        <Typography variant='caption' sx={{ fontSize: '14px', }}>Terms</Typography>
                        <Typography variant='caption' sx={{ fontSize: '14px', marginLeft: '1rem'}}>Privacy</Typography>
                    </Box>
                </Box>
            </Box>
            </Box>
        </Dialog>
    );
}

export default CheckoutAnonymousSession;