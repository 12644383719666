// @ts-nocheck

import MDBox from "material-ui/components/MDBox";
import MDCard from "material-ui/components/MDCard";
import {Box, CircularProgress, Grid, Select, Slider, Switch, Tooltip, Typography} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {Circle, GoogleMap, LoadScript, OverlayView, Polygon as MapsPolygon} from "@react-google-maps/api";
import {Coordinate} from "models/coordinate";
import Divider from "@mui/material/Divider";
import MDTypography from "material-ui/components/MDTypography";
import colors from "material-ui/theme/base/colors";
import MDButton from "material-ui/components/MDButton";
import RadiusIcon from "assets/icons/radius";
import MenuItem from "@mui/material/MenuItem";
import {formatNumber, formatPrice} from "helpers/formatters";
import MDDatePicker from "material-ui/components/MDDatePicker";
import MDIconButton from "material-ui/components/MDIconButton";
import DeleteIcon from "assets/icons/delete";
import MDInput from "material-ui/components/MDInput";
import ServiceAreaModal from "./components/service-area-modal";
import {
    ServiceArea,
    ServiceAreaPolygon,
    ServiceAreaRadius,
    ServiceAreaStatus
} from "models/hail-trace/ServiceArea";
import {getCenterOfPolygon, mapContainerStyle, mapOptions} from "helpers/google-map-helper";
import StormAutomationIcon from "assets/icons/storm-automation";
import StormAutomationService from "services/storm-automation";
import {useNavigate, useParams} from "react-router-dom";
import HailSizeIcon from "assets/icons/hail-size";
import HomeAlt2Icon from "assets/icons/home-alt-2";
import HomeEditIcon from "assets/icons/home-edit";
import ProductIcon from "assets/icons/product";
import WindSpeedIcon from "assets/icons/wind-speed";
import ProductSpeedIcon from "assets/icons/product-speed";
import PostageIcon from "assets/icons/postage";
import {useInterval} from "hooks/useInterval";
import Info2Icon from "assets/icons/info-2";
import InfoModal from "components/info-modal";
import MDAlert from "material-ui/components/MDAlert";
import WarningIcon from "assets/icons/warning";
import CheckIcon from "assets/icons/check";
import HomeDollarIcon from "assets/icons/home-dollar";
import moment from "moment/moment";
import {OwnerOrRenterFilter, PropertyTypeFilter} from "models/radius-mailing/filters";
import ProfileService from "services/profile";
import { useQuery } from "@tanstack/react-query";
import { useGlobal } from "context/global-context";
import SelectTemplate from "components/select-template";
import { ITemplate } from "models/template";

function StormAutomation() {
    const {setShowLoader, setTemplateFlow, templateFlow} = useGlobal()

    const navigate = useNavigate()

    const [orderId, setOrderId] = useState<number>(parseInt(useParams().id))

    const {getServiceArea, postServiceArea, putServiceArea, copyServiceArea} = StormAutomationService()
    const {getProfile} = ProfileService()
    const [map, setMap] = useState<google.maps.Map>(null)
    const [hasMapLoaded, setHasMapLoaded] = useState<boolean>(false)
    const [geocoder, setGeocoder] = useState(null)

    const [initialCenter, setInitialCenter] = useState<Coordinate>({lat: null, lng: null})
    const [center, setCenter] = useState<Coordinate>({lat: null, lng: null})
    const [zoom, setZoom] = useState<number>(10)

    const [showServiceAreaModal, setShowServiceAreaModal] = useState<boolean>(false)

    const [hailSizeMinFilter, setHailSizeMinFilter] = useState<boolean>(false)
    const [windSpeedFilter, setWindSpeedFilter] = useState<boolean>(false)
    const [stopSendingAtDateFilter, setStopSendingAtDateFilter] = useState<boolean>(false)
    const [resendingFilter, setResendingFilter] = useState<boolean>(false)

    const [shouldRefetch, setShouldRefetch] = useState<boolean>(false)

    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)

    const [showCopyOrderModal, setShowCopyOrderModal] = useState<boolean>(false)

    const [showSubmitErrorModal, setShowSubmitErrorModal] = useState<boolean>(false)
    const [errorModalContent, setErrorModalContent] = useState<React.ReactNode | string>(null)
    const [confirmEnableModalContent, setConfirmEnableModalContent] = useState<React.ReactNode | string>(null)

    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)

    const polygonsRef = useRef([])

    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)

    const [serviceArea, setServiceArea] = useState<ServiceArea>({
        id: 0,
        name: null,
        totalStructures: null,
        estimatedPricePerCard: null,
        canEdit: true,
        isProcessed: false,
        status: ServiceAreaStatus.Draft,
        radii: [],
        polygons: [],
        cardsToSendPerStormMax: 50000,
        template: null,
        hailSizeMinFilter: null,
        homeOwnerFilter: 0,
        propertyTypeFilter: 0,
        windSpeedMinFilter: null,
        resendAfterWeeks: null,
        startSendingAtDate: null,
        stopSendingAtDate: null,
        canDelete: true,
        queuedCount: null,
        inProductionCount: null,
        mailedCount: null
    })

    const getProfileQuery = useQuery({
        queryKey: ["profile"],
        queryFn: () => {
            return getProfile()
        },
    })

    const selectTemplate = (template: ITemplate, tempServiceArea?: ServiceArea) => {
        if(serviceArea.id === 0) setServiceArea((prev) => ({...(tempServiceArea || prev), template}))
        else {        
            let putData = {...tempServiceArea || serviceArea}
            putData.template = template
            setShowLoader(true)
            putServiceArea(putData).then((response) => {
                let newServiceArea = response.payload as ServiceArea
                if(newServiceArea){
                    setShowLoader(false)
                    setServiceArea(newServiceArea)
                }
            })
        }
    }

    function onMapLoaded() {
        setHasMapLoaded(true)

        setGeocoder(new window.google.maps.Geocoder())

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function (pos: GeolocationPosition) {
                    let lat = pos.coords.latitude
                    let lng = pos.coords.longitude

                    setCenter({lat, lng})
                    setInitialCenter({lat, lng})
                },
                function (error) {
                    setCenter({lat: 33.824386, lng: -118.1358666})
                    setInitialCenter({lat: 33.824386, lng: -118.1358666})
                })
        } else {
            setCenter({lat: 33.824386, lng: -118.1358666})
            setInitialCenter({lat: 33.824386, lng: -118.1358666})
        }
    }

    function addAddress(lat: number, lng: number, addressData: any) {
        let sortedAddresses = serviceArea.radii.sort((a, b) => {
            if (a.id > b.id) {
                return 1
            } else if (a.id < b.id) {
                return -1
            }
            return 0
        })

        let lastId = sortedAddresses.length ? sortedAddresses[sortedAddresses.length - 1].id : 0

        setServiceArea(prevState => {
            let newAddress: ServiceAreaRadius = {
                id: lastId + 1,
                coordinate: {
                    lat: lat,
                    lng: lng,
                },
                radius: 2,
                name: addressData.shortAddress,
                isNew: true,
                structures: null,
                isProcessed: true,
                recipientSearchEntryId: null
            }

            return {...prevState, radii: [...prevState.radii, newAddress]}
        })
    }

    function addPolygon(lat: number, lng: number, addressData: any) {
        setServiceArea(prevState => {
            let sortedPolygons = serviceArea.polygons.sort((a, b) => {
                if (a.id > b.id) {
                    return 1
                } else if (a.id < b.id) {
                    return -1
                }
                return 0
            })

            let lastId = sortedPolygons.length ? sortedPolygons[sortedPolygons.length - 1].id : 0

            let offset = 0.02898550724 //Approximate offset for the 4 points around the center (2 miles)

            let newPolygon: ServiceAreaPolygon = {
                id: lastId + 1,
                name: addressData.shortAddress,
                coordinates: [
                    {lat: lat, lng: lng - offset},
                    {lat: lat - offset, lng: lng},
                    {lat: lat, lng: lng + offset},
                    {lat: lat + offset, lng: lng},
                ],
                isNew: true,
                structures: null,
                isProcessed: true,
                recipientSearchEntryId: null
            }

            return {...prevState, polygons: [...prevState.polygons, newPolygon]}
        })
    }

    function deleteAddress(id: number) {
        setServiceArea(prevState => {
            let copy = {...prevState}

            copy.radii = copy.radii.filter(a => a.id !== id)

            return copy
        })
    }

    function deletePolygon(id: number) {
        setServiceArea(prevState => {
            let copy = {...prevState}

            copy.polygons = copy.polygons.filter(a => a.id !== id)

            return copy
        })
    }

    function setStatesFromServiceArea(newServiceArea: ServiceArea){
            setServiceArea(newServiceArea)

            if(newServiceArea.hailSizeMinFilter){
                setHailSizeMinFilter(true)
            }
            if(newServiceArea.windSpeedMinFilter){
                setWindSpeedFilter(true)
            }
            if(newServiceArea.stopSendingAtDate){
                setStopSendingAtDateFilter(true)
            }
            if(newServiceArea.resendAfterWeeks){
                setResendingFilter(true)
            }

            if(newServiceArea.radii.length || newServiceArea.polygons.length){
                if(newServiceArea.radii.length){
                    let c = newServiceArea.radii[0].coordinate
                    setCenter(c)
                    setInitialCenter(c)
                }
                else if(newServiceArea.polygons.length){
                    let c = getCenterOfPolygon(newServiceArea.polygons[0].coordinates)
                    setCenter(c)
                    setInitialCenter(c)
                }
            }

            setShouldRefetch(!newServiceArea.isProcessed)
    }

    function fetchServiceArea(){
        getServiceArea(orderId).then((response) => {
            let newServiceArea = response.payload as ServiceArea

            setStatesFromServiceArea(newServiceArea)
        })
    }

    function fetchServiceAreaStructures(){
        getServiceArea(orderId).then((response) => {
            let newServiceArea = response?.payload as ServiceArea
            if(!newServiceArea) return

            setShouldRefetch(!newServiceArea.isProcessed)

            let newAddresses: ServiceAreaRadius[] = [...serviceArea.radii]
            let newPolygons: ServiceAreaPolygon[] = [...serviceArea.polygons]

            for(let add1 of newAddresses){
                for (let add2 of newServiceArea.radii){
                    if (add1.id === add2.id){
                        add1.isProcessed = add2.isProcessed
                        add1.structures = add2.structures
                        break
                    }
                }
            }

            for(let poly1 of newPolygons){
                for (let poly2 of newServiceArea.polygons){
                    if (poly1.id === poly2.id){
                        poly1.isProcessed = poly2.isProcessed
                        poly1.structures = poly2.structures
                        break
                    }
                }
            }

            setServiceArea(prevState => {
                return {
                    ...prevState,
                    radii: newAddresses,
                    polygons: newPolygons,
                    isProcessed: newServiceArea.isProcessed,
                    totalStructures: newServiceArea.totalStructures,
                    estimatedPricePerCard: newServiceArea.estimatedPricePerCard
                }
            })
        })
    }

    function updateCardsSendPerStormMax(serviceArea: ServiceArea){
        if(serviceArea.id === 0) return
        putServiceArea(serviceArea).then((response) => {
            let newServiceArea = response.payload as ServiceArea
            if(newServiceArea){
                setServiceArea(prevState => {
                    return {...prevState, estimatedPricePerCard: newServiceArea.estimatedPricePerCard}
                })
            }
        })
    }

    useEffect(() => {
        if(!isFirstLoad){
            return
        }

        if(orderId){
            if(serviceArea.id && hasMapLoaded){
                setShowLoader(false)
                setIsFirstLoad(false)
            }
            else {
                setShowLoader(true)
            }
        }
        else{
            if (hasMapLoaded) {
                setShowLoader(false)
                setIsFirstLoad(false)
            }
            else {
                setShowLoader(true)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasMapLoaded, serviceArea, orderId, isFirstLoad]);

    useEffect(() => {
        if(templateFlow.order?.template) {
            setStatesFromServiceArea(templateFlow.order)
            selectTemplate(templateFlow.order.template, templateFlow.order)
            setTemplateFlow({})
        } else if(orderId){
            fetchServiceArea()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId])

    useInterval(() => {
        fetchServiceAreaStructures()
    }, shouldRefetch? 5000 : null);

    // Updates the backend every time one of these filters gets changed
    useEffect(() => {
        if(!serviceArea || !serviceArea.id){
            return
        }

        updateServiceArea()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceArea?.propertyTypeFilter, serviceArea?.homeOwnerFilter]);

    const minStructuresCount: number = 100;

    function handleSubmit(){
        if(!serviceArea.radii.length && !serviceArea.polygons.length){
            setErrorModalContent("Please define the area of your storm automation")
            setShowSubmitErrorModal(true)
            return
        }

        if(!serviceArea.template){
            setErrorModalContent("Please select a template to be used for this storm automation")
            setShowSubmitErrorModal(true)
            return
        }

        if(!serviceArea.windSpeedMinFilter && !serviceArea.hailSizeMinFilter){
            setErrorModalContent("Please fill out the hail size or wind speed of your automation")
            setShowSubmitErrorModal(true)
            return
        }

        if(!serviceArea.startSendingAtDate){
            setErrorModalContent("Please enter the start date of your storm automation")
            setShowSubmitErrorModal(true)
            return
        }
        
        if(!serviceArea.isProcessed){
            setErrorModalContent("Please wait while we're fetching the total number of structures for your service area")
            setShowSubmitErrorModal(true)
            return
        }
        else{
            if(hasStructuresBelowMinRecipients(serviceArea)){
                setErrorModalContent(`Please update your service area to have at least ${minStructuresCount} structures in each area.`)
                setShowSubmitErrorModal(true)
                return
            }
        }
        
        const recipientsAmount = serviceArea.totalStructures
        const totalPrice = recipientsAmount <= serviceArea.cardsToSendPerStormMax
                                ?recipientsAmount * serviceArea.estimatedPricePerCard
                                :serviceArea.cardsToSendPerStormMax * serviceArea.estimatedPricePerCard

        setConfirmEnableModalContent(`You're about to enable a severe storm automation that will charge you up to ${formatPrice(totalPrice)} per storm, if all ${Math.min(recipientsAmount, serviceArea.cardsToSendPerStormMax).toLocaleString()} recipients in your service area are impacted.\nWe will use any existing credits on your account before automatically charging your default payment method.`)
        setShowConfirmModal(true)
    }

    function hasStructuresBelowMinRecipients(serviceArea: ServiceArea): boolean {
        const hasPolygonWithLessThan100Structures = serviceArea.polygons.some(polygon => polygon.structures < minStructuresCount);
        const hasRadiusWithLessThan100Structures = serviceArea.radii.some(radius => radius.structures < minStructuresCount);
        return hasPolygonWithLessThan100Structures || hasRadiusWithLessThan100Structures;
    }

    function submitServiceArea(){
        setShowLoader(true)

        let postData: ServiceArea = {...serviceArea};

        postData.status = ServiceAreaStatus.Active
        serviceArea.status = ServiceAreaStatus.Active

        for (let e of postData.radii) {
            if (e.isNew) {
                e.id = 0
                e.isNew = false
            }
        }

        for (let e of postData.polygons) {
            if (e.isNew) {
                e.id = 0
                e.isNew = false
            }
        }

        postServiceArea(serviceArea).then((response) => {
            setShowLoader(false)
            setShouldRefetch(false)
            setShowConfirmModal(false)
            navigate('/automations?status=createdSuccessfully');
            setServiceArea(response.payload)
        })
    }

    function updateServiceArea(){
        setShowLoader(true)

        let postData: ServiceArea = {...serviceArea};

        for (let e of postData.radii) {
            if (e.isNew) {
                e.id = 0
                e.isNew = false
            }
        }

        for (let e of postData.polygons) {
            if (e.isNew) {
                e.id = 0
                e.isNew = false
            }
        }

        postServiceArea(serviceArea).then((response) => {
            setShowLoader(false)

            navigate(`/storm-automation/order/${response.payload.id}`)
            setOrderId(response.payload.id)
            setServiceArea(response.payload)

            setShouldRefetch(!response.payload.isProcessed)
        })
    }

    function copyStormAutomation(){
        setShowCopyOrderModal(true)
    }

    function copyOrder(){
        setShowLoader(true)
        copyServiceArea(serviceArea).then((response) => {
            setShowLoader(false)

            navigate(`/storm-automation`)
        })
    }

    return <LoadScript googleMapsApiKey="AIzaSyAnZBcPxYjFTJ2p5VykxLnOcqM0gY4uIY0" onLoad={onMapLoaded}>
        {hasMapLoaded ?
            <Grid>
                {!serviceArea.canEdit?
                    <MDAlert color={"warning"} dismissible={false} backgroundColorOpacity={0.3} mb={3}>
                        <Grid container justifyContent={"center"} alignItems={"center"}>
                            <Grid item display={"flex"}>
                                <WarningIcon color={"warning"}/>
                            </Grid>

                            <Grid item ml={2}>
                                <MDTypography color={"secondary"} textAlign={"center"} fontWeight={"normal"} sx={{fontSize: 16}}>
                                    This storm automation cannot be edited, as it is not in a Draft status.
                                    If you want to make a Draft copy of this automation,
                                    please click<MDTypography ml={0.5} display={'inline-block'} sx={{fontSize: 16}} fontWeight={"normal"} isLink={true} color={"primary"} onClick={copyStormAutomation}>here</MDTypography>
                                </MDTypography>
                            </Grid>
                        </Grid>
                    </MDAlert>
                    :
                    null
                }

                <Grid container gap={3} flexWrap={"nowrap"}>
                    <Grid item flex={1} maxWidth={"800px"} minWidth={"600px"}>
                        <MDCard>
                            <Grid p={4} pt={3.5}>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography mb={2} fontSize={24} fontWeight={"bold"} color={"secondary"}>
                                        {orderId ? (serviceArea.canEdit ? "Edit" : "") : "New"} Storm Automation {serviceArea.canEdit ? "" : "Details"}
                                    </Typography>
                                    <Tooltip
                                        componentsProps={{ tooltip: { sx: { width: "310px", maxWidth: "unset" } } }} 
                                        title={"Create orders based on storm occurrences to ensure your letters are received when your recipients need you most"}
                                    >
                                        <Box>
                                            <Info2Icon color={"light2"}/>
                                        </Box>
                                    </Tooltip>
                                </Box>
                                <Grid container alignItems={"stretch"} mt={2} gap={3} py={1}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} justifyContent={"center"}
                                              flexDirection={"column"}>
                                            <Grid item>
                                                <MDBox p={1} sx={{
                                                    backgroundColor: serviceArea.radii.length || serviceArea.polygons.length? colors.primary.main : colors.secondary.main,
                                                    borderRadius: "50%",
                                                    width: "30px",
                                                    height: "30px"
                                                }} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                                    {serviceArea.radii.length || serviceArea.polygons.length?
                                                        <CheckIcon color={"white"}/>
                                                        :
                                                        <MDTypography sx={{fontSize: 16}} fontWeight={"bold"} color={"white"}>
                                                            1
                                                        </MDTypography>
                                                    }
                                                </MDBox>
                                            </Grid>

                                            <Grid item mt={0.4}>
                                                <MDTypography variant={"h5"}>Step 1</MDTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <MDBox>
                                        <Divider light={true} orientation={"vertical"}/>
                                    </MDBox>

                                    <Grid item flex={1}>
                                        <MDButton
                                            color="primary"
                                            onClick={() => {
                                                setShowServiceAreaModal(true)
                                            }}
                                            disabled={!serviceArea.canEdit}
                                        >
                                            <Grid container alignItems={"center"} justifyContent={"center"} gap={1}>
                                                <Grid item display={"flex"}>
                                                    <RadiusIcon/>
                                                </Grid>
                                                <Grid item>
                                                    Establish Service Area
                                                </Grid>
                                            </Grid>
                                        </MDButton>
                                    </Grid>
                                </Grid>

                                <Divider light={true}/>

                                <Grid container alignItems={"stretch"} mt={2} gap={3} py={1}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} justifyContent={"center"}
                                              flexDirection={"column"}>
                                            <Grid item>
                                                <MDBox p={1} sx={{
                                                    backgroundColor: serviceArea.template? colors.primary.main : colors.secondary.main,
                                                    borderRadius: "50%",
                                                    width: "30px",
                                                    height: "30px"
                                                }} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                                    {serviceArea.template?
                                                        <CheckIcon color={"white"}/>
                                                        :
                                                        <MDTypography sx={{fontSize: 16}} fontWeight={"bold"} color={"white"}>
                                                            2
                                                        </MDTypography>
                                                    }
                                                </MDBox>
                                            </Grid>

                                            <Grid item mt={0.4}>
                                                <MDTypography variant={"h5"}>Step 2</MDTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <MDBox>
                                        <Divider light={true} orientation={"vertical"}/>
                                    </MDBox>

                                    <Grid item flex={1}>
                                        <SelectTemplate
                                            order={serviceArea}
                                            template={serviceArea.template}
                                            disabled={!serviceArea.canEdit}
                                            onDelete={() => setServiceArea(prevState => ({...prevState, template: null}))}
                                            onTemplateChange={(template) => selectTemplate(template)}
                                            shouldCheckNotSupported
                                            disableEditOption
                                        />
                                    </Grid>
                                </Grid>

                                <Divider light={true}/>

                                <Grid container alignItems={"stretch"} mt={2} gap={3} py={1}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} justifyContent={"center"}
                                              flexDirection={"column"}>
                                            <Grid item>
                                                <MDBox p={1} sx={{
                                                    backgroundColor: serviceArea.hailSizeMinFilter || serviceArea.windSpeedMinFilter? colors.primary.main : colors.secondary.main,
                                                    borderRadius: "50%",
                                                    width: "30px",
                                                    height: "30px"
                                                }} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                                    {serviceArea.hailSizeMinFilter || serviceArea.windSpeedMinFilter?
                                                        <CheckIcon color={"white"}/>
                                                        :
                                                        <MDTypography sx={{fontSize: 16}} fontWeight={"bold"} color={"white"}>
                                                            3
                                                        </MDTypography>
                                                    }
                                                </MDBox>
                                            </Grid>

                                            <Grid item mt={0.4}>
                                                <MDTypography variant={"h5"}>Step 3</MDTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Divider light={true} orientation={"vertical"}/>
                                    </Grid>

                                    <Grid item flex={1}>
                                        <MDTypography sx={{fontSize: 24}} fontWeight={"bold"}>Filters</MDTypography>

                                        <Grid container alignItems={"flex-start"} gap={3} width={"100%"} mt={3}>
                                            <Grid item flex={1}>
                                                <MDTypography sx={{fontSize: 14}} fontWeight={"bold"} mb={1}>
                                                    Home Owner
                                                </MDTypography>

                                                <Select
                                                    disabled={!serviceArea.canEdit}
                                                    fullWidth={true}
                                                    value={serviceArea.homeOwnerFilter}
                                                    displayEmpty={true}
                                                    onChange={(event) => {
                                                        let val = event.target.value === null? null : event.target.value as OwnerOrRenterFilter

                                                        setServiceArea(prevState => {
                                                            return {
                                                                ...prevState,
                                                                homeOwnerFilter: val
                                                            }
                                                        })
                                                    }}
                                                >
                                                    <MenuItem value={null}>All</MenuItem>
                                                    <MenuItem value={0}>Owner</MenuItem>
                                                    <MenuItem value={1}>Renter</MenuItem>
                                                </Select>
                                            </Grid>

                                            <Grid item flex={1}>
                                                <MDTypography sx={{fontSize: 14}} fontWeight={"bold"} mb={1}>
                                                    Property Type
                                                </MDTypography>

                                                <Select
                                                    disabled={!serviceArea.canEdit}
                                                    fullWidth={true}
                                                    value={serviceArea.propertyTypeFilter}
                                                    displayEmpty={true}
                                                    onChange={(event) => {
                                                        let val = event.target.value === null? null : event.target.value as PropertyTypeFilter

                                                        setServiceArea(prevState => {
                                                            return {
                                                                ...prevState,
                                                                propertyTypeFilter: val
                                                            }
                                                        })
                                                    }}
                                                >
                                                    <MenuItem value={null}>All</MenuItem>
                                                    <MenuItem value={0}>Single Family Dwelling Unit</MenuItem>
                                                    <MenuItem value={1}>Multi Family Dwelling Unit</MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>

                                        <Grid container alignItems={"flex-start"} gap={3} width={"100%"} mt={3}>
                                            <Grid item flex={1}>
                                                <Grid container alignItems={"flex-start"}
                                                      justifyContent={"space-between"} mb={1}>
                                                    <Grid item>
                                                        <Grid container gap={1} alignItems={"center"} mb={0.5}
                                                              width={"fit-content"} sx={{cursor: "pointer"}}
                                                              onClick={() => {
                                                                  if(!serviceArea.canEdit) {
                                                                      return
                                                                  }

                                                                  let newState = !hailSizeMinFilter

                                                                  setHailSizeMinFilter(newState)
                                                                  setWindSpeedFilter(false)
                                                                  setServiceArea(prevState => {
                                                                      return {...prevState, hailSizeMinFilter: newState? 0.75 : null, windSpeedMinFilter: null, windSpeedMaxFilter: null}
                                                                  })
                                                              }}>
                                                            <Grid item>
                                                                <MDTypography sx={{fontSize: 14}} fontWeight={"bold"}>
                                                                    Hail Size<MDTypography component="span" sx={{fontSize: 14}} fontWeight={"bold"} color={"error"} display={"inline-block"}>*</MDTypography>
                                                                </MDTypography>
                                                            </Grid>
                                                            <Grid item display={"flex"}>
                                                                <Switch size={"small"} checked={hailSizeMinFilter} disabled={!serviceArea.canEdit}/>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item>
                                                        {hailSizeMinFilter ?
                                                            <MDTypography sx={{fontSize: 16}} fontWeight={"normal"}>
                                                                {`${serviceArea.hailSizeMinFilter}" and larger`}
                                                            </MDTypography>
                                                            :
                                                            null
                                                        }
                                                    </Grid>
                                                </Grid>

                                                <MDBox position={"relative"} pl={1}>
                                                    <Slider
                                                        sx={{
                                                            ".MuiSlider-thumb:nth-of-type(2n)": {
                                                                display: "none"
                                                            }
                                                        }}
                                                        disabled={!hailSizeMinFilter}
                                                        min={0.75}
                                                        max={1.5}
                                                        step={0.25}
                                                        value={[serviceArea.hailSizeMinFilter, 1.5]}
                                                        valueLabelDisplay="auto"
                                                        valueLabelFormat={(value) => {
                                                            return `${value}"`
                                                        }}
                                                        onChange={(event, value: any) => {
                                                            setServiceArea(prevState => {
                                                                return {...prevState, hailSizeMinFilter: value[0]}
                                                            })
                                                        }}
                                                        marks={[
                                                            {value: 0.75, label: '0.75"'},
                                                            {value: 1, label: '1"'},
                                                            {value: 1.25, label: '1.25"'},
                                                            {value: 1.5, label: '1.50"'},
                                                        ]}
                                                    />
                                                </MDBox>
                                            </Grid>

                                            <Grid item flex={1}>
                                                <Grid container alignItems={"flex-start"} justifyContent={"space-between"} mb={1}>
                                                    <Grid item>
                                                        <Grid container gap={1} alignItems={"center"} mb={0.5}
                                                              width={"fit-content"} sx={{cursor: "pointer"}}
                                                              onClick={() => {
                                                                  if(!serviceArea.canEdit) {
                                                                      return
                                                                  }

                                                                  let newState = !windSpeedFilter

                                                                  setWindSpeedFilter(newState)
                                                                  setHailSizeMinFilter(null)
                                                                  setServiceArea(prevState => {
                                                                      return {
                                                                          ...prevState,
                                                                          windSpeedMinFilter: newState? 65 : null,
                                                                          windSpeedMaxFilter: newState? 95 : null,
                                                                          hailSizeMinFilter: null
                                                                      }
                                                                  })
                                                              }}>
                                                            <Grid item>
                                                                <MDTypography sx={{fontSize: 14}} fontWeight={"bold"}>
                                                                    Wind Speed<MDTypography component="span" sx={{fontSize: 14}} fontWeight={"bold"} color={"error"} display={"inline-block"}>*</MDTypography>
                                                                </MDTypography>
                                                            </Grid>
                                                            <Grid item display={"flex"}>
                                                                <Switch size={"small"} checked={windSpeedFilter} disabled={!serviceArea.canEdit}/>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item>
                                                        {windSpeedFilter ?
                                                            <MDTypography sx={{fontSize: 16}} fontWeight={"normal"}>
                                                                {`${serviceArea.windSpeedMinFilter} MPH and stronger`}
                                                            </MDTypography>
                                                            :
                                                            null
                                                        }
                                                    </Grid>
                                                </Grid>

                                                <MDBox position={"relative"} pl={1}>
                                                    <Slider
                                                        disabled={!windSpeedFilter || !serviceArea.canEdit}
                                                        sx={{
                                                            ".MuiSlider-thumb:nth-of-type(2n)": {
                                                                display: "none"
                                                            }
                                                        }}
                                                        min={65}
                                                        max={95}
                                                        step={5}
                                                        value={[serviceArea.windSpeedMinFilter, 95]}
                                                        valueLabelDisplay="auto"
                                                        valueLabelFormat={(value) => {
                                                            return `${value} MPH`
                                                        }}
                                                        onChange={(event, value: any) => {
                                                            setServiceArea(prevState => {
                                                                return {
                                                                    ...prevState,
                                                                    windSpeedMinFilter: value[0],
                                                                    windSpeedMaxFilter: 95
                                                                }
                                                            })
                                                        }}
                                                        marks={[
                                                            {value: 65, label: "65"},
                                                            {value: 70, label: "70"},
                                                            {value: 75, label: "75"},
                                                            {value: 80, label: "80"},
                                                            {value: 85, label: "85"},
                                                            {value: 90, label: "90"},
                                                            {value: 95, label: "95+"},
                                                        ]}
                                                    />
                                                </MDBox>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Divider light={true}/>

                                <Grid container alignItems={"stretch"} mt={2} gap={3} py={1}>
                                    <Grid item>
                                        <Grid container alignItems={"center"} justifyContent={"center"}
                                              flexDirection={"column"}>
                                            <Grid item>
                                                <MDBox p={1} sx={{
                                                    backgroundColor: serviceArea.startSendingAtDate? colors.primary.main : colors.secondary.main,
                                                    borderRadius: "50%",
                                                    width: "30px",
                                                    height: "30px"
                                                }} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                                    {serviceArea.startSendingAtDate?
                                                        <CheckIcon color={"white"}/>
                                                        :
                                                        <MDTypography sx={{fontSize: 16}} fontWeight={"bold"} color={"white"}>
                                                            4
                                                        </MDTypography>
                                                    }
                                                </MDBox>
                                            </Grid>

                                            <Grid item mt={0.4}>
                                                <MDTypography variant={"h5"}>Step 4</MDTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Divider light={true} orientation={"vertical"}/>
                                    </Grid>

                                    <Grid item flex={1}>
                                        <MDTypography sx={{fontSize: 24}} fontWeight={"bold"}>Sending
                                            Options</MDTypography>

                                        <MDBox mt={3}>
                                            <Grid container alignItems={"center"} justifyContent={"space-between"}>
                                                <Grid item>
                                                    <MDTypography sx={{fontSize: 14}} fontWeight={"bold"}>
                                                        Cards sent per storm
                                                    </MDTypography>
                                                </Grid>

                                                <Grid item>
                                                    <MDTypography sx={{fontSize: 16}} fontWeight={"normal"}>
                                                        Up to {formatNumber(serviceArea.cardsToSendPerStormMax)}
                                                    </MDTypography>
                                                </Grid>
                                            </Grid>
                                        </MDBox>

                                        <MDBox px={2} mt={1}>
                                            <Slider
                                                disabled={!serviceArea.canEdit}
                                                min={2500}
                                                max={50000}
                                                step={100}
                                                value={serviceArea.cardsToSendPerStormMax}
                                                valueLabelDisplay="auto"
                                                valueLabelFormat={(value) => {
                                                    return `${formatNumber(value)}`
                                                }}
                                                onChange={(event, value: any) => {
                                                    setServiceArea(prevState => {
                                                        return {...prevState, cardsToSendPerStormMax: value}
                                                    })
                                                }}
                                                onChangeCommitted={(event, value: any) => {
                                                    setServiceArea(prevState => {
                                                        var serviceArea = {...prevState, cardsToSendPerStormMax: value}
                                                        updateCardsSendPerStormMax(serviceArea)
                                                        return serviceArea
                                                    })
                                                }}
                                                marks={[
                                                    {value: 2500, label: "2,500"},
                                                    {value: 10000, label: "10,000"},
                                                    {value: 20000, label: "20,000"},
                                                    {value: 30000, label: "30,000"},
                                                    {value: 40000, label: "40,000"},
                                                    {value: 50000, label: "50,000"}
                                                ]}
                                            />
                                        </MDBox>

                                        <Grid container alignItems={"flex-start"} gap={3} mt={2}>
                                            <Grid item flex={1}>
                                                <MDTypography sx={{fontSize: 14}} fontWeight={"bold"} mb={1}>
                                                    Start Date<MDTypography component="span" sx={{fontSize: 14}} fontWeight={"bold"} color={"error"} display={"inline-block"}>*</MDTypography>
                                                </MDTypography>

                                                <MDDatePicker
                                                    options={{
                                                        minDate: getProfileQuery.data?.loggedInUser.isAdmin? moment().subtract(60, 'days').format("MM/DD/YYYY") : moment().format("MM/DD/YYYY")
                                                    }}
                                                    onChange={(dates: Date, dateStr: string) => {
                                                        let newDate = moment(dateStr)

                                                        if(serviceArea.stopSendingAtDate) {
                                                            let endDate = moment(serviceArea.stopSendingAtDate)

                                                            if(newDate.isAfter(endDate)) {
                                                                setServiceArea(prevState => {
                                                                    return {...prevState, stopSendingAtDate: null}
                                                                })
                                                            }
                                                        }

                                                        setServiceArea(prevState => {
                                                            return {...prevState, startSendingAtDate: dateStr}
                                                        })
                                                    }}
                                                    input={{
                                                        disabled: !serviceArea.canEdit,
                                                        fullWidth: true,
                                                        placeholder: "Select a start date",
                                                        value: serviceArea.startSendingAtDate ? moment(serviceArea.startSendingAtDate).format("MM/DD/YYYY") : ""
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item flex={1}>
                                                <Grid container gap={1} alignItems={"center"} mb={1} width={"fit-content"} sx={{cursor: "pointer"}} onClick={() => {
                                                    if(!serviceArea.canEdit) {
                                                        return
                                                    }

                                                    setStopSendingAtDateFilter(prevState => !prevState)
                                                    setServiceArea(prevState => {
                                                        return {...prevState, stopSendingAtDate: null}
                                                    })
                                                }}>
                                                    <Grid item>
                                                        <MDTypography sx={{fontSize: 14}} fontWeight={"bold"}>
                                                            End Date
                                                        </MDTypography>
                                                    </Grid>
                                                    <Grid item display={"flex"}>
                                                        <Switch size={"small"} checked={stopSendingAtDateFilter} disabled={!serviceArea.canEdit}/>
                                                    </Grid>
                                                </Grid>

                                                <MDDatePicker
                                                    options={{
                                                        minDate: serviceArea.startSendingAtDate
                                                    }}
                                                    disabled={!serviceArea.canEdit}
                                                    onChange={(dates: Date, dateStr: string) => {
                                                        setServiceArea(prevState => {
                                                            return {...prevState, stopSendingAtDate: dateStr}
                                                        })
                                                    }}
                                                    input={{
                                                        fullWidth: true,
                                                        placeholder: "Select an end date",
                                                        disabled: !stopSendingAtDateFilter,
                                                        value: serviceArea.stopSendingAtDate ? moment(serviceArea.stopSendingAtDate).format("MM/DD/YYYY") : ""
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container gap={1} alignItems={"center"} mb={1.5} mt={3} width={"fit-content"} sx={{cursor: "pointer"}} onClick={() => {
                                            if(!serviceArea.canEdit) {
                                                return
                                            }

                                            let newState = !resendingFilter

                                            setServiceArea(prevState => {
                                                return {...prevState, resendAfterWeeks: newState? 1 : null}
                                            })

                                            setResendingFilter(newState)
                                        }}>
                                            <Grid item>
                                                <MDTypography sx={{fontSize: 14}} fontWeight={"bold"}>
                                                    Re-Sending Settings
                                                </MDTypography>
                                            </Grid>

                                            <Grid item display={"flex"}>
                                                <Switch size={"small"} checked={resendingFilter} disabled={!serviceArea.canEdit}/>
                                            </Grid>

                                            <Grid item>
                                                <Tooltip title={"If a homeowner in your service area has been impacted by multiple storms, don't send another handwritten card within the time selected"}>
                                                    <MDBox display={"flex"}>
                                                        <Info2Icon color={"light2"}/>
                                                    </MDBox>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>

                                        <Grid container alignItems={"center"} gap={3}>
                                            <Grid item flex={1}>
                                                <Select
                                                    disabled={!resendingFilter || !serviceArea.canEdit}
                                                    fullWidth={true}
                                                    value={serviceArea.resendAfterWeeks}
                                                    displayEmpty={true}
                                                    onChange={(event: any) => {
                                                        let val: number = null
                                                        if (event.target.value) {
                                                            val = parseInt(event.target.value)
                                                        }

                                                        setServiceArea(prevState => {
                                                            return {...prevState, resendAfterWeeks: val}
                                                        })
                                                    }}
                                                >
                                                    {Array.from({ length: 12 }, (_, i) => i + 1).map(vl =>
                                                        <MenuItem key={vl} value={vl}>
                                                            {vl} {vl > 1 ? 'weeks' : 'week'}
                                                        </MenuItem>
                                                    )}
                                                </Select>
                                            </Grid>

                                            <Grid item flex={1}>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </MDCard>
                    </Grid>

                    <Grid item flex={1} minWidth={"800px"}>
                        <MDBox display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{height: "600px"}}>
                            {hasMapLoaded ?
                                <GoogleMap
                                    onCenterChanged={() => {
                                        let newCenter = map?.getCenter()
                                        if(newCenter) setCenter({lng: newCenter.lng(), lat: newCenter.lat()})
                                    }}
                                    onLoad={(map) => {
                                        setMap(map)
                                    }}
                                    mapContainerStyle={mapContainerStyle}
                                    center={initialCenter}
                                    zoom={zoom}
                                    onZoomChanged={() => {
                                        if (map) {
                                            setZoom(map.getZoom())
                                        }
                                    }}
                                    options={mapOptions}
                                >
                                    {serviceArea.radii.map((address, index) => {
                                        return <MDBox key={index}>
                                            <OverlayView
                                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                                getPixelPositionOffset={(width, height) => ({
                                                    x: -(width / 2),
                                                    y: -(height / 2),
                                                })}
                                                position={{
                                                    lat: address.coordinate.lat,
                                                    lng: address.coordinate.lng
                                                }}
                                            >
                                                <MDTypography whiteSpace={"nowrap"} fontWeight={"bold"}
                                                              textAlign={"center"}
                                                              color={"white"}
                                                              sx={{textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black"}}>
                                                    {address.name}
                                                </MDTypography>
                                            </OverlayView>

                                            <Circle
                                                center={{
                                                    lat: address.coordinate.lat,
                                                    lng: address.coordinate.lng
                                                }}
                                                radius={address.radius * 1609.34}
                                                options={{
                                                    fillColor: colors.primary.main,
                                                    fillOpacity: 0.30,
                                                    strokeColor: colors.primary.main,
                                                    strokeOpacity: 0.8
                                                }}
                                            />
                                        </MDBox>
                                    })}

                                    {serviceArea.polygons.map((polygon, index) => {
                                        return <MDBox key={index}>
                                            <OverlayView
                                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                                getPixelPositionOffset={(width, height) => ({
                                                    x: -(width / 2),
                                                    y: -(height / 2),
                                                })}
                                                position={getCenterOfPolygon(polygon.coordinates)}
                                            >
                                                <MDTypography whiteSpace={"nowrap"} fontWeight={"bold"}
                                                              textAlign={"center"} color={"white"}
                                                              sx={{textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black"}}>
                                                    {polygon.name}
                                                </MDTypography>
                                            </OverlayView>

                                            <MapsPolygon
                                                key={polygon.id}
                                                path={polygon.coordinates}
                                                options={{
                                                    fillColor: colors.primary.main,
                                                    fillOpacity: 0.40,
                                                    strokeColor: colors.primary.main,
                                                    strokeOpacity: 1,
                                                    strokeWeight: 3
                                                }}
                                            />
                                        </MDBox>
                                    })}
                                </GoogleMap>
                                :
                                null
                            }
                        </MDBox>

                        <MDBox mt={2}>
                            <MDCard>
                                <MDBox p={4}>
                                    <MDTypography sx={{fontSize: 24}} fontWeight={"bold"} color={"secondary"}>Service Area</MDTypography>

                                    <MDBox mt={3}>
                                        <Grid container>
                                            <Grid item flex={1}>
                                                <MDTypography fontWeight={"bold"} sx={{fontSize: 17}} color={"secondary"}>
                                                    Service Area
                                                </MDTypography>
                                            </Grid>

                                            <Grid item flex={1}>
                                                <MDTypography fontWeight={"bold"} sx={{fontSize: 17}} align={"center"} color={"secondary"}>
                                                    Search Type
                                                </MDTypography>
                                            </Grid>

                                            <Grid item flex={1}>
                                                <MDTypography fontWeight={"bold"} sx={{fontSize: 17}} align={"center"} color={"secondary"}>
                                                    Radius (mi)
                                                </MDTypography>
                                            </Grid>

                                            <Grid item flex={1}>
                                                <MDTypography fontWeight={"bold"} sx={{fontSize: 17}} align={"center"} color={"secondary"}>
                                                    Structures
                                                </MDTypography>
                                            </Grid>

                                            <Grid item flex={1}>
                                                <MDTypography fontWeight={"bold"} sx={{fontSize: 17}} align={"center"} color={"secondary"}>
                                                    Action
                                                </MDTypography>
                                            </Grid>
                                        </Grid>

                                        {serviceArea.radii.map((address, index) => {
                                            return <Grid container mt={1} alignItems={"center"} key={index}>
                                                <Grid item flex={1}>
                                                    <MDTypography sx={{fontSize: 17}}>{address.name}</MDTypography>
                                                </Grid>

                                                <Grid item flex={1}>
                                                    <MDTypography sx={{fontSize: 17}} align={"center"}>
                                                        Radius
                                                    </MDTypography>
                                                </Grid>

                                                <Grid item flex={1}>
                                                    <MDInput
                                                        disabled={true}
                                                        fullWidth={true}
                                                        type="number"
                                                        inputProps={{
                                                            step: 0.1,
                                                            style: {textAlign: "center" }
                                                        }}
                                                        size={"small"}
                                                        value={address.radius}
                                                        onChange={(e: any) => {
                                                            setServiceArea((prevState: ServiceArea) => {
                                                                let newEntry = {...prevState}

                                                                for (let a of newEntry.radii) {
                                                                    if (a.id === address.id) {
                                                                        a.radius = parseFloat(e.target.value)
                                                                        break
                                                                    }
                                                                }

                                                                return newEntry
                                                            })
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item flex={1}>
                                                    <MDTypography sx={{
                                                        fontSize: 17,
                                                        color: (address.structures && address.structures < 100) || !address.structures ? 'red' : 'inherit'
                                                        }} align={"center"}>
                                                        {address.structures ?
                                                            formatNumber(address.structures)
                                                            :
                                                            shouldRefetch ?
                                                                <CircularProgress size={"25px"} color="primary"/>
                                                                :
                                                                '-'
                                                        }
                                                    </MDTypography>
                                                </Grid>

                                                <Grid item flex={1}>
                                                    <Grid container alignItems={"center"} gap={1}
                                                          justifyContent={"center"}>
                                                        <Grid item>
                                                            <Tooltip title={"See on map"}>
                                                                <MDIconButton backgroundColor={"light"} sx={{
                                                                    padding: 0,
                                                                    width: "42px",
                                                                    minWidth: "unset"
                                                                }} onClick={() => {
                                                                    map.setCenter({
                                                                        lat: address.coordinate.lat,
                                                                        lng: address.coordinate.lng
                                                                    })
                                                                    setZoom(12)
                                                                }}>
                                                                    <RadiusIcon color={"primary"}/>
                                                                </MDIconButton>
                                                            </Tooltip>
                                                        </Grid>

                                                        <Grid item>
                                                            <Tooltip title={"Delete"}>
                                                                <MDIconButton disabled={!serviceArea.canEdit} backgroundColor={"light"} sx={{
                                                                    padding: 0,
                                                                    width: "42px",
                                                                    minWidth: "unset"
                                                                }} onClick={() => deleteAddress(address.id)}>
                                                                    <DeleteIcon color={"error"}/>
                                                                </MDIconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        })}

                                        {serviceArea.polygons.map((polygon, index) => {
                                            return <Grid container mt={1} alignItems={"center"} key={index}>
                                                <Grid item flex={1}>
                                                    <MDTypography sx={{fontSize: 17}}>{polygon.name}</MDTypography>
                                                </Grid>

                                                <Grid item flex={1}>
                                                    <MDTypography sx={{fontSize: 17}} align={"center"}>
                                                        Polygon
                                                    </MDTypography>
                                                </Grid>

                                                <Grid item flex={1}>
                                                    <MDTypography sx={{fontSize: 17}} align={"center"}>
                                                        -
                                                    </MDTypography>
                                                </Grid>

                                                <Grid item flex={1}>
                                                    <MDTypography sx={{
                                                        fontSize: 17,
                                                        color: (polygon.structures && polygon.structures < 100) || !polygon.structures  ? 'red' : 'inherit'
                                                    }} align={"center"}>
                                                        {polygon.structures ?
                                                            formatNumber(polygon.structures)
                                                            :
                                                            shouldRefetch ?
                                                                <CircularProgress size={"25px"} color="primary"/>
                                                                :
                                                                '-'
                                                        }
                                                    </MDTypography>
                                                </Grid>

                                                <Grid item flex={1}>
                                                    <Grid container alignItems={"center"} gap={1}
                                                          justifyContent={"center"}>
                                                        <Grid item>
                                                            <Tooltip title={"See on map"}>
                                                                <MDIconButton backgroundColor={"light"} sx={{
                                                                    padding: 0,
                                                                    width: "42px",
                                                                    minWidth: "unset"
                                                                }} onClick={() => {
                                                                    map.setCenter(getCenterOfPolygon(polygon.coordinates))
                                                                    setZoom(12)
                                                                }}>
                                                                    <RadiusIcon color={"primary"}/>
                                                                </MDIconButton>
                                                            </Tooltip>
                                                        </Grid>

                                                        <Grid item>
                                                            <Tooltip title={"Delete"}>
                                                                <MDIconButton backgroundColor={"light"} sx={{
                                                                    padding: 0,
                                                                    width: "42px",
                                                                    minWidth: "unset"
                                                                }} disabled={!serviceArea.canEdit} onClick={() => deletePolygon(polygon.id)}>
                                                                    <DeleteIcon color={"error"}/>
                                                                </MDIconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        })}
                                    </MDBox>

                                    <Divider light={true}/>

                                    <MDBox>
                                        <Grid container flexDirection={"row"} flexWrap={"nowrap"} justifyContent={"space-between"} gap={3}>
                                            <Grid item width={"330px"}>
                                                <MDTypography sx={{fontSize: 18}} fontWeight={"bold"} color={"secondary"}>
                                                    Storm Automation Filters
                                                </MDTypography>

                                                <Grid container mt={2} alignItems={"center"} gap={0.5}>
                                                    <Grid item display={"flex"}>
                                                        <HailSizeIcon/>
                                                    </Grid>
                                                    <Grid item>
                                                        <MDTypography fontWeight={"bold"} sx={{fontSize: 15}} color={"secondary"}>
                                                            Hail Size:
                                                        </MDTypography>
                                                    </Grid>
                                                    <Grid item>
                                                        <MDTypography sx={{fontSize: 15}}>
                                                            {hailSizeMinFilter ? `${serviceArea.hailSizeMinFilter}" and larger` : '-'}
                                                        </MDTypography>
                                                    </Grid>
                                                </Grid>

                                                <Grid container mt={1} alignItems={"center"} gap={0.5}>
                                                    <Grid item display={"flex"}>
                                                        <WindSpeedIcon/>
                                                    </Grid>

                                                    <Grid item>
                                                        <MDTypography fontWeight={"bold"} sx={{fontSize: 15}} color={"secondary"}>
                                                            Wind Speed:
                                                        </MDTypography>
                                                    </Grid>

                                                    <Grid item>
                                                        <MDTypography sx={{fontSize: 16}}>
                                                            {windSpeedFilter ? `${serviceArea.windSpeedMinFilter} MPH+` : '-'}
                                                        </MDTypography>
                                                    </Grid>
                                                </Grid>

                                                <Grid container mt={1} alignItems={"center"} gap={0.5}>
                                                    <Grid item display={"flex"}>
                                                        <HomeAlt2Icon/>
                                                    </Grid>

                                                    <Grid item>
                                                        <MDTypography fontWeight={"bold"} sx={{fontSize: 15}} color={"secondary"}>
                                                            Home Owner:
                                                        </MDTypography>
                                                    </Grid>

                                                    <Grid item>
                                                        <MDTypography sx={{fontSize: 15}}>
                                                            {serviceArea.homeOwnerFilter !== null ? OwnerOrRenterFilter[serviceArea.homeOwnerFilter] : '-'}
                                                        </MDTypography>
                                                    </Grid>
                                                </Grid>

                                                <Grid container mt={1} alignItems={"center"} gap={0.5}>
                                                    <Grid item display={"flex"}>
                                                        <HomeEditIcon/>
                                                    </Grid>

                                                    <Grid item>
                                                        <MDTypography fontWeight={"bold"} sx={{fontSize: 15}} color={"secondary"}>
                                                            Property Type:
                                                        </MDTypography>
                                                    </Grid>

                                                    <Grid item>
                                                        <MDTypography sx={{fontSize: 15}}>
                                                            {serviceArea.propertyTypeFilter !== null ? PropertyTypeFilter[serviceArea.propertyTypeFilter] : '-'}
                                                        </MDTypography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item flex={1}>
                                                <Grid item flex={1}>
                                                    <MDTypography sx={{fontSize: 18}} fontWeight={"bold"} color={"secondary"}>
                                                        Product Details
                                                    </MDTypography>
                                                </Grid>

                                                <Grid container mt={2} alignItems={"center"} gap={0.5}>
                                                    <Grid item display={"flex"}>
                                                        <ProductIcon/>
                                                    </Grid>

                                                    <Grid item>
                                                        <MDTypography fontWeight={"bold"} sx={{fontSize: 15}} color={"secondary"}>
                                                            Product:
                                                        </MDTypography>
                                                    </Grid>

                                                    <Grid item>
                                                        <MDTypography sx={{fontSize: 15}}>
                                                            {serviceArea.template?.product?? "-"}
                                                        </MDTypography>
                                                    </Grid>
                                                </Grid>

                                                <Grid container mt={1} alignItems={"center"} gap={0.5}>
                                                    <Grid item display={"flex"}>
                                                        <PostageIcon/>
                                                    </Grid>

                                                    <Grid item>
                                                        <MDTypography fontWeight={"bold"} sx={{fontSize: 15}} color={"secondary"}>
                                                            Postage:
                                                        </MDTypography>
                                                    </Grid>

                                                    <Grid item>
                                                        <MDTypography sx={{fontSize: 15}}>
                                                            First Class (Fastest)
                                                        </MDTypography>
                                                    </Grid>
                                                </Grid>

                                                <Grid container mt={1} alignItems={"center"} gap={1} flexDirection={"row"} flexWrap={"nowrap"}>
                                                    <Grid item display={"flex"}>
                                                        <ProductSpeedIcon/>
                                                    </Grid>
                                                    <Grid item>
                                                        <MDTypography fontWeight={"bold"} sx={{fontSize: 15, textWrap: "nowrap"}} color={"secondary"}>
                                                            Production Speed:
                                                        </MDTypography>
                                                    </Grid>
                                                    <Grid item>
                                                        <MDTypography sx={{fontSize: 15}}>
                                                            Expedited Severe Storm: 24-72 hour production
                                                        </MDTypography>
                                                    </Grid>
                                                </Grid>

                                                <Grid container mt={1} alignItems={"center"} gap={1} flexDirection={"row"} flexWrap={"nowrap"}>
                                                    <Grid item display={"flex"}>
                                                        <HomeDollarIcon/>
                                                    </Grid>
                                                    <Grid item>
                                                        <MDTypography fontWeight={"bold"} sx={{fontSize: 15, textWrap: "nowrap"}} color={"secondary"}>
                                                            Cost per card:
                                                        </MDTypography>
                                                    </Grid>
                                                    <Grid item>
                                                        <MDTypography sx={{fontSize: 15, textWrap: "nowrap"}}>
                                                            {serviceArea.estimatedPricePerCard?formatPrice(serviceArea.estimatedPricePerCard??2.90):"-"} ea
                                                        </MDTypography>
                                                    </Grid>
                                                    <Grid item>
                                                        <MDTypography sx={{fontSize: 12}}>
                                                            *includes all options required for Severe Storm Automation
                                                        </MDTypography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <MDBox mt={3}>
                                            <MDButton
                                                fullWidth={true}
                                                color="primary"
                                                onClick={handleSubmit}
                                                disabled={!serviceArea.canEdit}
                                            >
                                                <Grid container alignItems={"center"} justifyContent={"center"} gap={1}>
                                                    <Grid item display={"flex"}>
                                                        <StormAutomationIcon/>
                                                    </Grid>
                                                    <Grid item>
                                                        Enable Storm Automation
                                                    </Grid>
                                                </Grid>
                                            </MDButton>
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            </MDCard>
                        </MDBox>
                    </Grid>
                </Grid>

                <ServiceAreaModal
                    show={showServiceAreaModal}
                    setShow={setShowServiceAreaModal}
                    showCenter={center}
                    showZoom={zoom}
                    serviceArea={serviceArea}
                    setServiceArea={setServiceArea}
                    geocoder={geocoder}
                    addAddress={addAddress}
                    addPolygon={addPolygon}
                    deleteAddress={deleteAddress}
                    deletePolygon={deletePolygon}
                    polygonsRef={polygonsRef}
                    updateServiceArea={updateServiceArea}
                    setShouldRefetch={setShouldRefetch}
                />

                <InfoModal
                    show={showSubmitErrorModal}
                    setShow={setShowSubmitErrorModal}
                    headerText={errorModalContent}
                    showCancelButton={false}
                    showConfirmButton={true}
                    confirmButtonOnClick={() => {
                        setShowSubmitErrorModal(false)
                    }}
                />

                <InfoModal
                    show={showSuccessModal}
                    setShow={setShowSuccessModal}
                    headerText={"Your storm automation has been successfully created. You can review it in the history page"}
                    showConfirmButton={true}
                    showCancelButton={false}
                    confirmButtonOnClick={() => navigate('/automations?new=selectAutomation')}
                />
                
                <InfoModal
                    show={showConfirmModal}
                    setShow={setShowConfirmModal}
                    headerText={confirmEnableModalContent}
                    showCancelButton={true}
                    showConfirmButton={true}
                    cancelButtonOnClick={() => {
                        setShowConfirmModal(false)
                    }}
                    confirmButtonOnClick={submitServiceArea}
                />

                <InfoModal
                    show={showCopyOrderModal}
                    setShow={setShowCopyOrderModal}
                    headerText={"Are you sure you want to copy (duplicate) this service area?"}
                    showConfirmButton={true}
                    showCancelButton={true}
                    cancelButtonOnClick={() => {
                        setShowCopyOrderModal(false)
                    }}
                    confirmButtonOnClick={() => {
                        copyOrder()
                    }}
        />
            </Grid>
            :
            null
        }
    </LoadScript>
}

export default StormAutomation