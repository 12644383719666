import React from 'react';

import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { PaymentMethod } from '@stripe/stripe-js';

import ACHIcon from 'assets/images/ach-icon.png';
import MDRadio from 'material-ui/components/MDRadio';

interface IACHList {
    loadingProfilePayments: boolean,
    userPaymentMethods: PaymentMethod[],
    selectedPaymentMethod: PaymentMethod | null,
    handleSelectPaymentMethod: (id: PaymentMethod) => void
}

const ACHList: React.FC<IACHList> = ({ loadingProfilePayments, userPaymentMethods, selectedPaymentMethod, handleSelectPaymentMethod}) => {

  const getCreditCardBrandToRender = () => {
    return <Box component="img" src={ACHIcon} width="32px" marginRight={'20px'} marginLeft={'12px'} />;
  };

return (
    <React.Fragment>
        {!loadingProfilePayments &&
            Array.isArray(userPaymentMethods) &&
            userPaymentMethods.map((p: PaymentMethod) => (
                <Box
                    key={p.id}
                    sx={{
                        padding: '.5rem 1rem',
                        margin: '1rem 0',
                        borderRadius: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        height: '70px',
                        gap: '5px',
                        position: 'relative',
                        background: 'linear-gradient(0deg, #F5F5F5, #FFFFFF)',
                        boxShadow: '-1px 2px 15px -7px rgba(0, 0, 0, 0.28)',
                    }}
                >
                    <MDRadio
                        value={p.id}
                        checked={p.id === selectedPaymentMethod?.id}
                        onChange={() => handleSelectPaymentMethod(p)}
                    />
                    {getCreditCardBrandToRender()}
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Typography variant="body2">
                            Banking Account ending in {p?.us_bank_account?.last4}
                        </Typography>
                        <Typography variant="caption" sx={{ padding: '.25rem 0', opacity: 0.8 }}>
                            {p?.us_bank_account?.bank_name}
                        </Typography>
                    </Box>
                </Box>
            )
        )}
    </React.Fragment>
);
}

export default ACHList;