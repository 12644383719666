import React, { useEffect, useState } from 'react';

import { Box } from '@mui/system';
import { IProfile } from 'models/profile';
import { getUserData } from 'helpers/getUserInfos';
import { useNavigate } from 'react-router-dom';
import { ICheckoutData } from 'services/checkout/checkout.types';
import { IPaymentMethod } from 'services/profile/profile.types';
import { enqueueSnackbar } from 'notistack';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Alert, CircularProgress, Typography, } from '@mui/material';
import { CardElement, useElements,useStripe } from '@stripe/react-stripe-js';

import NewACH from './components/NewACH';
import ACHList from './components/ACHList';
import MDButton from 'material-ui/components/MDButton';
import NewCreditCard from './components/NewCreditCard';
import ProfileService from 'services/profile';
import CreditCardList from './components/CreditCardList';
import useCheckoutDataService from 'services/checkout';
import LoadingProfilePayments from '../../LoadingProfilePayments';

interface IPayments {
    data: ICheckoutData | any;
    subscriptionType: 'upgrade' | 'authenticated_checkout' ;
}

const Payments: React.FC<IPayments> = ({ data, subscriptionType }) => {

    const YEARLY = 4;

    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const { addProfilePaymentMethod, getProfilePaymentMethods, getProfile } = ProfileService();
    const { makeUpgradeSubscription, makeSubscription } = useCheckoutDataService();

    // UI + state
    const [submiting, setSubmiting] = useState(false);
    const [paymentFailed, setPaymentFailed] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [canSubmitPayment, setCanSubmitPayment] = useState<boolean>(false);
    const [isSubmitingPayment, setIsSubmitingPayment] = useState<boolean>(false);

    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<'new credit card' | 'new ach' | string>('');

    const [handleCallProfileToUpdateStorage, setHandleCallProfileToUpdateStorage] = useState(false);

    const { data: UserPaymentMethods, isLoading: loadingProfilePayments, refetch: handleRefetchProfilePayments, isRefetching: isRefetchingProfilePayments } = useQuery({
        queryKey: ['profilePaymentMethods'],
        queryFn: getProfilePaymentMethods,
        refetchOnWindowFocus: false,
    });

    // For the final “subscribe” flow when is Upgrade Plan
    const { mutate: makeUpgrade } = useMutation({
        mutationFn: makeUpgradeSubscription,
        onSuccess: (response) => {
            if (response && response.hasErrors === false) {
                try {
                    setTimeout(() => {
                        setHandleCallProfileToUpdateStorage(true);

                        getProfileQuery.refetch().then(() => {
                            profile = getProfileQuery.data;
                            sessionStorage.setItem(
                                btoa('userData'),
                                btoa(JSON.stringify(profile))
                            );
                            navigate('/subscriptions/callback');
                        })
                            .catch(() => {
                                enqueueSnackbar('Failed to fetch profile. Try again later.', {
                                    variant: 'error',
                                });
                            });
                    }, 8000);
                } catch (error) {
                    enqueueSnackbar('Failed to fetch profile. Try again later.', {
                        variant: 'error',
                    });
                }
            }

            if (
                !response ||
                (response && response.hasErrors)
            ) {
                enqueueSnackbar(
                    response && response.errors
                        ? response.errors[0]
                        : 'Something went wrong. Try again later.',
                    { variant: 'error' }
                );
                setSubmiting(false);
            }
        },
    });

    // For the final “subscribe” flow when is Authenticated Checkout
    const { mutate: makeAuthenticatedSubscription } = useMutation({
        mutationFn: makeSubscription,
        onSuccess: (response) => {
            if (response && response.hasErrors === false) {
                try {
                    setTimeout(() => {
                        setHandleCallProfileToUpdateStorage(true);

                        getProfileQuery.refetch().then(() => {
                            profile = getProfileQuery.data;
                            sessionStorage.setItem(
                                btoa('userData'),
                                btoa(JSON.stringify(profile))
                            );
                            setPaymentFailed(false);
                            navigate('/subscriptions/callback');
                        })
                            .catch(() => {
                                enqueueSnackbar('Failed to fetch profile. Try again later.', {
                                    variant: 'error',
                                });
                            });
                    }, 8000);
                } catch (error) {
                    enqueueSnackbar('Failed to fetch profile. Try again later.', {
                        variant: 'error',
                    });
                }
            }

            if (
                !response ||
                (response && response.hasErrors)
            ) {
                setPaymentFailed(true);
                setSubmiting(false);
            }
        },
    });

    // 1. Use one query hook with merged options
    const getProfileQuery = useQuery<IProfile>({
        queryKey: ['profile'],
        queryFn: getProfile,
        refetchOnWindowFocus: false,
        enabled: handleCallProfileToUpdateStorage,
    });

    // 2. Destructure the data from the query result
    let profile = getProfileQuery.data;

    // Called when user chooses a new PaymentMethod on the server
    const { mutate: addPaymentMethod } = useMutation({
        mutationFn: addProfilePaymentMethod,
        onSuccess: (result) => {
            if (result.hasErrors) {
                setErrorMessage(result.errors[0]);
            } else {
                setSelectedPaymentMethodId(result.id);
                handleSubscribe(); // once the new PaymentMethod is created, proceed
            }
            setTimeout(() => {
                setErrorMessage(null);
            }, 8000);
        },
    });

    // Grab the first existing payment method by default
    useEffect(() => {
        if (UserPaymentMethods?.payload?.length === 1) {
            setSelectedPaymentMethodId(UserPaymentMethods.payload[0].id);
        }
    }, [UserPaymentMethods]);

    useEffect(() => {
        if(selectedPaymentMethodId === 'new ach') {
            setSubmiting(true);
        }
    }, [selectedPaymentMethodId])

    // For radio select
    const handleSelectPaymentMethodId = (id: string) => {
        setSelectedPaymentMethodId(id);
    };

    // Subscribe or pay
    const handleSubscribe = () => {
        setSubmiting(true);
        if (selectedPaymentMethodId === 'new credit card') {
            handleCardPaymentFlow();
        } else {
            // Use existing PaymentMethod
            const objEnv = {
                ...data,
                paymentMethodId: selectedPaymentMethodId,
            };

            if(subscriptionType === 'upgrade') {
                makeUpgrade(objEnv);
            } else {
                makeAuthenticatedSubscription(objEnv)
            }
        }
    };

    // 1) Create PaymentMethod for credit card
    // 2) If success, pass ID to backend, proceed
    const handleCardPaymentFlow = async () => {
        if (!stripe || !elements) {
            setErrorMessage('Stripe not loaded');
            return;
        }
        setIsSubmitingPayment(true);

        const card = elements.getElement(CardElement);
        if (!card) {
            setErrorMessage('CardElement not found');
            setIsSubmitingPayment(false);
            return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card,
        });

        if (error) {
            setErrorMessage(error.message || 'Error creating card PaymentMethod');
            setIsSubmitingPayment(false);
            setSubmiting(false);
            return;
        }

        if (paymentMethod?.id) {
            // Send the new PM id to your backend for final attach/verify
            addPaymentMethod(paymentMethod.id);
        }
    };

    return (
        <React.Fragment>
            {/* Existing Payment Methods */}
            <CreditCardList 
                loadingProfilePayments={loadingProfilePayments || isRefetchingProfilePayments} 
                userPaymentMethods={UserPaymentMethods?.payload ? UserPaymentMethods?.payload.filter((i: IPaymentMethod) => i.type !== 'us_bank_account') : []} 
                selectedPaymentMethodId={selectedPaymentMethodId} 
                handleSelectPaymentMethodId={paymentId => handleSelectPaymentMethodId(paymentId)}
            />

            {
                getUserData()?.customerSubscription?.billingPeriodId === YEARLY && (
                    <ACHList 
                        loadingProfilePayments={loadingProfilePayments || isRefetchingProfilePayments} 
                        userPaymentMethods={UserPaymentMethods?.payload ? UserPaymentMethods?.payload.filter((i: IPaymentMethod) => i.type === 'us_bank_account') : []} 
                        selectedPaymentMethodId={selectedPaymentMethodId} 
                        handleSelectPaymentMethodId={paymentId => handleSelectPaymentMethodId(paymentId)}
                    />
                )
            }

            {(loadingProfilePayments || isRefetchingProfilePayments) && <LoadingProfilePayments />}

            {/* Add New Credit Card */}
            <NewCreditCard 
                loadingProfilePayments={loadingProfilePayments || isRefetchingProfilePayments} 
                selectedPaymentMethodId={selectedPaymentMethodId} 
                setSelectedPaymentMethodId={(value: string) => setSelectedPaymentMethodId(value)} 
                setCanSubmitPayment={(value: boolean) => setCanSubmitPayment(value)} errorMessage={errorMessage} 
            />

            {
                getUserData()?.customerSubscription?.billingPeriodId === YEARLY && (
                    <NewACH 
                        data={data} 
                        profile={profile}
                        loadingProfilePayments={loadingProfilePayments || isRefetchingProfilePayments} 
                        selectedPaymentMethodId={selectedPaymentMethodId}
                        setSubmiting={(value: boolean) => setSubmiting(value)} 
                        setErrorMessage={(value: string | null) => setErrorMessage(value)}
                        handleUpdateList={() => {
                            setSubmiting(false);
                            handleRefetchProfilePayments()}
                        }
                        setIsSubmitingPayment={(value: boolean) => setIsSubmitingPayment(value)}
                        setSelectedPaymentMethodId={(value: string) => setSelectedPaymentMethodId(value)} 
                    />
                )
            }

            {
                paymentFailed && (
                    <Alert severity="error" sx={{ mt: 1, mb: 2 }}>
                        {'Payment failed. Please choose or add a new payment method to continue.'}
                    </Alert>
                )
            }

            {/* Subscribe Button */}
            <MDButton
                fullWidth
                sx={{ height: '50px', fontSize: '16px' }}
                disabled={
                    loadingProfilePayments ||
                    submiting ||
                    isRefetchingProfilePayments ||
                    !selectedPaymentMethodId ||
                    (selectedPaymentMethodId === 'new credit card' && (!canSubmitPayment || isSubmitingPayment))
                }
                onClick={() => handleSubscribe()}
            >
                {submiting ? <CircularProgress size={15} color="inherit" /> : 'Subscribe'}
            </MDButton>

            <Box sx={{ display: 'flex', justifyContent: 'center', margin: '1.5rem 0' }}>
                <Typography
                    variant="caption"
                    sx={{ fontSize: 15, textAlign: 'center', width: '100%', lineHeight: '1.7rem' }}
                >
                    By confirming your subscription, you allow Stripe to
                    <br />
                    charge you for future payments in accordance with their
                    <br />
                    terms. You can always cancel your subscription.
                </Typography>
            </Box>
        </React.Fragment>
    );
};

export default Payments;
