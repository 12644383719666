import useFetchWithMsal from 'hooks/useFetch';
import API_ENDPOINT, { IObjectResult, mockedObjectResult } from '../endpoint';
import { IVM_GridOrder } from 'models/views/gridOrderVm';
import { IMoverAutomation, IRecipientsCountStatus, ISmartAutomationPayload, ISmartAutomationsResponse } from './smart-automation.types';
import { ICheckout } from 'models/checkout';
import { PreviewRecipientsPayload } from 'models/previewRecipientsPayload ';
import { ApiResponse } from 'models/apiResponse';

const useSmartAutomationService = () => {
  const { execute } = useFetchWithMsal();
  const API_PATH = `${API_ENDPOINT}/smart-automations`;

  const getSmartAutomation = (id?: number): Promise<IObjectResult<ISmartAutomationPayload>> => {
    if (!id) return Promise.resolve(mockedObjectResult({ payload: '' }));
    return execute({
      endpoint: `${API_PATH}/${id || ''}`,
      method: 'GET',
    });
  };

  const getAllSmartAutomations = (): Promise<ISmartAutomationsResponse> => {
    return execute({
      endpoint: API_PATH,
      method: 'GET',
    });
  };

  const getViewModelByDataSource = (id: number): Promise<IObjectResult<string>> => {
    // return new Promise((resolve) => resolve({ payload: MockData.payload }));
    return execute({
      endpoint: `${API_PATH}/data-sources/${id}/smart-automation-data-source-viewmodel`,
      method: 'GET',
    });
  };

  const getPreviewRecipientsCountStatus = (profileDataProviderId: number): Promise<IObjectResult<IRecipientsCountStatus>> => {
    return execute({
      endpoint: `${API_PATH}/data-sources/${profileDataProviderId}/preview-recipients-count-status`,
      method: 'GET',
    });
  };

  const createSmartAutomation = (
    smartAutomation: ISmartAutomationPayload
  ): Promise<{ payload: ISmartAutomationPayload }> => {
    return execute({
      endpoint: API_PATH,
      method: 'POST',
      data: smartAutomation,
    });
  };

  const updateSmartAutomation = (
    smartAutomation: ISmartAutomationPayload
  ): Promise<{ payload: ISmartAutomationPayload }> => {
    return execute({
      endpoint: `${API_PATH}/${smartAutomation.id}`,
      method: 'PUT',
      data: smartAutomation,
    });
  };

  const copySmartAutomation = (smartAutomation: IVM_GridOrder) => {
    return execute({
      endpoint: `${API_PATH}/copy`,
      method: 'POST',
      data: smartAutomation,
    });
  };

  const deleteSmartAutomation = (id: number) => {
    return execute({
      endpoint: `${API_PATH}/${id}`,
      method: 'DELETE',
    });
  };

  const calculateTargets = (smartAutomation: ISmartAutomationPayload): Promise<{ payload: IMoverAutomation }> => {
    return execute({
      endpoint: `${API_ENDPOINT}/movers/calculate-targets`,
      method: 'POST',
      data: smartAutomation,
    });
  };

  const calculateZipcodes = (id: string): Promise<{ payload: IMoverAutomation }> => {
    return execute({
      endpoint: `${API_ENDPOINT}/movers/calculate-zipcode-boundaries`,
      method: 'POST',
      data: { id },
    });
  };

  const getMoversPoll = (recipientSearchId = 0): Promise<{ payload: IMoverAutomation }> => {
    return execute({
      endpoint: `${API_ENDPOINT}/movers/recipient-search/${recipientSearchId}`,
      method: 'GET',
    });
  };

  const getMoversZipPoll = (id: string): Promise<{ payload: IMoverAutomation }> => {
    return execute({
      endpoint: `${API_ENDPOINT}/movers/${id}:zip-code`,
      method: 'GET',
    });
  };

  const getMoversCheckout = (smartAutomation: ISmartAutomationPayload): Promise<{ payload: ICheckout }> => {
    return execute({
      endpoint: `${API_ENDPOINT}/movers/checkout`,
      method: 'POST',
      data: smartAutomation,
    });
  };

  const enableMoversAutomation = (smartAutomation: ISmartAutomationPayload): Promise<{ payload: ICheckout }> => {
    return execute({
      endpoint: `${API_ENDPOINT}/movers/enable`,
      method: 'POST',
      data: smartAutomation,
    });
  };

  const getLeadRevealCheckout = (
    smartAutomation: ISmartAutomationPayload
  ): Promise<{ payload: { totalCharges: number; totalQuantity: number } }> => {
    return execute({
      endpoint: `${API_ENDPOINT}/lead-reveal/checkout`,
      method: 'POST',
      data: smartAutomation,
    });
  };

  const calculatePreviewRecipientsCount = (smartAutomation: ISmartAutomationPayload): Promise<ApiResponse<PreviewRecipientsPayload>> => {
    return execute({
      endpoint: `${API_PATH}/data-sources/calculate-preview-recipients-count`,
      method: 'POST',
      data: smartAutomation,
    });
  };

  return {
    getSmartAutomation,
    getAllSmartAutomations,
    getViewModelByDataSource,
    getPreviewRecipientsCountStatus,
    createSmartAutomation,
    updateSmartAutomation,
    copySmartAutomation,
    deleteSmartAutomation,
    calculateTargets,
    calculateZipcodes,
    getMoversPoll,
    getMoversZipPoll,
    getMoversCheckout,
    enableMoversAutomation,
    getLeadRevealCheckout,
    calculatePreviewRecipientsCount
  };
};

export default useSmartAutomationService;
