import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import MDCard from 'material-ui/components/MDCard';
import MDTypography from 'material-ui/components/MDTypography';
import MDButton from 'material-ui/components/MDButton';
import NewSmartAutomationModal from 'components/new-smart-automation-modal';
import PlusIcon from 'assets/icons/plus';

const SmartAutomationPage = () => {
  const navigate = useNavigate();
  const [showNewAutomationModal, setShowNewAutomationModal] = useState<boolean>(false);

  return (
    <MDCard>
      <Box p={3}>
        <Grid container alignItems={'center'} justifyContent={'space-between'} mb={3}>
          <MDTypography sx={{ fontSize: 26 }} fontWeight={'bold'}>
            Smart Automations
          </MDTypography>
          <Box display={'flex'} gap={1}>
            <MDButton color="light" onClick={() => navigate('/settings/smart-automation-setup')}>
              <SettingsIcon style={{ marginRight: 8 }} /> Setup Sources
            </MDButton>
            <MDButton onClick={() => setShowNewAutomationModal(true)}>
              <PlusIcon style={{ marginRight: 8 }} /> New Automation
            </MDButton>
          </Box>
        </Grid>

      </Box>
      <NewSmartAutomationModal open={showNewAutomationModal} setOpen={setShowNewAutomationModal} />
    </MDCard>
  );
};

export default SmartAutomationPage;

