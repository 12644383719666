import React, { useEffect, useState } from 'react';

import { IProfile } from 'models/profile';
import { getUserData } from 'helpers/getUserInfos';
import { useSnackbar } from 'notistack';
import { PaymentMethod } from '@stripe/stripe-js';
import { ICheckoutData } from 'services/checkout/checkout.types';
import { IPaymentMethod } from 'services/profile/profile.types';
import { useMutation, useQuery } from '@tanstack/react-query';

import NewACH from './components/NewACH';
import ACHList from './components/ACHList';
import NewCreditCard from './components/NewCreditCard';
import ProfileService from 'services/profile';
import CreditCardList from './components/CreditCardList';
import LoadingProfilePayments from './components/LoadingProfilePayments';

interface IPayments {
    data: ICheckoutData | any;
    handleSelectedPayment: (paymentMethod: PaymentMethod) => void
}

const PaymentManager: React.FC<IPayments> = ({ data, handleSelectedPayment }) => {
    const YEARLY = 4;
    const { enqueueSnackbar } = useSnackbar();

    const { addProfilePaymentMethod, getProfilePaymentMethods, getProfile } = ProfileService();

    // UI + state
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [paymentSelected, setPaymentSelected] = useState<PaymentMethod>();
    const [isSubmitingPayment, setIsSubmitingPayment] = useState<boolean>(false);

    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<'new credit card' | 'new ach' | string>('');

    const handleCallProfileToUpdateStorage = false;

    const { data: UserPaymentMethods, isLoading: loadingProfilePayments, refetch: handleRefetchProfilePayments, isRefetching: isRefetchingProfilePayments } = useQuery({
        queryKey: ['profilePaymentMethods'],
        queryFn: getProfilePaymentMethods,
        refetchOnWindowFocus: false,
    });

    // 1. Use one query hook with merged options
    const getProfileQuery = useQuery<IProfile>({
        queryKey: ['profile'],
        queryFn: getProfile,
        refetchOnWindowFocus: false,
        enabled: handleCallProfileToUpdateStorage,
    });

    // 2. Destructure the data from the query result
    let profile = getProfileQuery.data;

    // Called when user chooses a new PaymentMethod on the server
    const { mutate: addPaymentMethod } = useMutation({
        mutationFn: addProfilePaymentMethod,
        onSuccess: (result) => {
            if (result.hasErrors) {
                setErrorMessage(result.errors[0]);
            } else {
                setSelectedPaymentMethodId(result.id);
                setIsSubmitingPayment(false);
                handleRefetchProfilePayments();
                enqueueSnackbar('Payment method added successfully.', { variant: 'success' } );
            }
            setTimeout(() => {
                setErrorMessage(null);
            }, 8000);
        },
    });

    // Grab the first existing payment method by default
    useEffect(() => {
        if (UserPaymentMethods?.payload?.length === 1) {
            setSelectedPaymentMethodId(UserPaymentMethods.payload[0].id);
        }
    }, [UserPaymentMethods]);

    useEffect(() => {
        if(selectedPaymentMethodId === 'new ach') {
            setIsSubmitingPayment(true);
        }
    }, [selectedPaymentMethodId])

    return (
        <React.Fragment>
            {/* Existing Payment Methods */}
            <CreditCardList 
                handleSelectPaymentMethod={(value: PaymentMethod) => {setPaymentSelected(value); handleSelectedPayment(value)}}
                selectedPaymentMethod={paymentSelected ?? null}
                loadingProfilePayments={loadingProfilePayments || isRefetchingProfilePayments} 
                userPaymentMethods={UserPaymentMethods?.payload ? UserPaymentMethods?.payload.filter((i: IPaymentMethod) => i.type !== 'us_bank_account') : []} 
            />
            {
                getUserData()?.customerSubscription?.billingPeriodId === YEARLY && (
                    <ACHList 
                        selectedPaymentMethod={paymentSelected ?? null}
                        loadingProfilePayments={loadingProfilePayments || isRefetchingProfilePayments} 
                        userPaymentMethods={UserPaymentMethods?.payload ? UserPaymentMethods?.payload.filter((i: IPaymentMethod) => i.type === 'us_bank_account') : []} 
                        handleSelectPaymentMethod={(value: PaymentMethod) => {setPaymentSelected(value); handleSelectedPayment(value)}}
                    />
                )
            }

            {(loadingProfilePayments || isRefetchingProfilePayments) && <LoadingProfilePayments />}

            {/* Add New Credit Card */}
            <NewCreditCard 
                handleAddPaymentMethod={(value: string) => addPaymentMethod(value)}
                errorMessage={errorMessage}
                setErrorMessage={(value: string | null) => setErrorMessage(value)}
                loadingProfilePayments={loadingProfilePayments || isRefetchingProfilePayments} 
                selectedPaymentMethodId={selectedPaymentMethodId} 
                setSelectedPaymentMethodId={(value: string) => setSelectedPaymentMethodId(value)} 
                submitingNewPaymentMethod={isSubmitingPayment}
                setSubmitingNewPaymentMethod={(value: boolean) => setIsSubmitingPayment(value)}
            />

            {
                getUserData()?.customerSubscription?.billingPeriodId === YEARLY && (
                    <NewACH 
                        data={data} 
                        profile={profile}
                        loadingProfilePayments={loadingProfilePayments || isRefetchingProfilePayments} 
                        selectedPaymentMethodId={selectedPaymentMethodId}
                        setSubmiting={(value: boolean) => setIsSubmitingPayment(value)} 
                        setErrorMessage={(value: string | null) => setErrorMessage(value)}
                        handleUpdateList={() => {
                            setIsSubmitingPayment(false);
                            handleRefetchProfilePayments()}
                        }
                        setIsSubmitingPayment={(value: boolean) => setIsSubmitingPayment(value)}
                        setSelectedPaymentMethodId={(value: string) => setSelectedPaymentMethodId(value)} 
                    />
                )
                
            }

            
        </React.Fragment>
    );
};

export default PaymentManager;
