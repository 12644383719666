import { useLocation } from 'react-router-dom';
import { Field, FieldArrayRenderProps, FieldProps } from 'formik';
import { Chip, FormControl, Grid, IconButton, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import DeleteIcon from 'assets/icons/delete';
import FormikErrorMessage from 'components/formik-error-message';
import {
  IDataSourceFilter,
  IOperator,
  ISmartAutomationSchema,
  ISourceViewModel,
  IUserCreatedFilter,
} from 'services/smart-automation/smart-automation.types';
import useSmartAutomationService from 'services/smart-automation';
import { convertKeysToCamelCase } from 'helpers/pascalToCamelCase';

interface SmartAutomationFilterItemProps extends FieldArrayRenderProps {
  orIndex: number;
  andIndex: number;
  filter: IUserCreatedFilter;
  deleteOrGroup: () => void;
  disabled?: boolean;
}

const SmartAutomationFilterItem = ({
  filter,
  andIndex,
  orIndex,
  disabled,
  deleteOrGroup,
  ...arrayHelpers
}: SmartAutomationFilterItemProps) => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const source = queryParams.get('source');

  const { getViewModelByDataSource } = useSmartAutomationService();
  const { data: vmData } = useQuery({
    queryKey: ['getViewModelByDataSource', Number(source)],
    queryFn: () => getViewModelByDataSource(Number(source)),
    refetchOnWindowFocus: false,
    enabled: source !== '1'
  });

  const sourceVm: ISourceViewModel = vmData?.payload ? convertKeysToCamelCase(JSON.parse(vmData.payload)) : {};

  const { userCreatedFilters }: ISmartAutomationSchema = arrayHelpers.form.values;
  const andList = userCreatedFilters[orIndex].integrationOrderFilters;
  const hasSelectedColumn = andList[andIndex].dataSourceFilter;
  const operatorAndValueSx = {
    opacity: hasSelectedColumn ? 1 : 0.5,
    pointerEvents: hasSelectedColumn ? 'all' : 'none',
  };

  const onColumnChange = (index: number, event: SelectChangeEvent<number>) => {
    const dataSourceFilter = sourceVm?.filterOptions?.find((el) => el.id === event.target.value);
    arrayHelpers.replace(index, { dataSourceFilter, operator: null, value: null });
  };

  const onOperatorChange = (index: number, event: SelectChangeEvent<number>) => {
    const auxFilters = [...userCreatedFilters];
    const { dataSourceFilter } = auxFilters[orIndex].integrationOrderFilters[andIndex];
    const operator = dataSourceFilter?.operatorOptions?.find((el) => el.id === event.target.value);
    arrayHelpers.replace(index, { dataSourceFilter, operator, value: null });
  };

  const handleOrderColumnsItems = (items: IDataSourceFilter[]) => {
    if(!items) return []
    return items.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
  }

  const handleOrderOperatorItems = (items: IOperator[]) => {
    if(!items) return []
    return items.sort((a, b) => (b.operatorShortName ?? "").localeCompare(a.operatorShortName ?? ""));
  }

  const onDelete = () => {
    if (andList.length === 1) deleteOrGroup();
    else arrayHelpers.remove(andIndex);
  };

  return (
    <Grid container gap={1}>
      {andList?.length > 1 && (
        <Grid item width={54}>
          {andIndex !== 0 && <Chip label="AND" color="secondary" sx={{ marginTop: -6, fontWeight: 700 }} />}
        </Grid>
      )}
      <Grid item lg={3} minWidth={200}>
        <Field name={`userCreatedFilters.${orIndex}.integrationOrderFilters.${andIndex}.dataSourceFilter`}>
          {({ field, meta }: FieldProps) => (
            <FormControl fullWidth>
              <Select
                displayEmpty
                aria-label="Column"
                value={field.value?.id ?? ""}
                onChange={(event) => onColumnChange(andIndex, event)}
                error={!!meta.touched && !!meta.error}
                disabled={disabled}
              >
                <MenuItem value="" disabled>
                  Select a column
                </MenuItem>
                {handleOrderColumnsItems(sourceVm?.filterOptions)?.map(({ id, shortName }: IDataSourceFilter) => (
                  <MenuItem key={`column${orIndex}${andIndex}${id}`} value={id}>
                    { shortName }
                  </MenuItem>
                ))}
              </Select>
              <FormikErrorMessage meta={meta} />
            </FormControl>
          )}
        </Field>
      </Grid>

      <Grid item lg={3} minWidth={200} sx={operatorAndValueSx}>
        <Field name={`userCreatedFilters.${orIndex}.integrationOrderFilters.${andIndex}.operator`}>
          {({ field, meta }: FieldProps) => (
            <FormControl fullWidth>
              <Select
                displayEmpty
                aria-label="Operator"
                value={field.value?.id ?? ""}
                onChange={(event) => onOperatorChange(andIndex, event)}
                error={!!meta.touched && !!meta.error}
                disabled={disabled}
              >
                <MenuItem value="" disabled>
                  Select an operator
                </MenuItem>
                {handleOrderOperatorItems(andList[andIndex].dataSourceFilter?.operatorOptions ?? []).map(({ id, operator }: IOperator) => (
                  <MenuItem key={`operator${orIndex}${andIndex}${id}`} value={id}>
                    {operator } 
                  </MenuItem>
                ))}
              </Select>
              <FormikErrorMessage meta={meta} />
            </FormControl>
          )}
        </Field>
      </Grid>

      <Grid item flex={1} minWidth={200} sx={operatorAndValueSx}>
        <Field name={`userCreatedFilters.${orIndex}.integrationOrderFilters.${andIndex}.value`}>
          {({ field, meta }: FieldProps) => (
            <FormControl fullWidth>
              <TextField
                {...field}
                aria-label="Value"
                placeholder="Type your value here"
                type={andList[andIndex]?.dataSourceFilter?.valueType?.toLowerCase()}
                value={field.value ?? ""}
                disabled={!andList[andIndex]?.operator?.showUi || disabled}
                error={!!meta.touched && !!meta.error}
                helperText={meta.error}
              />
            </FormControl>
          )}
        </Field>
      </Grid>

      <Grid item>
        <IconButton size="large" onClick={onDelete} disabled={disabled}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default SmartAutomationFilterItem;
