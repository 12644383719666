import {  useState } from "react";
import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import moment from "moment";

import MDCard from "material-ui/components/MDCard";
import MDTypography from "material-ui/components/MDTypography";
import { IVM_GridOrder } from "models/views/gridOrderVm";
import EditPencilIcon from "assets/icons/edit-pencil";
import { OrderStatus } from "models/enums/orderStatus";
import OrderService from "services/order";
import DeleteIcon from "assets/icons/delete";
import MDIconButton from "material-ui/components/MDIconButton";
import StatusTracker from "components/status-tracker";
import OrderDetailsModal from "components/order-details-modal";
import InfoModal from "components/info-modal";
import TemplateScenePreview from "components/template-scene-preview";
import { useGlobal } from "context/global-context";
import { ProductOptions } from "newStandard/src/services/order/types";

interface OrderCardProps {
  order: IVM_GridOrder
}

const OrderCard = ({ order }: OrderCardProps) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { setShowLoader } = useGlobal()
  const { deleteOrder } = OrderService()

  const [showModal, setShowModal] = useState<string>('')

  const { mutate: removeOrder } = useMutation({
    mutationFn: () => deleteOrder(order.id),
    onMutate: () => setShowLoader(true),
    onSuccess: () => {
      setShowLoader(false)
      setShowModal('')
      queryClient.invalidateQueries({ queryKey: ["orders"] })
    }
  })

  const statusForTracker = (status: string) => {
    if (status === OrderStatus.Draft || status === OrderStatus.EditsNeeded) return "Draft"
    if (status === OrderStatus.Paid || status === OrderStatus.PaymentNeeded || status === OrderStatus.ReadyForProduction || status === OrderStatus.InShipping) return "Production"
    if (status === OrderStatus.Mailed) return "Mailed"
  }

  return (
    <Grid item xs={12} md={6} lg={4} xl={3} p={1} key={order.id}>
      <MDCard border boxShadow={false} color={"light"}
        onClick={() => setShowModal(prev => prev ? prev : 'details')}
        sx={{ padding: 2, height: "100%", cursor: "pointer", "&:hover": { boxShadow: "0px 2px 8px lightgray" } }}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Stack width={"80%"}>
            <Typography variant={"caption"} fontWeight={"bold"} color="text">
              #{order.id}
            </Typography>
            <MDTypography color="text" variant={"body2"} fontWeight={"bold"} sx={{ overflow: "hidden", textOverflow: "ellipsis", textWrap: "nowrap" }}>
              {order.name ? order.name : "(No name)"}
            </MDTypography>
          </Stack>

          {(order.status === OrderStatus.Draft || order.status === OrderStatus.PaymentNeeded || order.status === OrderStatus.EditsNeeded) &&
            <Box display={"flex"} gap={1} zIndex={2}>
              <Tooltip title="Edit Order">
                <MDIconButton square onClick={() => navigate(`/order/${order.id}`)}>
                  <EditPencilIcon htmlColor={"#646367"} />
                </MDIconButton>
              </Tooltip>
              <Tooltip title="Delete Order">
                <MDIconButton square onClick={() => setShowModal('delete')}>
                  <DeleteIcon color={"error"} />
                </MDIconButton>
              </Tooltip>
            </Box>
          }
        </Box>

        <Box my={1}>
          {order.estimatedDeliveryDateMin && order.estimatedDeliveryDateMax ?
            <MDTypography variant={"body2"} fontWeight={"bold"} color="text">
              {!(order.status === OrderStatus.Mailed && order.mailedDate) && "Est. "}
              Delivery: {' '}
              <MDTypography component="span" variant={"body2"} fontWeight={"regular"} color="text">
                {`${moment(order.estimatedDeliveryDateMin).format("MMM Do")} - ${moment(order.estimatedDeliveryDateMax).format("MMM Do")}`}
              </MDTypography>
            </MDTypography>
            :
            order.mailedDate ?
              <MDTypography variant={"body2"} fontWeight={"bold"} color="text">
                Mailed Date:{' '}
                <MDTypography component="span" variant={"body2"} fontWeight={"regular"} color="text">
                  {moment(order.mailedDate).format("MMM Do")}
                </MDTypography>
              </MDTypography>
              :
              <MDTypography variant={"body2"} fontWeight={"bold"} color="text">&nbsp;</MDTypography>
          }
          <MDTypography variant={"body2"} fontWeight={"bold"} color="text">
            Product: {' '}
            <MDTypography component="span" variant={"body2"} fontWeight={"regular"} color="text">
              {order.product}
            </MDTypography>
          </MDTypography>
        </Box>

        <StatusTracker statuses={["Draft", "Production", "Mailed"]} currentStatus={statusForTracker(order.status) ?? ''} />
        <Box display={"flex"} alignItems={"end"} height={"100%"}>
          <TemplateScenePreview {...order} variant="elevation" sx={{ marginTop: 1 }} isBifold={order?.product === ProductOptions.HandwrittenBiFoldCard} />
        </Box>
      </MDCard>

      <OrderDetailsModal show={showModal === 'details'} setShow={() => setShowModal('')} order={order} />

      <InfoModal
        showCancelButton
        showConfirmButton
        show={showModal === 'delete'}
        setShow={() => setShowModal('')}
        headerText={"Are you sure you want to delete this order?"}
        cancelButtonOnClick={() => setShowModal('')}
        confirmButtonOnClick={() => removeOrder()}
      />
    </Grid>
  )
}

export default OrderCard