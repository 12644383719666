import { ICustomerSubscription } from './customerSubscription';
import { IProfileContact } from './profileContact';
import { CTMPhoneNumber } from './CTMPhoneNumber';

export enum NotificationTypeEnum {
  StripeSubscriptionPastDueFailed = 'StripeSubscriptionPastDueFailed',
  StripeSubscriptionInvoicePaymentFailed = 'StripeSubscriptionInvoicePaymentFailed',
}

export interface IProfileNotification {
  notificationType: NotificationTypeEnum;
  isViewed: boolean;
  message: string;
  title: string;
}

export interface IProfile {
  aadId: string;
  balance: number;
  annualMailVolumeId: string;
  balanceExpiration: string;
  companyName: string;
  customerSubscription: ICustomerSubscription;
  profileContact: IProfileContact;
  email: string;
  firstName: string;
  id: number;
  industry: string;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  lastName: string;
  nonprofitTaxId: string;
  payeeEmail: string;
  payeeFirstName: string;
  payeeLastName: string;
  profileStatus: string;
  referralCode: string;
  stripeCustomerId: string;
  numbers: CTMPhoneNumber[];
  createdDate?: Date;
  defaultPaymentMethodId: string;
  isActingAsUser: boolean;
  canActAsUser: boolean;
  actingAsUserId: number | null;
  loggedInUser: IProfile;
  availableProfiles: Array<IProfile>;
  allProfiles: Array<IProfile>;
  hasSubscription: boolean;
  resendAfterWeeks: number | null;
  notifications: IProfileNotification[];
  systemFeatures: ISystemFeatureVm[];
  annualMailVolumes: IAnnualMailVolumeVm[];
}

export interface ISystemFeatureVm {
  id: number;
  key: string;
  name: string;
  description: string;
}

export interface IAnnualMailVolumeVm {
  id: number;
  name: string;
  minimumValue: number;
  maximumValue: number;
}