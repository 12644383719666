import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { saveAs } from "file-saver";

import MDBox from 'material-ui/components/MDBox';
import MDCard from 'material-ui/components/MDCard';
import MDTypography from 'material-ui/components/MDTypography';
import React, { useState } from 'react';
import EnhancedTable from './table';
import MDButton from 'material-ui/components/MDButton';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

interface IRow {
    date: string,
    orderNumber: string,
    productType: string,
    orderStatus: string,
    orderQuantity: string,
    lineItemTotal: string,
    doubleSidedPrinting: string,
    extraText: string,
    postageUpgrade: string,
    totalCostPerPiece: string,
    discount: string,
    amount: string,
}

const OrderSummary: React.FC = () => {
    const [ dataToExport, setDataToExport] = useState([]);
    

    const exportToCSV = (jsonData: IRow[], fileName: string = "TransactionSummary") => {
        if (!jsonData || jsonData.length === 0) {
          return;
        }
      
        const columnMapping: { [key: string]: string } = {
          date: "Order Date",
          orderNumber: "Order Number",
          productType: "Product Type",
          orderStatus: "Order Status",
          orderQuantity: "Order Quantity",
          lineItemTotal: "Total Line Item",
          doubleSidedPrinting: "Double-Sided Printing",
          extraText: "Extra Text",
          postageUpgrade: "Postage Upgrade",
          totalCostPerPiece: "Total Cost Per Piece",
          discount: "Discount Applied",
          amount: "Total Amount",
        };
      
        const csvContent = [
          Object.keys(jsonData[0]).map((key) => `"${columnMapping[key] || key}"`).join(","), // Cabeçalhos formatados
          ...jsonData.map((row) =>
            Object.values(row)
              .map((value) => `"${String(value).replace(/"/g, '""')}"`) // Formata valores corretamente
              .join(",")
          ),
        ].join("\n");
      
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        saveAs(blob, `${fileName}.csv`);
    };
      
    
    return (
        <MDBox sx={{ maxWidth: '1376px', margin: '0 auto' }}>
            <MDCard color={"white"} boxShadow={false} border={false} sx={{ position: 'relative', margin: '.25rem'}}>
                <MDBox px={4}>
                    <MDBox>
                        <Grid container sx={{ height: '65px' }} alignItems={'center'} justifyContent={'space-between'}>
                            <MDTypography sx={{ fontSize: 18 }} fontWeight={'bold'} data-testid="automation-title">
                                Order Summary
                            </MDTypography>
                            <MDButton 
                                disabled={dataToExport?.length === 0}
                                sx={{ cursor: 'pointer !important' }} 
                                variant="transparent" 
                                color="secondary"
                                startIcon={<FileDownloadOutlinedIcon />}
                                onClick={() => {
                                const now = new Date();
                                const timestamp = now.toISOString().slice(0, 10);
                                exportToCSV(dataToExport, `OrdersSummary${timestamp}`);
                                }}
                            >
                                Download Orders
                            </MDButton>
                        </Grid>
                        <Box sx={{ border: '2px solid #eef2ff', position: 'absolute', left: 0, top: '65px', width: '100%'}} />
                    </MDBox>
                    <Box p={4}>
                        <EnhancedTable setDataToExport={setDataToExport} />
                    </Box>
                </MDBox>
            </MDCard>
        </MDBox>
    )
}

export default OrderSummary;