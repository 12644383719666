import React, { useState } from 'react';

import { Box } from '@mui/system';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Chip, CircularProgress, Typography } from '@mui/material';

import MDRadio from 'material-ui/components/MDRadio';
import MDButton from 'material-ui/components/MDButton';

interface INewCreditCard {
    errorMessage: string | null,
    setErrorMessage: (value: string | null) => void,
    loadingProfilePayments: boolean,
    selectedPaymentMethodId: string,
    submitingNewPaymentMethod: boolean,
    setSubmitingNewPaymentMethod: (value: boolean) => void,
    setSelectedPaymentMethodId: (newPaymentMethodId: string) => void,
    handleAddPaymentMethod: (value: string) => void
}

const NewCreditCard: React.FC<INewCreditCard> = ({ 
    loadingProfilePayments,
    errorMessage,
    setErrorMessage,
    selectedPaymentMethodId, 
    setSelectedPaymentMethodId,
    handleAddPaymentMethod,
    submitingNewPaymentMethod,
    setSubmitingNewPaymentMethod
}) => {

    const stripe = useStripe();
    const elements = useElements();

    const [canSubmitPayment, setCanSubmitPayment] = useState<boolean>(false);

        // 1) Create PaymentMethod for credit card
    // 2) If success, pass ID to backend, proceed
    const handleCardPaymentFlow = async () => {
        if (!stripe || !elements) {
            setErrorMessage('Stripe not loaded');
            return;
        }
        setSubmitingNewPaymentMethod(true);

        const card = elements.getElement(CardElement);
        if (!card) {
            setErrorMessage('CardElement not found');
            return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card,
        });

        if (error) {
            setErrorMessage(error.message || 'Error creating card PaymentMethod');
            return;
        }

        if (paymentMethod?.id) {
            // Send the new PM id to your backend for final attach/verify
            handleAddPaymentMethod(paymentMethod.id);
        }
    };

    return (
        <React.Fragment>
            {!loadingProfilePayments && (
                <Box
                    sx={{
                        padding: '.5rem 1rem',
                        margin: '1rem 0',
                        borderRadius: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '70px',
                        gap: '5px',
                        position: 'relative',
                        background: 'linear-gradient(0deg, #F5F5F5, #FFFFFF)',
                        boxShadow: '-1px 2px 15px -7px rgba(0, 0, 0, 0.28)',
                    }}
                >
                    <Box sx={{ display: 'flex' }}>
                        <MDRadio
                            value="new credit card"
                            checked={selectedPaymentMethodId === 'new credit card'}
                            onChange={() => setSelectedPaymentMethodId('new credit card')}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Typography variant="body2">Add new credit card</Typography>
                        </Box>
                    </Box>

                    {selectedPaymentMethodId === 'new credit card' && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '100%', paddingLeft: '10px' }}>
                            <CardElement onChange={(e) => setCanSubmitPayment(e.complete)} />
                            {errorMessage && (
                                <Chip
                                    color="error"
                                    label={
                                        <Box
                                            sx={{
                                                padding: '0 1rem',
                                                wordWrap: 'break-word',
                                                overflowWrap: 'break-word',
                                                whiteSpace: 'normal',
                                            }}
                                        >
                                            {errorMessage}
                                        </Box>
                                    }
                                    sx={{
                                        height: 'auto',
                                        borderRadius: '8px',
                                        background: '#f8d7da',
                                        color: '#721c24',
                                        wordWrap: 'break-word',
                                        overflowWrap: 'break-word',
                                        whiteSpace: 'normal',
                                        display: 'block',
                                    }}
                                />
                            )}
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '.5rem 0'}}>
                                <MDButton onClick={() => handleCardPaymentFlow()} disabled={!canSubmitPayment || submitingNewPaymentMethod}>
                                    { submitingNewPaymentMethod ? <CircularProgress color="inherit" size={18} /> : 'Save Credit Card' }
                                </MDButton>
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
        </React.Fragment>
    );
}

export default NewCreditCard;