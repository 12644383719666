// * Types

export type MailMergeMapType = Map<MailMergeFieldType, string>;

export type MailMergeFieldType = typeof allMailMergeFields[number];

export interface IMailMergeField {
  parameterType: MailMergeFieldType;
  parameterText: string;
}

// * Constants
export const namedMailMergeFields = [
  'FIRST NAME',
  'LAST NAME',
  'ORGANIZATION',
  'ADDRESS 1',
  'ADDRESS 2',
  'STREET NAME',
  'CITY',
  'STATE',
  'ZIP',
  'SALUTATION',
  'RADIUS PIN ADDRESS',
  'RADIUS PIN STREET',
  'TO ORGANIZATION',
  'DISCOUNT CODE',
  'ZIP CODE',
  'COUNTRY'
] as const;

export const customMailMergeFields = ['CUSTOM 1', 'CUSTOM 2', 'CUSTOM 3', 'CUSTOM 4', 'CUSTOM 5', 'CUSTOM 6'] as const;

export const allMailMergeFields = [...namedMailMergeFields, ...customMailMergeFields] as const;

export const mailMergeFieldsOptions: { title: string; fields: ReadonlyArray<typeof allMailMergeFields[number]> }[] = [
  { title: 'Named Fields', fields: namedMailMergeFields },
  { title: 'Custom Fields', fields: customMailMergeFields },
];

// * Functions

export function getMailMergeFields(text: string) {
  return text?.match(/{{(.*?)}}/g);
}

export function getValidMailMergeFields(text: string): MailMergeFieldType[] {
  const matches = getMailMergeFields(text) || [];
  const fields = matches?.map((match) => match.replaceAll('{', '').replaceAll('}', '')) as MailMergeFieldType[];
  const validFields = fields?.filter((field) => allMailMergeFields.includes(field));
  return [...new Set(validFields)];
}

export function shouldUpdateMailMergeFields(prevMap: MailMergeMapType, updatedMap: MailMergeMapType) {
  if (prevMap.size !== updatedMap.size) return true;
  for (const key of prevMap.keys()) {
    if (!updatedMap.has(key)) return true;
  }
  for (const key of updatedMap.keys()) {
    if (!prevMap.has(key)) return true;
  }
  return false;
}
