import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Field, FieldArray, FieldProps, Form, Formik, FormikProps } from 'formik';
import {
  Alert,
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import MDButton from 'material-ui/components/MDButton';
import StepIndicator from 'components/step-indicator';
import FormikErrorMessage from 'components/formik-error-message';
import SmartAutomationFilterGroup from './smart-automation-filter-group';
import useFormikValidation from 'hooks/useFilterValidation';
import MDCard from 'material-ui/components/MDCard';
import useSmartAutomationService from 'services/smart-automation';
import {
  ISmartAutomationPayload,
  ISmartAutomationSchema,
  ISourceViewModel,
  IUserCreatedFilter,
  PostageTypeEnum,
} from 'services/smart-automation/smart-automation.types';
import SelectTemplate from 'components/select-template';
import { smartAutomationValidation } from 'services/smart-automation/smart-automation.schema';
import { convertKeysToCamelCase } from 'helpers/pascalToCamelCase';
import RadioSelectWrapper from 'components/radio-select-wrapper';
import { orderProducUsesLetterUSPSPricing } from 'models/enums/ProductTypes';
import LeadRevealEnable from './lead-reveal-enable';
import SelectDiscountCodes from 'components/select-discount-codes';
import { IntegrationOrderStatus } from 'models/enums/IntegrationOrderStatus';
import NewShopifyFilters from './newShopifyFilter';
import { FiltersProvider, useFilters } from '../context';
import { useGlobal } from 'context/global-context';

export const emptyFilter: IUserCreatedFilter = { dataSourceFilter: undefined, operator: undefined, value: '' };

interface SmartAutomationFormContentProps extends FormikProps<ISmartAutomationSchema> {
  disabled?: boolean;
  vmData?: any;
  initialData: any;
}

const SmartAutomationFormContent = ({
  disabled,
  values,
  setValues,
  setFieldValue,
  initialData,
  vmData,
  isValid,
  isSubmitting,
  getFieldMeta,
  errors,
}: SmartAutomationFormContentProps) => {

  const SHOPIFY_AUTOMATION = '1';
  const { shopifyIntegrationOrderFilters } = useFilters();
  const { showLoader } = useGlobal();

  const navigate = useNavigate();
  const { calculatePreviewRecipientsCount, getPreviewRecipientsCountStatus } = useSmartAutomationService();

  const [segmentValue, setSegmentValue] = useState<string>('');
  const [isError, setIsError] = useState(false);
  const [previewCount, setPreviewCount] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState(false);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const source = queryParams.get('source');
  const name = queryParams.get('name');
  const isPreviewEnable = true;

  const { mutate: handleCalculate, isPending: isCalculating } = useMutation({
    mutationFn: calculatePreviewRecipientsCount,
    onSuccess: (response) => {
      if (response.hasErrors) {
        setIsError(true);
        setErrorMessage(response.errors[0])
        setPreviewCount(0);
        setIsProcessing(false);
      } else {
        checkStatus();
      }
    },
    onError: () => {
      setIsError(true);
    }
  });

  const checkStatus = () => {
    const { template, ...payload } = values;

    const interval = setInterval(async () => {
      try {
        const status = await getPreviewRecipientsCountStatus(Number(payload.profileDataProviderId));

        if (!status.payload.isRunningFilters) {
          setIsError(false);
          setErrorMessage('');
          setPreviewCount(status.payload.recipientsCount);
          clearInterval(interval);
          setIsProcessing(false);
        }

        if(status && status.hasErrors) {
          setIsError(true);
          setErrorMessage(status?.errors ? status.errors[0] : '');
          clearInterval(interval);
          setIsProcessing(false);
        }

      } catch (errors) {
        setIsError(true);
        setErrorMessage('Something went wrong, try again later!');
        clearInterval(interval);
        setIsProcessing(false);
      }
    }, 10000);
  };

  const sourceVm: ISourceViewModel = vmData?.payload ? convertKeysToCamelCase(JSON.parse(vmData.payload)) : {};
  const schema = smartAutomationValidation(!!sourceVm.isFilterRequired, Number(source));
  const [isFilterValid] = useFormikValidation<ISmartAutomationSchema, typeof schema>(schema, 'userCreatedFilters');

  const handleSubmitCalculate = () => {
    const { template, ...payload } = values;
    const smartAutomationPayload: ISmartAutomationPayload = {
      dataSourceId: Number(source),
      templateId: template?.id,
      payload: JSON.stringify({
        ...payload,
        userCreatedFilters: shopifyIntegrationOrderFilters
      }),
      name: name ?? '',
    };

    setIsProcessing(true);
    handleCalculate({ ...smartAutomationPayload });
  };

  return (
      <Form>
        <Grid container gap={3} py={1}>
          <StepIndicator stepNumber={1} checked={!!values.template} />
          <Box>
            <Divider light orientation="vertical" />
          </Box>
          <Grid item flex={1}>
            <Typography variant="h4" pb={2}>
              Template
            </Typography>
            <Field name="template">
              {({ field, meta }: FieldProps) => (
                <SelectTemplate
                  meta={meta}
                  order={values}
                  shouldCheckNotSupported
                  onDelete={() => setFieldValue('template', null)}
                  onTemplateChange={(template) => setFieldValue('template', template)}
                  template={field.value}
                  disabled={disabled}
                />
              )}
            </Field>
            {values.template?.hasDiscount && (
              <Box mt={1}>
                <SelectDiscountCodes
                  disabled={disabled}
                  error={!!errors.multiUseCouponId}
                  selectedDiscount={values}
                  setSelectedDiscount={(discount) => setValues({ ...values, ...discount })}
                />
              </Box>
            )}
          </Grid>
        </Grid>

        <Divider light />

        <Grid container gap={3}>
          <StepIndicator stepNumber={2} checked={isFilterValid && !!values.segmentId} />
          <Box>
            <Divider light orientation="vertical" />
          </Box>
          <Grid item container flexDirection={'column'} flex={1} gap={2}>
            {sourceVm?.segmentDropdownValues && (
              <Grid item maxWidth={350} minWidth={200} flex={1}>
                <Field name="segmentId">
                  {({ field, meta }: FieldProps) => (
                    <FormControl fullWidth>
                      <Typography component="label" htmlFor="sa-segment" variant="h4" pb={1}>
                        Segment
                      </Typography>
                      <Autocomplete
                        {...field}
                        disablePortal
                        id="sa-segment"
                        sx={{ width: 300 }}
                        value={field.value || ''}
                        inputValue={segmentValue}
                        onInputChange={(_, newValue) => setSegmentValue(newValue)}
                        options={[...(sourceVm.segmentDropdownValues?.map((el) => el.item1) ?? [])]}
                        renderInput={(params) => <TextField {...params} />}
                        getOptionLabel={(option) =>
                          sourceVm.segmentDropdownValues?.find((el) => el.item1 === option)?.item2 ?? ''
                        }
                        onChange={(_, newValue) => setFieldValue('segmentId', newValue)}
                        isOptionEqualToValue={(option, value) => option === value}
                      />
                      <FormikErrorMessage meta={meta} />
                    </FormControl>
                  )}
                </Field>
              </Grid>
            )}

            {sourceVm?.storeDropdownValues && (
              <Grid item maxWidth={350} minWidth={200} flex={1}>
                <Field name="profileDataProviderId">
                  {({ field, meta }: FieldProps) => (
                    <FormControl fullWidth>
                      <Typography component="label" htmlFor="sa-store" variant="h4" pb={1}>
                        Store
                      </Typography>
                      <Select
                        {...field}
                        fullWidth
                        id="sa-store"
                        value={field.value || ''}
                        error={!!meta.touched && !!meta.error}
                        disabled={disabled}
                      >
                        <MenuItem value="" disabled>
                          Select an option
                        </MenuItem>
                        {sourceVm.storeDropdownValues?.map(({ item1, item2 }) => (
                          <MenuItem key={`store${item1}`} value={item1}>
                            <Box display="flex" gap={1}>
                              {item2}
                            </Box>
                          </MenuItem>
                        ))}
                      </Select>
                      <FormikErrorMessage meta={meta} />
                    </FormControl>
                  )}
                </Field>
              </Grid>
            )}
            <Grid item flex={1} gap={1} display={'flex'} flexDirection={'column'}>
              <Typography variant="h4" pr={4}>
                { source === SHOPIFY_AUTOMATION ? 'Find me customers where...' : 'Profile Filters'} {(source !== SHOPIFY_AUTOMATION && !sourceVm.isFilterRequired) && '(Optional)'}
              </Typography>

              {
                source !== SHOPIFY_AUTOMATION ? (
                  <FieldArray
                    name="userCreatedFilters"
                    render={(arrayHelpers) =>
                      values.userCreatedFilters?.map((orGroup, orIndex) => (
                        <SmartAutomationFilterGroup
                          key={`orGroup${orIndex}`}
                          disabled={disabled}
                          orGroup={orGroup}
                          orIndex={orIndex}
                          {...arrayHelpers}
                        />
                      ))
                    }
                  />
                ) : (
                  <NewShopifyFilters initialData={initialData} canEdit={!disabled ? true : false}/>
                )
              }

              {source !== SHOPIFY_AUTOMATION && !values.userCreatedFilters?.length && (
                <Box>
                  <MDButton
                    disabled={disabled}
                    sx={{ width: 200 }}
                    onClick={() => setFieldValue('userCreatedFilters', [{ integrationOrderFilters: [emptyFilter] }])}
                  >
                    Add Filter
                  </MDButton>
                  {sourceVm.isFilterRequired && getFieldMeta('userCreatedFilters').touched && (
                    <Typography sx={{ fontSize: '12px !important' }} color="error">
                      Required
                    </Typography>
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Divider light />

        <Grid container gap={3}>
          <StepIndicator stepNumber={3} checked={values.template !== null} />
          <Box>
            <Divider light orientation="vertical" />
          </Box>
          <Grid item flex={1}>
            <Typography variant="h4" pb={2}>
              Postage Type
            </Typography>
            {
              values.template ? (
                  <Field name="postageType">
                    {({ field }: FieldProps) => (
                      <FormControl disabled={disabled}>
                            <RadioGroup value={field.value} onChange={(e) => setFieldValue('postageType', Number(e.target.value))}>
                              <RadioSelectWrapper selected={field.value === PostageTypeEnum.FirstClass}>
                                <FormControlLabel
                                  control={<Radio />}
                                  value={PostageTypeEnum.FirstClass}
                                  label={
                                    <Box display={'flex'} alignItems={'center'} gap={1}>
                                      <Typography variant="h5">First Class Postage</Typography>
                                      <Typography variant="h5" fontWeight={'normal'}>
                                        (3-5 day USPS estimated delivery)
                                      </Typography>
                                      <Chip
                                        label={
                                          values.template?.postageTypePrices?.FirstClass
                                            ? `+$${values?.template?.postageTypePrices?.FirstClass?.toFixed(2)}`
                                            : orderProducUsesLetterUSPSPricing(values?.template?.product)
                                              ? '+$0.15'
                                              : '+$0.05'
                                        }
                                      />
                                    </Box>
                                  }
                                />
                              </RadioSelectWrapper>
                              <Box mt={1} />
                              <RadioSelectWrapper selected={field.value === PostageTypeEnum.Standard}>
                                <FormControlLabel
                                  control={<Radio />}
                                  value={PostageTypeEnum.Standard}
                                  label={
                                    <Box display={'flex'} alignItems={'center'} gap={1}>
                                      <Typography variant="h5">Standard Postage</Typography>
                                      <Typography variant="h5" fontWeight={'normal'}>
                                        (10-14 day USPS estimated delivery)
                                      </Typography>
                                    </Box>
                                  }
                                />
                              </RadioSelectWrapper>
                            </RadioGroup>
                      </FormControl>
                    )}
                  </Field>
                ) : (
                  <small style={{ opacity: .8}}>Select a template to see Postage Type available options</small>
                )
              }
          </Grid>
        </Grid>
        {Number(source) !== 3 && (
          <>
            <Divider light />

            <Grid container gap={3}>
            <StepIndicator
                stepNumber={4}
                checked={
                  source === '1'
                    ? values?.sendingOptions?.maxNumberCardsSentPerMonth !== null &&
                      values?.sendingOptions?.maxNumberCardsSentPerMonth !== 0
                      ? true
                      : false
                    : true
                }
              />              
              <Box>
                <Divider light orientation="vertical" />
              </Box>
              <Grid item flex={1}>
                <Typography variant="h4" pb={1}>
                  Sending Options
                </Typography>
                <Box display={'flex'} justifyContent={'space-between'} mt={1}>
                  <Typography fontSize={14} fontWeight={'bold'}>
                    Monthly Sending Limits
                  </Typography>
                </Box>
                <Field name="sendingOptions.maxNumberCardsSentPerMonth">
                  {({ field }: FieldProps) => (
                    <Box ml={2.5} mr={3} display={'flex'} flexDirection={'column'}>
                      <TextField
                        type="number"
                        required={false}
                        error={field?.value === 0}
                        inputProps={{
                          step: 100,
                          min: 0
                        }}
                        disabled={disabled}
                        value={field.value ?? ''}
                        onChange={(event) => {
                          let newValue = event.target.value.replaceAll(/,/g, '');
                          if (newValue === '') {
                            setFieldValue('sendingOptions.maxNumberCardsSentPerMonth', null);
                          } else {
                            setFieldValue('sendingOptions.maxNumberCardsSentPerMonth', Number(newValue));
                          }
                        }}
                        variant="outlined"
                        sx={{ margin: '.5rem 0 0 -1.3rem', width: '180px' }}
                      />
                      {
                        field?.value === 0 && (
                          <small style={{ color: 'red', fontSize: '12px', marginLeft: '-20px'}}>Monthly Sending Limits cannot be zero.</small>
                        )
                      }
                    </Box>
                  )}
                </Field>

              </Grid>
            </Grid>
          </>
        )}
        {Number(source) !== 4 && isPreviewEnable && source === '1' && (
          <>
            <Divider light />

            <Grid container gap={3}>
              <StepIndicator stepNumber={5} checked={source === '1' && previewCount !== null ? true : (source === '1' && previewCount === null) ? false : true} />
              <Box>
                <Divider light orientation="vertical" />
              </Box>
              <Grid item flex={1}>
                <Typography variant="h4" pb={1}>
                  Preview Matches Count
                </Typography>
                {
                  previewCount !== null && (
                    <Typography fontSize={14} fontWeight="bold">
                      Approximate number of recipients found:
                      {(isCalculating || isProcessing) ? (
                        <CircularProgress size={16} sx={{ ml: 1 }} />
                      ) : isError ? (
                        ' Error fetching count.'
                      ) : (
                        ` ${(isCalculating || isProcessing) ? '...'  : previewCount}`
                      )}
                    </Typography>
                  )
                }
                <Box display={'flex'} justifyContent={'space-between'} mt={1}>

                  <MDButton
                    color="primary"
                    onClick={async () => {
                      handleSubmitCalculate();
                    }}
                    disabled={(isCalculating || isProcessing) || !values.profileDataProviderId}
                  >
                    {(isCalculating || isProcessing) ? <CircularProgress color="inherit" size={20} /> : 'Preview'}
                  </MDButton>
                </Box>
                
                {isError && (
                  <Alert sx={{margin:'1rem 0'}} severity="error">{errorMessage}</Alert>
                )}
              </Grid>
            </Grid>
          </>
        )}
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} marginTop={2} justifyContent="flex-end">
          <MDButton disabled={isSubmitting || disabled} color="light" onClick={() => navigate('/automations')}>
            Cancel
          </MDButton>
          <MDButton
            disabled={isSubmitting || disabled || showLoader}
            type="submit"
            color="primary"
            onClick={() => !isValid && window.scrollTo({ top: 0, behavior: 'smooth' })}
          >
            {(isSubmitting || showLoader) ? <CircularProgress color="inherit" size={20} /> : 'Save'}
          </MDButton>
        </Stack>
      </Form>
  );
};

interface SmartAutomationFormProps {
  disabled?: boolean;
  status?: IntegrationOrderStatus;
  onSubmit: (values: ISmartAutomationSchema) => void;
  initialValues: ISmartAutomationSchema;
}

const SmartAutomationForm = ({
  disabled,
  initialValues,
  onSubmit,
  status = IntegrationOrderStatus.Testing,
}: SmartAutomationFormProps) => {

  const SHOPIFY_AUTOMATION = '1';

  const { id: automationId } = useParams();
  const isEdit = automationId !== 'new';

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const source = queryParams.get('source');

  const [currentSource, setCurrentSource] = useState<number>();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [leadRevealModalProps, setLeadRevealModalProps] = useState<{
    open: boolean;
    payload?: ISmartAutomationSchema;
  }>({ open: false });

  const { getViewModelByDataSource } = useSmartAutomationService();

  const {
    data: vmData,
    refetch: refetchVM,
  } = useQuery({
    queryKey: ['getViewModelByDataSource', Number(source)],
    queryFn: () => getViewModelByDataSource(Number(source)),
    refetchOnWindowFocus: false,
  });

  const sourceVm: ISourceViewModel =
    typeof vmData?.payload === 'string' ? convertKeysToCamelCase(JSON.parse(vmData.payload)) : {};

  useEffect(() => {
    const newSource = Number(source);
    if (currentSource !== newSource) {
      setCurrentSource(newSource);
      refetchVM();
    }
  }, [currentSource, source, refetchVM]);

  const handleSubmit = async (
    values: ISmartAutomationSchema,
    formikHelpers: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    const { setSubmitting } = formikHelpers;
    try {
      setSubmitting(true);
      
      const filters = sessionStorage.getItem('tmpIntegrationOrderFilters');
      if (
        source === SHOPIFY_AUTOMATION &&
        ((filters && !isFilterFormValid(JSON.parse(filters))) || values.sendingOptions?.maxNumberCardsSentPerMonth === 0)
      ){
        return
      } else {
        
        const shouldShowLRModal = !isEdit && sourceVm?.requiresConfirmation && !leadRevealModalProps.open;
        setLeadRevealModalProps({ open: shouldShowLRModal ?? false, payload: shouldShowLRModal ? values : undefined });
        const shouldShowConfirmationModal = currentSource === 2 && status !== IntegrationOrderStatus.Active && !showConfirmationModal;
        setShowConfirmationModal(shouldShowConfirmationModal);
        if (!shouldShowLRModal && !shouldShowConfirmationModal) await onSubmit(values);
      }
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };
  
  function isFilterFormValid(filters: any) {
    function validateFilter(filter: any): boolean {
        const ALL_TIME_OPTION = 12;
        const IS_NOT_BLANK_OPTION = 8;

        if (!filter.dataSourceFilter || typeof filter.dataSourceFilter.id !== 'number') {
            return false;
        }

        if (!filter.operator || typeof filter.operator.id !== 'number') {
            return false;
        }

        const operatorId = filter.operator.id;
        if ((operatorId !== IS_NOT_BLANK_OPTION && operatorId !== ALL_TIME_OPTION) && (!filter.value || typeof filter.value !== 'string')) {
            return false;
        }

        if ((operatorId === IS_NOT_BLANK_OPTION || operatorId === ALL_TIME_OPTION) && filter.value) {
            return false;
        }

        if (filter.dataSourceFilter.aggregateFilters) {
            return filter.dataSourceFilter.aggregateFilters.every((aggregate: any) => 
                aggregate.integrationOrderFilters.every(validateFilter)
            );
        }

        return true;
    }

    return filters.every((item: { integrationOrderFilters: any[] }) => {
        return item.integrationOrderFilters.every(validateFilter);
    });
  }

  const handleCancelSubmit = (setSubmitting: (isSubmitting: boolean) => void) => {
    setShowConfirmationModal(false);
    setLeadRevealModalProps({ open: false });
    setSubmitting(false);
  };

  const customValidation = (values: ISmartAutomationSchema) => {
    if (values.template && values.template.hasDiscount && !values.multiUseCouponId && !values.couponListId) {
      return { multiUseCouponId: 'Required' };
    }
    return {};
  };

  if (vmData?.hasErrors) return <Alert severity="error">{vmData.errors?.[0]}</Alert>;
  
  return (
    <MDCard>
      <Box width={'100%'} maxWidth={1300} mx={'auto'} p={4}>
        <Formik
          initialValues={
            initialValues || {
              segmentId: '',
              profileDataProviderId: '',
              template: null,
              userCreatedFilters: [],
              postageType: PostageTypeEnum.Standard,
              sendingOptions: { maxNumberCardsSentPerMonth: null },
            }
          }
          validationSchema={smartAutomationValidation(!!sourceVm.isFilterRequired, currentSource)}
          onSubmit={handleSubmit}
          validate={customValidation}
          enableReinitialize
        >
          {(formikProps) => (
            <>

              <FiltersProvider vmData={sourceVm}>
                <SmartAutomationFormContent 
                  vmData={vmData} 
                  initialData={initialValues} 
                  disabled={disabled} 
                  {...formikProps} />
              </FiltersProvider>
              <LeadRevealEnable
                {...leadRevealModalProps}
                onSubmit={() => handleSubmit(formikProps.values, formikProps)}
                onCancel={() => handleCancelSubmit(formikProps.setSubmitting)}
              />
              <Dialog
                open={showConfirmationModal}
                onClose={() => handleCancelSubmit(formikProps.setSubmitting)}
                fullWidth
                maxWidth={'sm'}
              >
                <DialogContent>
                  <Stack spacing={2}>
                    <Typography variant="h4" fontSize={24}>
                      Test your Smart Automation
                    </Typography>
                    <Box component={'ul'} pl={3}>
                      <Typography component={'li'} fontSize={16}>
                        To verify that your Smart Automation is working, we're pulling the 20 most recent recipients
                        from your segment.
                      </Typography>
                      <Typography component={'li'} fontSize={16}>
                        Once testing is complete, please change the status to "Active" to start mailing.
                      </Typography>
                    </Box>
                    <Box display={'flex'} justifyContent={'space-between'} gap={2}>
                      <MDButton fullWidth onClick={() => handleSubmit(formikProps.values, formikProps)}>
                        Start Testing
                      </MDButton>
                      <MDButton color="light" onClick={() => handleCancelSubmit(formikProps.setSubmitting)}>
                        Cancel
                      </MDButton>
                    </Box>
                  </Stack>
                </DialogContent>
              </Dialog>
            </>
          )}
        </Formik>
      </Box>
    </MDCard>
  );
};

export default SmartAutomationForm;

