//@ts-nocheck
import colors from "material-ui/theme/base/colors";
import { Circle, GoogleMap, OverlayView, Polygon as MapsPolygon, LoadScript } from "@react-google-maps/api";
import { mapContainerStyle, mapOptions, getCoordinatesAddress, mapInfoTextContainer, getCenterOfPolygon } from "helpers/google-map-helper";
import MDBox from "material-ui/components/MDBox";
import MDCard from "material-ui/components/MDCard";
import MDTypography from "material-ui/components/MDTypography";
import { Coordinate } from "models/coordinate";
import { ServiceArea, ServiceAreaPolygon, ServiceAreaRadius } from "models/hail-trace/ServiceArea";
import { useRef, useState } from "react";
import { Box } from "@mui/system";
import { Divider, Grid, Tooltip, Typography } from "@mui/material";
import MDButton from "material-ui/components/MDButton";

import PolygonIcon from "assets/icons/polygon";
import RadiusIcon from "assets/icons/radius";
import MDIconButton from "material-ui/components/MDIconButton";
import DeleteIcon from "assets/icons/delete";
import MDInput from "material-ui/components/MDInput";

interface IStep2 {
  serviceArea: ServiceArea;
  setServiceArea: (value: ServiceArea) => void
}

const Step2: React.FC<IStep2> = ({ serviceArea, setServiceArea }) => {

    const polygonsRef = useRef([])
  
    const [map, setMap] = useState<google.maps.Map>()
    const [isAddingAddress, setIsAddingAddress] = useState<boolean>(false)
    const [isAddingPolygon, setIsAddingPolygon] = useState<boolean>(false)
    const [center, setCenter] = useState<Coordinate>({ lat: 34.0200392, lng: -118.7413915 })
    const [geocoder, setGeocoder] = useState<google.maps.Geocoder | null>(null);

   
    function onMapLoaded() {
        setGeocoder(new window.google.maps.Geocoder())

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function (pos: GeolocationPosition) {
                    let lat = pos.coords.latitude
                    let lng = pos.coords.longitude

                    setCenter({ lat, lng })
                },
                function (error) {
                    setCenter({ lat: 33.824386, lng: -118.1358666 })
                })
        } else {
            setCenter({ lat: 33.824386, lng: -118.1358666 })
        }
    }

    function onPolygonUnmount() {
        polygonsRef.current = []
    }

    function onPolygonLoad(polygon: google.maps.Polygon, id: number) {
        polygonsRef.current.push({ id: id, polygon: polygon })
    }

    function onPolygonEdit(id: number) {
        let polygonToEdit = (polygonsRef.current.find((e: any) => e.id === id)).polygon

        let updatedPath = polygonToEdit.getPath().getArray().map((coordinate: google.maps.LatLng) => {
            return { lat: coordinate.lat(), lng: coordinate.lng() }
        })

        let centerOfPolygon = getCenterOfPolygon(updatedPath)

        getCoordinatesAddress(geocoder, centerOfPolygon.lat, centerOfPolygon.lng, (addressData: any) => {
            setServiceArea((prevState: ServiceArea) => {
                let copy = { ...prevState }

                for (let el of copy.polygons) {
                    if (el.id === id) {
                        el.coordinates = updatedPath
                        el.name = addressData.shortAddress
                        break
                    }
                }

                return copy
            })
        })
    }

    function addAddress(lat: number, lng: number, addressData: any) {
        let sortedAddresses = serviceArea.radii.sort((a, b) => {
            if (a.id > b.id) {
                return 1
            } else if (a.id < b.id) {
                return -1
            }
            return 0
        })

        let lastId = sortedAddresses.length ? sortedAddresses[sortedAddresses.length - 1].id : 0

        setServiceArea(prevState => {
            let newAddress: ServiceAreaRadius = {
                id: lastId + 1,
                coordinate: {
                    lat: lat,
                    lng: lng,
                },
                radius: 2,
                name: addressData.shortAddress,
                isNew: true,
                structures: null,
                isProcessed: true,
                recipientSearchEntryId: null
            }

            return { ...prevState, radii: [...prevState.radii, newAddress] }
        })
    }

    function addPolygon(lat: number, lng: number, addressData: any) {
        setServiceArea(prevState => {
            let sortedPolygons = serviceArea.polygons.sort((a, b) => {
                if (a.id > b.id) {
                    return 1
                } else if (a.id < b.id) {
                    return -1
                }
                return 0
            })

            let lastId = sortedPolygons.length ? sortedPolygons[sortedPolygons.length - 1].id : 0

            let offset = 0.02898550724 //Approximate offset for the 4 points around the center (2 miles)

            let newPolygon: ServiceAreaPolygon = {
                id: lastId + 1,
                name: addressData.shortAddress,
                coordinates: [
                    { lat: lat, lng: lng - offset },
                    { lat: lat - offset, lng: lng },
                    { lat: lat, lng: lng + offset },
                    { lat: lat + offset, lng: lng },
                ],
                isNew: true,
                structures: null,
                isProcessed: true,
                recipientSearchEntryId: null
            }

            return { ...prevState, polygons: [...prevState.polygons, newPolygon] }
        })
    }

    function deleteAddress(id: number) {
        setServiceArea(prevState => {
            let copy = { ...prevState }
            copy.radii = copy.radii.filter(a => a.id !== id)
            return copy
        })
    }
    function deletePolygon(id: number) {
        setServiceArea(prevState => {
            let copy = { ...prevState }
            copy.polygons = copy.polygons.filter(a => a.id !== id)
            return copy
        })
    }

    return (

      <MDCard sx={{ width: '100%'}}>
        <Box sx={{ position: 'relative', height: '50px', display: 'flex', alignItems: 'center', padding: '0 2rem' }}>
          <Typography fontSize={14} fontWeight={'bold'} variant='caption'>Define your service area</Typography>
          <Box sx={{ border: '1.5px solid #eef2ff', position: 'absolute', left: 0, top: '50px', width: '100%'}} />
        </Box>
        <Box sx={{ padding: '2rem', display: 'flex', flexDirection: 'column', width: '100%',    position: 'relative' }}>
          <Grid container gap={1.3} sx={{ position: "absolute", right: "50px", top: "50px", zIndex: 2 }} width={"fit-content"}>
            <Grid item>
                <MDButton
                    sx={{ 
                      background: 'white',
                      color: '#5551fe',
                      borderRadius: '3px',
                      '&:hover': {
                        backgroundColor: '#5551fe',
                        color: 'white'
                      },
                    }}
                    color="primary"
                    onClick={() => {
                        setIsAddingPolygon(false)
                        setIsAddingAddress(true)
                    }}
                >
                    <Grid container alignItems={"center"} justifyContent={"center"} gap={1}>
                        <Grid item display={"flex"}>
                            <RadiusIcon />
                        </Grid>
                        <Grid item>
                            Add Radius
                        </Grid>
                    </Grid>
                </MDButton>
            </Grid>

            <Grid item>
                <MDButton
                    color="primary"
                    onClick={() => {
                        setIsAddingAddress(false)
                        setIsAddingPolygon(true)
                    }}
                    sx={{ 
                      background: 'white',
                      color: '#5551fe',
                      borderRadius: '3px',
                      '&:hover': {
                        backgroundColor: '#5551fe',
                        color: 'white'
                      },
                    }}
                >
                    <Grid container alignItems={"center"} justifyContent={"center"} gap={1}>
                        <Grid item display={"flex"}>
                            <PolygonIcon />
                        </Grid>
                        <Grid item>
                            Add Polygon
                        </Grid>
                    </Grid>
                </MDButton>
            </Grid>
          </Grid>
          <Box sx={{ paddingX: '.5rem', width: '100%',height: '350px'}}>
            <LoadScript googleMapsApiKey="AIzaSyAnZBcPxYjFTJ2p5VykxLnOcqM0gY4uIY0" onLoad={onMapLoaded}>
                <GoogleMap
                    zoom={10}
                    center={center}
                    mapContainerStyle={mapContainerStyle}
                    options={mapOptions}
                    onLoad={(map) => {
                        setMap(map)
                    }}
                    onClick={(e: google.maps.MapMouseEvent) => {
                    if (!e.latLng || !geocoder) return; // Garante que latLng e geocoder estejam definidos corretamente
                
                    const lat = e.latLng?.lat();
                    const lng = e.latLng?.lng();
                
                    if (typeof lat !== "number" || typeof lng !== "number") {
                        console.error("Lat or Lng is not a valid number", { lat, lng });
                        return; // Se não forem números válidos, evita chamar a API
                    }
                
                    if (isAddingAddress) {
                        getCoordinatesAddress(geocoder, lat, lng, (addressData: any) => {
                            if (!addressData) {
                                console.error("Failed to fetch address data");
                                return;
                            }
                            addAddress(lat, lng, addressData);
                            setIsAddingAddress(false);
                        });
                    }
                
                    if (isAddingPolygon) {
                        getCoordinatesAddress(geocoder, lat, lng, (addressData: any) => {
                            if (!addressData) {
                                console.error("Failed to fetch address data");
                                return;
                            }
                            addPolygon(lat, lng, addressData);
                            setIsAddingPolygon(false);
                        });
                    }
                }}
                
                
                >
                    <MDBox sx={{
                        ...mapInfoTextContainer,
                        backgroundColor: 'rgba(85, 81, 254, .8)',
                        transform: isAddingAddress ? "translateY(0px)" : "translateY(50px)"
                    }}>
                        <MDTypography sx={{ fontSize: 17 }} fontWeight={"normal"} color={"white"}>
                            Click anywhere on the map to add an address
                        </MDTypography>
                    </MDBox>

                    <MDBox sx={{
                        ...mapInfoTextContainer,
                        backgroundColor: 'rgba(85, 81, 254, .8)',
                        transform: isAddingPolygon ? "translateY(0px)" : "translateY(50px)"
                    }}>
                        <MDTypography sx={{ fontSize: 17 }} fontWeight={"normal"} color={"white"}>
                            Click anywhere on the map to add a polygon
                        </MDTypography>
                    </MDBox>

                    {serviceArea.radii.map((radius, index) => {
                        return <MDBox key={index}>
                            <OverlayView
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                getPixelPositionOffset={(width, height) => ({
                                    x: -(width / 2),
                                    y: -(height / 2),
                                })}
                                position={{
                                    lat: radius.coordinate.lat,
                                    lng: radius.coordinate.lng
                                }}
                            >
                                <MDTypography whiteSpace={"nowrap"} fontWeight={"bold"} textAlign={"center"}
                                    color={"white"}
                                    sx={{ textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black" }}>
                                    {radius.name}
                                </MDTypography>
                            </OverlayView>

                            <Circle
                                center={{
                                    lat: radius.coordinate.lat,
                                    lng: radius.coordinate.lng
                                }}
                                radius={radius.radius * 1609.34}
                                options={{
                                    fillColor: colors.primary.main,
                                    fillOpacity: 0.30,
                                    strokeColor: colors.primary.main,
                                    strokeOpacity: 0.8,
                                    editable: true,
                                    draggable: true
                                }}
                                onDragEnd={(e) => {
                                    let lat = e.latLng.lat()
                                    let lng = e.latLng.lng()

                                    getCoordinatesAddress(geocoder, lat, lng, function (addressData: any) {
                                        setServiceArea((prevState: ServiceArea) => {
                                            let copy = { ...prevState }

                                            for (let e of copy.radii) {
                                                if (e.id === radius.id) {
                                                    e.coordinate.lat = lat
                                                    e.coordinate.lng = lng
                                                    e.name = addressData.shortAddress
                                                    break
                                                }
                                            }

                                            return copy
                                        })
                                    })

                                }}
                                draggable={true}
                            />
                        </MDBox>
                    })}

                    {serviceArea.polygons.map((polygon, index) => {
                        return <MDBox key={index}>
                            <OverlayView
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                getPixelPositionOffset={(width, height) => ({
                                    x: -(width / 2),
                                    y: -(height / 2),
                                })}
                                position={getCenterOfPolygon(polygon.coordinates)}
                            >
                                <MDTypography whiteSpace={"nowrap"} fontWeight={"bold"} textAlign={"center"}
                                    color={"white"}
                                    sx={{ textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black" }}>{polygon.name}</MDTypography>
                            </OverlayView>

                            <MapsPolygon
                                key={polygon.id}
                                draggable={true}
                                editable={true}
                                path={polygon.coordinates}
                                options={{
                                    fillColor: colors.primary.main,
                                    fillOpacity: 0.40,
                                    strokeColor: colors.primary.main,
                                    strokeOpacity: 1,
                                    strokeWeight: 3
                                }}
                                onLoad={(p) => {
                                    onPolygonLoad(p, polygon.id)
                                }}
                                onUnmount={() => onPolygonUnmount()}
                                onMouseUp={() => onPolygonEdit(polygon.id)}
                                onDragEnd={() => onPolygonEdit(polygon.id)}
                            />
                        </MDBox>
                    })}
                </GoogleMap>
            </LoadScript>
          </Box>
          {serviceArea.radii.length || serviceArea.polygons.length ?
                <Box sx={{
                    margin: '1rem 0 0 0',
                    paddingX: '.5rem'
                }}>
                    <MDBox mt={2}>
                        <MDCard border={true} borderColor={"dark"} color={"light"}>
                            <MDBox p={'.75rem 2rem'}>
                                {serviceArea.radii.map((radius, index) => {
                                    return <MDBox key={index}>
                                        <Grid container alignItems={"flex-end"} gap={2}>
                                            <Grid item flex={1}>
                                                <MDTypography sx={{ fontSize: 14 }} fontWeight={"bold"}>Address</MDTypography>
                                                <MDBox mt={1}>
                                                    <MDInput
                                                        backgroundColor={"white"}
                                                        fullWidth={true}
                                                        value={radius.name}
                                                        onChange={(e: any) => {
                                                            setServiceArea((prevState: ServiceArea) => {
                                                                let newEntry = { ...prevState }
                                                                for (let a of newEntry.radii) {
                                                                    if (a.id === radius.id) {
                                                                        a.name = e.target.value
                                                                        break
                                                                    }
                                                                }
                                                                return newEntry
                                                            })
                                                        }}
                                                    />
                                                </MDBox>
                                            </Grid>
                                            <Grid item flex={1}>
                                                <MDTypography sx={{ fontSize: 14 }} fontWeight={"bold"}>Radius (mi)</MDTypography>
                                                <MDBox mt={1}>
                                                    <MDInput
                                                        backgroundColor={"white"}
                                                        fullWidth={true}
                                                        value={radius.radius}
                                                        type="number"
                                                        inputProps={{
                                                            step: 0.1
                                                        }}
                                                        onChange={(e: any) => {
                                                            setServiceArea((prevState: ServiceArea) => {
                                                                let newEntry = { ...prevState }
                                                                for (let a of newEntry.radii) {
                                                                    if (a.id === radius.id) {
                                                                        a.radius = parseFloat(e.target.value)
                                                                        break
                                                                    }
                                                                }
                                                                return newEntry
                                                            })
                                                        }}
                                                    />
                                                </MDBox>
                                            </Grid>
                                            <Grid item>
                                                <Grid container alignItems={"center"} gap={1} justifyContent={"center"}>
                                                    <Grid item>
                                                        <Tooltip title={"See on map"}>
                                                            <MDIconButton backgroundColor={"white"} 
                                                            sx={{
                                                                padding: 0,
                                                                width: "44px",
                                                                height: "44px",
                                                                minWidth: "unset",
                                                                color: '#5551fe !important',
                                                                borderRadius: '3px',
                                                                '&:hover': {
                                                                  backgroundColor: '#5551fe',
                                                                  color: 'white'
                                                                },
                                                            }} onClick={() => {
                                                                map?.setCenter({
                                                                    lat: radius.coordinate.lat,
                                                                    lng: radius.coordinate.lng
                                                                })
                                                                map?.setZoom(12)
                                                            }}>
                                                                <RadiusIcon />
                                                            </MDIconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item>
                                                        <Tooltip title={"Delete"}>
                                                            <MDIconButton backgroundColor={"white"} sx={{
                                                                padding: 0,
                                                                width: "44px",
                                                                height: "44px",
                                                                minWidth: "unset",
                                                                color: '#5551fe',
                                                                borderRadius: '3px',
                                                                '&:hover': {
                                                                  backgroundColor: '#5551fe',
                                                                  color: 'white'
                                                                },
                                                            }} onClick={() => {
                                                                deleteAddress(radius.id)
                                                            }}>
                                                                <DeleteIcon  />
                                                            </MDIconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Divider light={true} />
                                    </MDBox>
                                })}
                                {serviceArea.polygons.map((polygon, index) => {
                                    return <MDBox key={index}>
                                        <Grid container alignItems={"flex-end"} gap={2}>
                                            <Grid item flex={1}>
                                                <MDTypography sx={{ fontSize: 14 }} fontWeight={"bold"}>Address</MDTypography>
                                                <MDBox mt={1}>
                                                    <MDInput
                                                        backgroundColor={"white"}
                                                        fullWidth={true}
                                                        value={polygon.name}
                                                        onChange={(e: any) => {
                                                            setServiceArea((prevState: ServiceArea) => {
                                                                let newEntry = { ...prevState }
                                                                for (let a of newEntry.polygons) {
                                                                    if (a.id === polygon.id) {
                                                                        a.name = e.target.value
                                                                        break
                                                                    }
                                                                }
                                                                return newEntry
                                                            })
                                                        }}
                                                    />
                                                </MDBox>
                                            </Grid>
                                            <Grid item>
                                                <Grid container alignItems={"center"} gap={1} justifyContent={"center"}>
                                                    <Grid item>
                                                        <Tooltip title={"See on map"}>
                                                            <MDIconButton backgroundColor={"white"} sx={{
                                                                padding: 0,
                                                                width: "44px",
                                                                height: "44px",
                                                                minWidth: "unset",
                                                                color: '#5551fe !important',
                                                                borderRadius: '3px',
                                                                '&:hover': {
                                                                  backgroundColor: '#5551fe',
                                                                  color: 'white'
                                                                },
                                                            }} onClick={() => {
                                                                map?.setCenter(getCenterOfPolygon(polygon.coordinates))
                                                                map?.setZoom(12)
                                                            }}>
                                                                <RadiusIcon />
                                                            </MDIconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item>
                                                        <Tooltip title={"Delete"}>
                                                            <MDIconButton backgroundColor={"white"} sx={{
                                                                padding: 0,
                                                                width: "44px",
                                                                height: "44px",
                                                                minWidth: "unset",
                                                                color: '#5551fe !important',
                                                                borderRadius: '3px',
                                                                '&:hover': {
                                                                  backgroundColor: '#5551fe',
                                                                  color: 'white'
                                                                },
                                                            }} onClick={() => {
                                                                deletePolygon(polygon.id)
                                                            }}>
                                                                <DeleteIcon />
                                                            </MDIconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Divider light={true} />
                                    </MDBox>
                                })}
                            </MDBox>
                        </MDCard>
                    </MDBox>
                </Box>
                : null
            }
        </Box>
      </MDCard>
    )
}

export default Step2;