import { ProductTypes } from "../models/enums/ProductTypes";
import largeHandwrittenCard from "assets/images/product_samples/large_handwritten_card.png";
import handwrittenPostCard from "assets/images/product_samples/handwritten_post_card.png";
import printedCardNo10 from "assets/images/product_samples/printed_card_no_10.png";
import { IProduct, IProductCategory } from "models/product";
import printedCard from "assets/images/product_samples/handwritten_post_card.png";

// Images
import image1 from "assets/images/products/card/image1.png"
import image2 from "assets/images/products/card/image2.png"
import image3 from "assets/images/products/card/image3.png"
import image4 from "assets/images/products/card/image4.png"
import image5 from "assets/images/products/card/image5.png"

import bifold1 from "assets/images/products/card/bifold1.png"
import bifold2 from "assets/images/products/card/bifold2.png"
import bifold3 from "assets/images/products/card/bifold3.png"
import bifold4 from "assets/images/products/card/bifold4.png"
import bifold5 from "assets/images/products/card/bifold5.png"

import imagepc1 from "assets/images/products/postcard/image1.png"
import imagepc2 from "assets/images/products/postcard/image2.png"
import imagepc3 from "assets/images/products/postcard/image3.png"
import imagepc4 from "assets/images/products/postcard/image4.png"
import imagepc5 from "assets/images/products/postcard/image5.png"

import image4x6pc1 from "assets/images/products/printed-postcard/4x6/4x6-1-postcard-double.png"
import image4x6pc2 from "assets/images/products/printed-postcard/4x6/4x6-1-postcard-front.png"
import image4x6pc3 from "assets/images/products/printed-postcard/4x6/4x6-2-postcard-double.png"
import image4x6pc4 from "assets/images/products/printed-postcard/4x6/4x6-3-postcard-double.png"
import image4x6pc5 from "assets/images/products/printed-postcard/4x6/4x6-4-postcard-double.png"

import image6x9pc1 from "assets/images/products/printed-postcard/6x9/6x9-1-postcard-double.png"
import image6x9pc2 from "assets/images/products/printed-postcard/6x9/6x9-1-postcard-front.png"
import image6x9pc3 from "assets/images/products/printed-postcard/6x9/6x9-1-postcard-rear.png"
import image6x9pc4 from "assets/images/products/printed-postcard/6x9/6x9-2-postcard-double.png"
import image6x9pc5 from "assets/images/products/printed-postcard/6x9/6x9-3-postcard-double.png"

import image6x11pc1 from "assets/images/products/printed-postcard/6x11/6x11-1-postcard-double.png"
import image6x11pc2 from "assets/images/products/printed-postcard/6x11/6x11-1-postcard-rear.png"
import image6x11pc3 from "assets/images/products/printed-postcard/6x11/6x11-2-postcard-double.png"
import image6x11pc4 from "assets/images/products/printed-postcard/6x11/6x11-2-postcard-front.png"
import image6x11pc5 from "assets/images/products/printed-postcard/6x11/6x11-2-postcard-rear.png"

import n10card1 from "assets/images/products/card/n10card1.png"
import n10card2 from "assets/images/products/card/n10card2.png"

export const products: IProduct[] = [
    {
        id: 2,
        name: ProductTypes.LargeHandwrittenCardA8,
        label: "Handwritten Flat Card + Envelope",
        overview1:
            "As our largest product offering, the Large Handwritten Card supports up to 650 characters. A little larger than a typical postcard, and a bit smaller than an invitation envelope, the Large Handwritten Card has a very unique and premium feel. Placed inside a fitted, pen-addressed envelope with a real stamp, the Handwritten Card gives the recipient a strong feel of authenticity.",
        overview2:
            'The large format lends itself well to outreach campaigns where the extended text can really make a difference. With the option of an eye-catching, full-sized, 5.3" x 7.5" full-bleed rear print and large graphics, there is plenty of room to stand-out, or add more details in the printed design.',
        bullet1: '5.3" x 7.5"',
        bullet2: "Up to 500 characters included in base price (800 max)",
        bullet2_short: "500 Characters included, up to 800 available",
        bullet3: "Handwritten Envelope",
        bullet4: "Real Stamp",
        icon: "mail",
        image: largeHandwrittenCard,
        priceBeforeAddons: 1.70,
        safeZoneArea: '(5.1" x 7.3")',
        fullBleedArea: '(5.5" x 7.7")',
        designSpecsUrl: "https://www.lettrlabs.com/help-guide/large-handwritten-cards-specs",
        publiclyAvailable: true
    },
    {
        id: 8,
        name: ProductTypes.HandwrittenBiFoldCard,
        label: "Handwritten Bi-fold card + Envelope",
        overview1:
            'As an enhanced product offering, the Handwritten Bi-fold Card supports up to 1600 characters, providing ample space for a more detailed and personal message. Each panel of the bifold card measures 5.3" x 7.5", offering the same safe-zone and full-bleed areas as our other cards, but now with twice the writing space. The Handwritten Bi-fold Card exudes a premium feel and is perfect for those who want to convey a more in-depth message. The card is placed inside a fitted, pen-addressed envelope with a real stamp, enhancing the recipient\'s experience with a strong sense of authenticity.',
        overview2:
            'The dual-panel format is ideal for outreach campaigns where extended text can make a significant impact. With the option of a striking, full-sized, 5.3" x 7.5" full-bleed print on the front and ample room for large graphics, this card allows for creative and detailed designs that stand out. The Handwritten Bi-fold Card is perfect for those looking to deliver a premium message with plenty of room for personalization and detail.',
        bullet1: '10.6" x 7.5"',
        bullet2: "Up to 500 characters included in base price (800 max per panel)",
        bullet2_short: "500 Characters included, up to 800 per panel available",
        bullet3: "Handwritten Envelope",
        bullet4: "Real Stamp",
        icon: "mail",
        image: largeHandwrittenCard,
        priceBeforeAddons: 2.05,
        safeZoneArea: '(10.4" x 7.3")',
        fullBleedArea: '(10.8" x 7.7")',
        designSpecsUrl: "https://www.lettrlabs.com/help-guide/handwritten-bifold-cards-specs",
        publiclyAvailable: true
    },
    {
        id: 3,
        name: ProductTypes.HandwrittenPostCardA8,
        label: "Handwritten Postcard",
        overview1:
            "Our Postcard is a great, inexpensive alternative for large-scale outreach campaigns. Get straight to the point with a large design on the rear, with full-bleed, premium printing.",
        overview2:
            'With up to 350 characters, our Postcard is perfect to grab someone\'s attention with a short hook and a great design that leads them wanting to connect to learn more.  With the option of an eye-catching, full-sized, 5.3" x 7.8" full-bleed rear print and large graphics, there is plenty of room to stand-out, or add more details in the printed design.',
        bullet1: '5.3" x 7.8"',
        bullet2: "350 Characters included",
        bullet3: "N/A",
        bullet4: "Real Stamp",
        icon: "featured_play_list",
        image: handwrittenPostCard,
        priceBeforeAddons: 0.95,
        safeZoneArea: '(5.1" x 7.6")',
        fullBleedArea: '(5.5" x 8")',
        designSpecsUrl: "https://www.lettrlabs.com/help-guide/handwritten-postcard-specs",
        publiclyAvailable: true
    },
    {
        id: 6,
        name: ProductTypes.PrintedPostcard4x6,
        label: 'Printed Postcard - 4"x6"',
        overview1: '',
        overview2: '',
        bullet1: '4" x 6"',
        bullet2: "N/A",
        bullet3: "N/A",
        bullet4: "Printed Stamp",
        icon: "rectangle",
        image: printedCard,
        priceBeforeAddons: 0.61,
        safeZoneArea: '(3.7" x 5.7")',
        fullBleedArea: '(4.2" x 6.2")',
        designSpecsUrl: "https://www.lettrlabs.com/help-guide/printed-postcard-specs-4-2-x-6-2",
        publiclyAvailable: true
    },
    {
        id: 7,
        name: ProductTypes.PrintedPostcard6x9,
        label: 'Printed Postcard - 6"x9"',
        overview1: '',
        overview2: '',
        bullet1: '6" x 9"',
        bullet2: "N/A",
        bullet3: "N/A",
        bullet4: "Printed Stamp",
        icon: "rectangle",
        image: printedCard,
        priceBeforeAddons: 0.66,
        safeZoneArea: '(5.7" x 8.7")',
        fullBleedArea: '(6.2" x 9.2")',
        designSpecsUrl: "https://www.lettrlabs.com/help-guide/printed-postcard-specs-6-2-x-9-2",
        publiclyAvailable: true
    },
    {
        id: 5,
        name: ProductTypes.PrintedPostcard6x11,
        label: 'Printed Postcard - 6"x11"',
        overview1: '',
        overview2: '',
        bullet1: '6" x 11"',
        bullet2: "N/A",
        bullet3: "N/A",
        bullet4: "Printed Stamp",
        icon: "rectangle",
        image: printedCard,
        priceBeforeAddons: 0.92,
        safeZoneArea: '(5.7" x 10.7")',
        fullBleedArea: '(6.2" x 11.2")',
        designSpecsUrl: "https://www.lettrlabs.com/help-guide/printed-postcard-specs-6-2-x-11-2",
        publiclyAvailable: true
    },
    {
        id: 9,
        name: ProductTypes.PrintedCardNo10,
        label: "Handwritten #10 Envelope & Printed Letter",
        overview1: '',
        overview2: '',
        bullet1: '8.5" x 11"',
        bullet2: "N/A",
        bullet2_short: "N/A",
        bullet3: "Handwritten Envelope",
        bullet4: "Real Stamp",
        icon: "mail",
        image: printedCardNo10,
        priceBeforeAddons: 0.79,
        safeZoneArea: '(8.25" x 10.75")',
        fullBleedArea: '(8.5" x 11")',
        designSpecsUrl: "https://www.lettrlabs.com/help-guide/printd_card_no_10-specs",
        publiclyAvailable: false
    }
]

export const productCategories: IProductCategory[] = [
    {
        id: 0,
        name1: "Handwritten Card",
        name2: "+ Envelope",
        products: [2, 8],
        images: [[image1, image2, image3, image4, image5],
        [bifold1, bifold2, bifold3, bifold4, bifold5]],
        publiclyAvailable: true
    },
    {
        id: 1,
        name1: "Printed",
        name2: "Postcard",
        products: [6, 7, 5],
        images: [
            [image4x6pc1, image4x6pc2, image4x6pc3, image4x6pc4, image4x6pc5],
            [image6x9pc1, image6x9pc2, image6x9pc3, image6x9pc4, image6x9pc5],
            [image6x11pc1, image6x11pc2, image6x11pc3, image6x11pc4, image6x11pc5],
        ],
        publiclyAvailable: true
    },
    {
        id: 2,
        name1: "Handwritten Postcard",
        name2: "Postcards",
        products: [3],
        images: [imagepc1, imagepc2, imagepc3, imagepc4, imagepc5],
        publiclyAvailable: true
    },
    {
        id: 3,
        name1: "Handwritten Envelope",
        name2: "+ Printed Letter",
        products: [9],
        images: [n10card1, n10card2],
        publiclyAvailable: false
    }
]