import  { useEffect, useState} from "react";

import InfoModal from "components/info-modal";

import {Elements} from "@stripe/react-stripe-js";
import {loadStripe, Stripe} from "@stripe/stripe-js";
import {Dialog, DialogContent, Typography} from "@mui/material";
import NewCreditCard from "newStandard/src/components/PaymentManager/components/NewCreditCard";
import NewACH from "newStandard/src/components/PaymentManager/components/NewACH";
import { getUserData } from "helpers/getUserInfos";
import ProfileService from "services/profile";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

interface AddPaymentMethodModalProps {
    show: boolean
    setShow: Function
    handleReloadPaymentMethods: Function
}

function AddPaymentMethodModal({show, setShow, handleReloadPaymentMethods}: AddPaymentMethodModalProps) {
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null)

    useEffect(() => {
        setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!))
    }, []);

    return <Elements stripe={stripePromise}>
        <Content show={show} setShow={setShow} handleReloadPaymentMethods={handleReloadPaymentMethods}/>
    </Elements>
}

function Content({show, setShow, handleReloadPaymentMethods}: AddPaymentMethodModalProps) {
    const YEARLY = 4;
    const { enqueueSnackbar } = useSnackbar();
    const { addProfilePaymentMethod } = ProfileService();

    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<any>()
    const [submitingPayment, setSubmtingPayment] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>('');

    const { mutate: addPaymentMethod } = useMutation({
        mutationFn: addProfilePaymentMethod,
        onSuccess: (result) => {
            if (result.hasErrors) {
                setErrorMessage(result.errors[0]);
            } else {
                setShow(false);
                setSubmtingPayment(false);
                handleReloadPaymentMethods();
                enqueueSnackbar('Payment method added successfully.', { variant: 'success' } );
            }
            setTimeout(() => {
                setErrorMessage(null);
            }, 8000);
        },
    });


    return <Dialog open={show} onClose={() => setShow(false)}>
        <DialogContent sx={{width: "500px"}}>
            <Typography mb={4} sx={{fontSize: 20}} fontWeight={"bold"}>Add a new Payment Method</Typography>
            
            <NewCreditCard
                handleAddPaymentMethod={(value: string) => addPaymentMethod(value)}
                errorMessage={errorMessage}
                setErrorMessage={(value: string | null) => setErrorMessage(value)}
                loadingProfilePayments={false} 
                selectedPaymentMethodId={selectedPaymentMethodId} 
                setSelectedPaymentMethodId={(value: string) => setSelectedPaymentMethodId(value)} 
                submitingNewPaymentMethod={submitingPayment}
                setSubmitingNewPaymentMethod={(value: boolean) => setSubmtingPayment(value)}
            />

            {
                getUserData()?.customerSubscription?.billingPeriodId === YEARLY && (
                    <NewACH 
                        data={null} 
                        profile={getUserData()}
                        loadingProfilePayments={false} 
                        selectedPaymentMethodId={selectedPaymentMethodId}
                        setSubmiting={(value: boolean) => setSubmtingPayment(value)} 
                        setErrorMessage={(value: string | null) => setErrorMessage(value)}
                        handleUpdateList={() => {
                            setSubmtingPayment(false);
                            setShow(false);
                            handleReloadPaymentMethods();
                        }}
                        setIsSubmitingPayment={(value: boolean) => setSubmtingPayment(value)}
                        setSelectedPaymentMethodId={(value: string) => setSelectedPaymentMethodId(value)} 
                    />
                )
                
            }

        </DialogContent>

        <InfoModal
            show={showErrorModal}
            setShow={setShowErrorModal}
            headerText={errorMessage}
            showConfirmButton={true}
            confirmButtonOnClick={() => {
                setShowErrorModal(false)
            }}
        />
    </Dialog>
}

export default AddPaymentMethodModal