import {Theme} from "@mui/material/styles";
import {RadiusMail} from "models/radius-mailing/radiusMail";
import {useGlobal} from "context/global-context";
import {RecipientSearch} from "models/recipient-search/recipientSearch";
import {useState, useEffect} from "react";
import {formatNumber, formatPrice} from "helpers/formatters";
import {Elements, useElements, useStripe} from "@stripe/react-stripe-js";
import {loadStripe, PaymentMethod, Stripe} from "@stripe/stripe-js";
import { CircularProgress, Dialog, DialogContent, Table, TableBody, TableRow, TextField } from "@mui/material";

import MDBox from "material-ui/components/MDBox";
import MDButton from "material-ui/components/MDButton";
import InfoModal from "components/info-modal";
import DialogTitle from "@mui/material/DialogTitle";
import MDTypography from "material-ui/components/MDTypography";
import PaymentManager from "newStandard/src/components/PaymentManager";
import RadiusMailService from "services/radius-mail";

interface CheckoutModalProps{
    show: boolean
    setShow: Function
    radiusMail: RadiusMail
    setRadiusMail: Function
    showSuccessfulCheckoutModal: boolean
    setShowSuccessfulCheckoutModal: Function
}

function CheckoutContent({show, setShow, radiusMail, setRadiusMail,setShowSuccessfulCheckoutModal}: CheckoutModalProps) {
    const {showLoader, setShowLoader} = useGlobal()

    const stripe = useStripe();
    const elements = useElements();

    const {postRadiusMail, postRadiusMailCheckout, getRadiusMailCheckout} = RadiusMailService()

    const [stripeToken, setStripeToken] = useState()
    const [selectedCard, setSelectedCard] = useState<PaymentMethod | null>(null)
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null)

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)

    const [errorModalMessage, setErrorModalMessage] = useState<string>('')

    const [checkout, setCheckout] = useState<any>(null)

    const borderBottom = {
        borderBottom: ({ borders: { borderWidth }, palette: { light } }: Theme) =>
            `${borderWidth[1]} solid ${light.main}`,
    }

    function checkoutFn(){
        if (!stripe || !elements) {
            return;
        }
        
        setShowLoader(true)

        // Update the name of the order
        postRadiusMail(radiusMail).then(() => {
            //Paying with an already existing card
            if(selectedCard){
                if(selectedCard.type === 'card') {
                    stripe.confirmCardPayment(checkout.stripeToken, { 
                      payment_method: selectedCard.id
                    }).then((result) => {
                        handlePaymentCallback(result)
                    })
                  } else if(selectedCard.type === 'us_bank_account') {
                    stripe.confirmUsBankAccountPayment(checkout.stripeToken, {
                      payment_method: selectedCard.id
                    }).then((result) => {
                        handlePaymentCallback(result)
                    });
                }
            }
            else{
                elements.submit()
                    .then((result1) => {
                        if(result1.error){
                            return;
                        }

                        //stripe.confirmPayment will only redirect if the user chooses a redirect-based payment method
                        stripe.confirmPayment({
                            elements,
                            redirect: "if_required"
                        }).then((result2: any) => {
                            handlePaymentCallback(result2)
                        })
                    })
            }
        })
    }

    function handlePaymentCallback(response: any){
        if(response.error){
            let error = response.error
            let message

            if (error.type === "card_error") {
                if(error.code === "card_declined"){
                    message = "You card has been declined. Please contact your bank for additional information"
                }
                else if(error.code === "expired_card"){
                    message = "You card has expired"
                }
                else if(error.code === "incorrect_cvc" || error.code === "incorrect_number"){
                    message = "Invalid cvc or number. Please verify your credentials and try again"
                }
                else{
                    message = "An unexpected error occurred. Please try again or contact billing@lettrlabs.com"
                }
            }
            else {
                message = "An unexpected error occurred. Please try again or contact billing@lettrlabs.com"
            }

            setShowErrorModal(true)
            setErrorModalMessage(message)
        }
        else{
            let paymentIntent = response.paymentIntent;

            if((paymentIntent.status === 'processing' || paymentIntent.status === "succeeded") && selectedCard?.type === 'us_bank_account' ){
                setShow(false)
                setShowSuccessfulCheckoutModal(true)
            }

            if((paymentIntent.status === "succeeded") && selectedCard?.type === 'card' ){
                setShow(false)
                setShowSuccessfulCheckoutModal(true)
            }
        }

        setShowLoader(false)
    }

    useEffect(function(){
        if (show){
            setIsLoading(true)
            setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? ''))

            getRadiusMailCheckout(radiusMail.id).then((response) => {
                if(response.hasErrors){
                    //setErrorModalMessage(response.errors[0]);
                    //setShowErrorModal(true);
                }
                else{
                    setStripeToken(response.payload.stripeToken)
                    setCheckout(response.payload)
                }

                setIsLoading(false)
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    function checkoutFree(){
        setShowLoader(true)

        // Update the name of the order
        postRadiusMail(radiusMail).then(() => {
            postRadiusMailCheckout(radiusMail.id).then((response) => {
                if(response.hasErrors){
                    setShowErrorModal(true)
                    setErrorModalMessage('An unexpected error occurred. Please try again or contact billing@lettrlabs.com')
                }

                setShowSuccessfulCheckoutModal(true)
                setShow(false)

                setShowLoader(false)
            })
        })
    }

    function handleCheckout() {
        checkout.credits.length > 0 ? checkoutFree() : checkoutFn();
    }

    return <Dialog open={show} onClose={() => setShow(false)} maxWidth={false}>
        <DialogContent sx={{width: "780px"}}>
            <DialogTitle p={0}>
                Checkout
            </DialogTitle>

            {!isLoading && checkout?
                <MDBox mt={2}>
                    <MDTypography sx={{fontSize: 16}} color="text" fontWeight="bold">
                        Order Name:
                    </MDTypography>


                    <MDBox mt={1}>
                        <TextField
                            sx={{width: "100%"}}
                            type="text"
                            value={radiusMail.name}
                            InputLabelProps={{shrink: true}}
                            onChange={(event) => {
                                setRadiusMail((prevState: RecipientSearch) => {
                                    let copy: RecipientSearch = {...prevState}

                                    copy.name = event.target.value

                                    return copy
                                })
                            }}
                        />

                        {!radiusMail.name?
                            <MDTypography
                                variant={"h6"}
                                fontWeight={"regular"}
                                color="error"
                            >
                                {'This field is required'}
                            </MDTypography>
                            :
                            null
                        }
                    </MDBox>


                    <Table sx={{ marginTop: "30px" }}>
                        <MDBox component="thead">
                            <TableRow>
                                <MDBox
                                    component="th"
                                    width={{ xs: "45%", md: "50%" }}
                                    py={1.5}
                                    px={1}
                                    textAlign="left"
                                    sx={borderBottom}
                                >
                                    <MDTypography sx={{fontSize: 16}} color="text" fontWeight="bold">
                                        Item:
                                    </MDTypography>
                                </MDBox>

                                <MDBox
                                    component="th"
                                    py={1.5}
                                    pl={3}
                                    pr={1}
                                    textAlign="left"
                                    sx={borderBottom}
                                >
                                    <MDTypography sx={{fontSize: 16}} color="text" fontWeight="bold">
                                        Quantity:
                                    </MDTypography>
                                </MDBox>

                                <MDBox
                                    component="th"
                                    py={1.5}
                                    pl={3}
                                    pr={1}
                                    textAlign="left"
                                    sx={borderBottom}
                                >

                                </MDBox>

                                <MDBox
                                    component="th"
                                    py={1.5}
                                    pl={3}
                                    pr={1}
                                    textAlign="left"
                                    sx={borderBottom}
                                >
                                    <MDTypography sx={{fontSize: 16}} color="text" fontWeight="bold">
                                        Amount:
                                    </MDTypography>
                                </MDBox>
                            </TableRow>
                        </MDBox>

                        <TableBody>
                            {checkout.lineItems &&
                                checkout.lineItems.map((lineItem: any, index: number) => (
                                    <TableRow key={"record" + index} sx={index === checkout.lineItems.length - 1? {} : {"td": {border: "none"}}}>
                                        <MDBox component="td" textAlign="left" p={1} sx={borderBottom}>
                                            <MDTypography
                                                variant="body2"
                                                color="text"
                                                fontWeight="regular"
                                            >
                                                {lineItem.name}
                                            </MDTypography>
                                        </MDBox>
                                        <MDBox
                                            component="td"
                                            textAlign="left"
                                            py={1}
                                            pr={1}
                                            pl={3}
                                            sx={borderBottom}
                                        >
                                            <MDTypography
                                                variant="body2"
                                                color="text"
                                                fontWeight="regular"
                                            >
                                                {formatNumber(lineItem.quantity)}
                                            </MDTypography>
                                        </MDBox>
                                        <MDBox
                                            component="td"
                                            textAlign="left"
                                            py={1}
                                            pr={1}
                                            pl={3}
                                            sx={borderBottom}
                                        >
                                            x {formatPrice(lineItem.rate)}
                                        </MDBox>
                                        <MDBox
                                            component="td"
                                            textAlign="left"
                                            py={1}
                                            pr={1}
                                            pl={3}
                                            sx={borderBottom}
                                        >
                                            <MDTypography
                                                variant="body2"
                                                color="text"
                                                fontWeight="regular"
                                            >
                                                {lineItem.total >= 0 && formatPrice(lineItem.total)}
                                                {lineItem.total < 0 && "-" + formatPrice(lineItem.total * -1)}
                                            </MDTypography>
                                        </MDBox>
                                    </TableRow>
                                ))}
                            <TableRow sx={{"td:not(:nth-last-child(-n+2))": {border: "none"}}}>
                                <MDBox component="td" textAlign="left" p={1} sx={borderBottom} />
                                <MDBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                />
                                <MDBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                >
                                    <MDTypography sx={{fontSize: 16}} color="text" fontWeight="bold">
                                        Subtotal
                                    </MDTypography>
                                </MDBox>
                                <MDBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                >
                                    <MDTypography sx={{fontSize: 16}} color="text" fontWeight="bold">
                                        {formatPrice(checkout.totalCharges)}
                                    </MDTypography>
                                </MDBox>
                            </TableRow>

                            {checkout.credits.length > 0 && (
                                <TableRow sx={{"td:not(:nth-last-child(-n+2))": {border: "none"}}}>
                                    <MDBox textAlign="left" p={1} sx={borderBottom} />
                                    <MDBox
                                        component="td"
                                        textAlign="left"
                                        py={1}
                                        pr={1}
                                        pl={3}
                                        sx={borderBottom}
                                    />
                                    <MDBox
                                        component="td"
                                        textAlign="left"
                                        py={1}
                                        pr={1}
                                        pl={3}
                                        sx={borderBottom}
                                    >
                                        <MDTypography sx={{fontSize: 16}} color="text" fontWeight="bold">
                                            Credits
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox
                                        component="td"
                                        textAlign="left"
                                        py={1}
                                        pr={1}
                                        pl={3}
                                        sx={borderBottom}
                                    >
                                        <MDTypography sx={{fontSize: 16}} color="text" fontWeight="bold">
                                            {formatPrice(checkout.totalCredits)}
                                        </MDTypography>
                                    </MDBox>
                                </TableRow>
                            )}

                            <TableRow sx={{"td": {border: "none"}}}>
                                <MDBox component="td" textAlign="left" p={1} sx={borderBottom} />
                                <MDBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    sx={borderBottom}
                                />
                                <MDBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    sx={borderBottom}
                                >
                                    <MDTypography sx={{fontSize: 16}} color="primary" fontWeight="bold">
                                        Total Due
                                    </MDTypography>
                                </MDBox>
                                <MDBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    sx={borderBottom}
                                >
                                    <MDTypography sx={{fontSize: 16}} color="primary" fontWeight="bold">
                                        {checkout.balance >= 0 && formatPrice(checkout.balance)}
                                        {checkout.balance < 0 && formatPrice(-1 * checkout.balance)}
                                    </MDTypography>
                                </MDBox>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <MDBox mt={2}>
                        {(checkout && checkout.stripeToken) && 
                            <MDBox>
                                {checkout.stripeToken && stripePromise?
                                    <Elements stripe={stripePromise} options={{clientSecret: stripeToken}}>
                                        <PaymentManager data={null} handleSelectedPayment={(value) => setSelectedCard(value)} />
                                    </Elements>
                                    :
                                    null
                                }
                            </MDBox>
                        }
                        <MDBox
                            mt={3}
                            width="100%"
                            display="flex"
                            justifyContent="flex-end"
                            gap={2}
                        >
                            <MDButton
                                color="light"
                                onClick={() => setShow(false)}
                            >
                                Cancel
                            </MDButton>

                            <MDButton
                                color="primary"
                                disabled={showLoader || (!selectedCard && checkout.totalCredits < checkout.totalCharges) || !radiusMail.name }
                                onClick={handleCheckout}
                            >
                                { showLoader ?  <CircularProgress size={18} color="inherit" /> : 'Checkout'}
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </MDBox>
                :
                <MDBox textAlign="center">
                    <CircularProgress color="primary"></CircularProgress>
                </MDBox>
            }
        </DialogContent>

        <InfoModal
            show={showErrorModal}
            setShow={setShowErrorModal}
            headerText={errorModalMessage}
            showCancelButton={false}
            showConfirmButton={true}
            confirmButtonOnClick={() => {setShowErrorModal(false)}}
        />
    </Dialog>
}

function CheckoutModal(props: CheckoutModalProps) {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? "");

    return (
        <Elements stripe={stripePromise}>
            <CheckoutContent {...props} />
        </Elements>
    );
}

export default CheckoutModal;