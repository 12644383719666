import useFetchWithMsal from "hooks/useFetch";
import API_ENDPOINT from "../endpoint";

function TemplateService(){
    const {execute} = useFetchWithMsal()

    const API_PATH = `${API_ENDPOINT}/orderTemplates`

    // Gets all of the user's templates
    function getTemplates(shouldCheckNotSupported: boolean = false){
        return execute({
            endpoint: `${API_PATH}?shouldCheckNotSupported=${shouldCheckNotSupported}`,
            method: "GET"
        })
    }

    // Gets a template
    function getTemplate(id: number | null){
        if(id === null) return

        return execute({
            endpoint: `${API_PATH}/${id}`,
            method: "GET"
        })
    }

    function postTemplate(template: any){
        return execute({
            endpoint: API_PATH,
            method: "POST",
            data: template
        })
    }

    // Creates an order from a provided name and templateId
    function createOrderFromTemplate(name: string, templateId: number){
        return execute({
            endpoint: `${API_PATH}/createOrder/${templateId}`,
            method: "POST",
            data: {
                name: name
            }
        })
    }

    // Creates a template from an order
    function createTemplateFromOrder(id: number, templateName: string, userIdToCopyTo: number | null){
        return execute({
            endpoint: `${API_PATH}/createfromorder/${id}`,
            method: "POST",
            data: { templateName, userIdToCopyTo }
        })
    }

    // Deletes a template
    function deleteTemplate(id: number){
        return execute({
            endpoint: `${API_PATH}/delete/${id}`,
            method: "POST"
        })
    }

    return {getTemplates, createOrderFromTemplate, deleteTemplate, postTemplate, createTemplateFromOrder, getTemplate}
}

export default TemplateService