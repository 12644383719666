import {Coordinate} from "../coordinate";
import {ITemplate} from "../template";
import {OwnerOrRenterFilter, PropertyTypeFilter} from "../radius-mailing/filters";

export interface ServiceArea{
    id: number;
    name: string | null;
    totalStructures: number | null;
    estimatedPricePerCard: number | null,
    canDelete: boolean;
    canEdit: boolean;
    isProcessed: boolean;
    status: ServiceAreaStatus;
    template: ITemplate | null;
    homeOwnerFilter: OwnerOrRenterFilter;
    propertyTypeFilter: PropertyTypeFilter;
    hailSizeMinFilter: number | null;
    windSpeedMinFilter: number | null;
    cardsToSendPerStormMax: number;
    resendAfterWeeks: number | null;
    startSendingAtDate: string | null;
    stopSendingAtDate: string | null;
    polygons: ServiceAreaPolygon[];
    radii: ServiceAreaRadius[]
    queuedCount: number | null;
    inProductionCount: number | null;
    mailedCount: number | null;
}

export enum ServiceAreaStatus{
    Draft=0,
    Active=1,
    Inactive=2
}

export interface ServiceAreaRadius{
    id: number
    recipientSearchEntryId: number
    radius: number
    name: string
    coordinate: {
        lat: number
        lng: number
    }
    isNew?: boolean
    structures: number
    isProcessed: boolean
    type?: string
}

export interface ServiceAreaPolygon{
    id: number
    recipientSearchEntryId?: number
    name: string
    coordinates: Coordinate[]
    isNew?: boolean
    structures: number
    isProcessed: boolean
    type?: string
}