import React, { forwardRef, useCallback, useRef } from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { IMaskInput } from 'react-imask';
import { IStepOneData } from '../..';

import MDCard from 'material-ui/components/MDCard';
import MDInput from 'material-ui/components/MDInput';

interface IStepOne {
  stepOneData: IStepOneData;
  emailError: string;
  setEmailError: (value: string) => void;
  setStepOneData: (data: IStepOneData) => void;
}

const isValidEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const TextMaskPhoneNumber = forwardRef<HTMLInputElement, any>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(000) 000-0000"
        inputRef={(el) => {
          if (ref) {
            if (typeof ref === 'function') {
              ref(el);
            } else if (ref && 'current' in ref) {
              ref.current = el;
            }
          }
        }}
        onAccept={(value: string) => {
          if (onChange) {
            onChange({ target: { name: props.name, value } });
          }
        }}
        overwrite
      />
    );
  }
);

const Step1: React.FC<IStepOne> = ({ stepOneData, setStepOneData, emailError, setEmailError }) => {
  const phoneInputRef = useRef<HTMLInputElement>(null);

  const handlePhoneChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (stepOneData.phone !== e.target.value) {
      setStepOneData({ ...stepOneData, phone: e.target.value });
    }
  }, [stepOneData, setStepOneData]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setStepOneData({ ...stepOneData, email: newEmail });

    if (emailError) setEmailError("");
  };

  const handleEmailBlur = () => {
    if (!isValidEmail(stepOneData.email)) {
      setEmailError("Invalid E-mail");
    }
  };

  return (
    <MDCard sx={{ width: '100%' }}>
      <Box sx={{ position: 'relative', height: '50px', display: 'flex', alignItems: 'center', padding: '0 2rem' }}>
        <Typography fontSize={14} fontWeight={'bold'} variant='caption'>Personal Information</Typography>
        <Box sx={{ border: '1.5px solid #eef2ff', position: 'absolute', left: 0, top: '50px', width: '100%' }} />
      </Box>
      <Box sx={{ padding: '2rem', gap: '15px', display: 'flex', flexDirection: 'column' }}>
        <MDInput
          fullWidth
          label="Full Name"
          value={stepOneData.fullName}
          onChange={(e: any) => setStepOneData({ ...stepOneData, fullName: e.target.value })}
          InputLabelProps={{ shrink: true }}
          sx={{
            "& label.Mui-focused": { color: "#5551FF !important" },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": { borderColor: "#5551FF !important" }
            }
          }}
        />
        <MDInput
          fullWidth
          label="Email"
          type="email"
          value={stepOneData.email}
          onChange={handleEmailChange}
          onBlur={handleEmailBlur}
          error={!!emailError}
          helperText={emailError}
          InputLabelProps={{ shrink: true }}
          sx={{
            "& label.Mui-focused": { color: "#5551FF !important" },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": { borderColor: "#5551FF !important" }
            }
          }}
        />
        <MDInput
          fullWidth
          label="Phone number"
          InputLabelProps={{ shrink: true }}
          value={stepOneData.phone}
          onChange={handlePhoneChange}
          inputRef={phoneInputRef}
          InputProps={{
            inputComponent: TextMaskPhoneNumber
          }}
          sx={{
            "& label.Mui-focused": { color: "#5551FF !important" },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": { borderColor: "#5551FF !important" }
            }
          }}
        />
        <MDInput
          fullWidth
          label="Business Name (Optional)"
          value={stepOneData.businessName}
          onChange={(e: any) => setStepOneData({ ...stepOneData, businessName: e.target.value })}
          InputLabelProps={{ shrink: true }}
          sx={{
            "& label.Mui-focused": { color: "#5551FF !important" },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": { borderColor: "#5551FF !important" }
            }
          }}
        />
      </Box>
    </MDCard>
  );
};

export default Step1;
