import React, { useState } from 'react';

import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { CircularProgress, Dialog, DialogContent, Typography } from '@mui/material';
import { ServiceArea, ServiceAreaStatus } from 'models/hail-trace/ServiceArea';

import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import MDButton from 'material-ui/components/MDButton';
import TradeShowService from 'services/trade-show';
import VerticalLinearStepper from './components/VerticalLinearStepper';

export interface IStepOneData {
    fullName: string,
    email: string,
    phone: string,
    businessName: string
}

const TradeShow: React.FC = () => {
    
    const { saveData } = TradeShowService();
    const { enqueueSnackbar } = useSnackbar();
    
    const [isSaving, setIsSaving] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [activeStep, setActiveStep] = useState(0);
    const [showConfirmReset, setShowConfirmReset] = useState(false);

    const [stepOneData, setStepOneData] = useState<IStepOneData>({
        fullName: '',
        email: '',
        phone: '',
        businessName: ''
    })

    const [serviceArea, setServiceArea] = useState<ServiceArea>({
        id: 0,
        name: null,
        totalStructures: null,
        estimatedPricePerCard: null,
        canEdit: true,
        isProcessed: false,
        status: ServiceAreaStatus.Draft,
        radii: [],
        polygons: [],
        cardsToSendPerStormMax: 50000,
        template: null,
        hailSizeMinFilter: null,
        homeOwnerFilter: 0,
        propertyTypeFilter: 0,
        windSpeedMinFilter: null,
        resendAfterWeeks: null,
        startSendingAtDate: null,
        stopSendingAtDate: null,
        canDelete: true,
        queuedCount: null,
        inProductionCount: null,
        mailedCount: null
    })

    const stepToRender = () => {
        switch (activeStep) {
            case 0:
                return <Step1 stepOneData={stepOneData} setStepOneData={(value: IStepOneData) => setStepOneData(value)} emailError={emailError} setEmailError={(value: string) => setEmailError(value)} />
            case 1:
                return <Step2 serviceArea={serviceArea} setServiceArea={(value: ServiceArea) => setServiceArea(value)}/>
            case 2:
                return <Step3 handleReset={handleReset}/>
            default:
                return <Step1 stepOneData={stepOneData} setStepOneData={(value: IStepOneData) => setStepOneData(value)} emailError={emailError} setEmailError={(value: string) => setEmailError(value)} />
        }
    }

    const handleFoward = () => {
        if(activeStep < 1) {
            setActiveStep(activeStep + 1)
        } else {
            setIsSaving(true);
            const objEnv = {
                ...stepOneData,
                payload: JSON.stringify(serviceArea)
            }

            saveData(objEnv)
                .then(() => {
                    setActiveStep(2);
                    setIsSaving(false);
                })
                .catch(() => {
                    setIsSaving(false);
                    enqueueSnackbar('Something went wrong, try again later.', { variant: 'error' })
                });
        }
    }

    const handleReset = () => {
        setStepOneData({
            fullName: '',
            businessName: '',
            email: '',
            phone: ''
        })

        setServiceArea({
            ...serviceArea,
            radii: [],
            polygons: []
        })

        setActiveStep(0);
    }

    
    return (
        <Dialog open={true} fullScreen fullWidth maxWidth="lg">
            <DialogContent sx={{ margin: 0, padding: 0, boxSizing: 'border-box'}}>
                <Box sx={{ display: 'flex', boxSizing: 'border-box', padding: 0, margin: 0, width: '100%', height: '100vh'}}>
                    <Box sx={{
                        background: 'white',
                        width: '180px',
                        height: '100%',
                        margin: 0,
                        padding: '2rem 1rem',
                        boxSizing: 'border-box',
                        position: 'fixed',
                        left: 0,
                        top: 0
                    }}>
                        <VerticalLinearStepper activeStep={activeStep} />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        background: 'linear-gradient(to bottom right, rgba(85, 81, 255, 0.1), rgba(238, 242, 254, 0.1), rgba(255, 202, 185, 0.1)) !important',
                        width: '100%',
                        height: '100vh',
                        marginLeft: '180px',
                        padding: '2rem 0',
                        boxSizing: 'border-box',
                    }}>
                        <Box sx={{ width: 800 }}>
                            { stepToRender() }
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '800px', margin: '2rem 0', paddingBottom: 2 }}>
                            <Box>
                                {
                                    activeStep <= 1 && (
                                        <MDButton 
                                            variant='transparent' 
                                            onClick={() => setShowConfirmReset(true)}
                                            sx={{ 
                                                color: '#5551FF !important', 
                                                borderColor: '#5551FF !important',
                                                '&:hover': {
                                                backgroundColor: 'rgba(85, 81, 255, 0.1) !important'
                                            } 
                                        }}>
                                            Reset
                                        </MDButton>
                                    )
                                }
                            </Box>
                            <Box sx={{ display: 'flex', gap: 2}}>
                                {
                                    activeStep === 1 && (
                                        <MDButton 
                                            variant='transparent' 
                                            sx={{ 
                                                border: 'none !important', 
                                                color: '#5551FF !important',
                                                '&:hover': {
                                                backgroundColor: 'rgba(85, 81, 255, 0.1) !important'
                                                } 
                                            }}
                                            onClick={() => setActiveStep(activeStep - 1)}
                                        >
                                            Back
                                        </MDButton>
                                    )
                                }
                                {
                                    activeStep <= 1 && (
                                        <MDButton 
                                            disabled={(!stepOneData.fullName || !stepOneData.email || !stepOneData.phone) || isSaving || emailError !== ''}
                                            sx={{ 
                                                backgroundColor: '#5551FF',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(68, 64, 230, 1)'
                                                },
                                                '&.Mui-disabled': {
                                                    backgroundColor: '#5551FF !important',
                                                }
                                            }}
                                            onClick={() => handleFoward()}
                                        >
                                            {
                                                !isSaving && (
                                                    <Box>
                                                        { activeStep === 0 ? 'Next' : 'Save' }
                                                    </Box>
                                                )
                                                
                                            }

                                            {
                                                isSaving && (
                                                    <CircularProgress size={20} color="inherit" />
                                                )
                                            }
                                        </MDButton>
                                    )
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <Dialog open={showConfirmReset} onClose={() => setShowConfirmReset(false)}>
                <DialogContent sx={{ width: '350px'}}>
                    <Typography variant='body2' fontWeight={'bold'}>Confirm Action</Typography>
                    <Typography variant='body2' sx={{ margin: '1.5rem 0'}}>Clear all forms and start again?</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1}}>
                        <MDButton 
                            variant='transparent' 
                            sx={{ 
                                border: 'none !important', 
                                color: '#5551FF !important',
                                '&:hover': {
                                backgroundColor: 'rgba(85, 81, 255, 0.1) !important'
                                } 
                            }}
                            onClick={() => setShowConfirmReset(false)}
                        >
                            Cancel
                        </MDButton>
                        <MDButton 
                            sx={{ 
                                backgroundColor: '#5551FF',
                                '&:hover': {
                                    backgroundColor: 'rgba(68, 64, 230, 1)'
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: '#5551FF !important',
                                }
                            }}
                            onClick={() => {
                                handleReset();
                                setShowConfirmReset(false);
                            }}
                        >
                            Confirm
                        </MDButton>
                    </Box>
                </DialogContent>
            </Dialog>
        </Dialog>
    );
}

export default TradeShow;