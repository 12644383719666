import useFetchWithMsal from "hooks/useFetch";
import API_ENDPOINT from "../endpoint";
import { ITradeShow } from "./trade-show.model";

function TradeShowService(){
    const {execute} = useFetchWithMsal()

    const API_PATH = `${API_ENDPOINT}/tradeshow`

    function saveData(data: ITradeShow){
        return execute({
            endpoint: `${API_PATH}/`,
            data: data,
            method: "POST"
        })
    }

    return {saveData}
}

export default TradeShowService