// @ts-nocheck
import MDCard from "material-ui/components/MDCard";
import {
    Box,
    Chip,
    FormControl,
    FormControlLabel,
    Grid,
    Alert,
    IconButton,
    InputLabel,
    Radio,
    RadioGroup,
    Select,
    Slider,
    Switch,
    Tooltip,
    Typography
} from "@mui/material";
import React, {MouseEventHandler, useEffect, useState} from "react";
import {GoogleMap, InfoWindow, Libraries, LoadScript, Marker} from "@react-google-maps/api";
import {Coordinate} from "models/coordinate";
import Divider from "@mui/material/Divider";
import MDTypography from "material-ui/components/MDTypography";
import RadiusIcon from "assets/icons/radius";
import {formatNumber, formatPrice} from "helpers/formatters";
import MDIconButton from "material-ui/components/MDIconButton";
import DeleteIcon from "assets/icons/delete";
import {useNavigate, useParams} from "react-router-dom";
import MDAlert from "material-ui/components/MDAlert";
import WarningIcon from "assets/icons/warning";
import {RadiusMail, RadiusMailAddress} from "models/radius-mailing/radiusMail";
import AddSingleAddressModal from "./components/add-single-address-modal";
import MenuItem from "@mui/material/MenuItem";
import MDInput from "material-ui/components/MDInput";
import InfoModal from "components/info-modal";
import RadiusMailService from "services/radius-mail";
import CheckoutModal from "./components/checkout-modal";
import MDButton from "material-ui/components/MDButton";
import {OwnerOrRenterFilter, PropertyTypeFilter} from "models/radius-mailing/filters";
import { OrderStatus } from "models/enums/orderStatus";
import {CSVBoxButton} from "@csvbox/react";
import DocumentIcon from "assets/icons/document";
import { ITemplate } from "models/template";
import StepIndicator from "components/step-indicator";
import Info2Icon from "assets/icons/info-2";
import { useGlobal } from "context/global-context";
import SelectTemplate from "components/select-template";
import { mapContainerStyle, mapOptions } from "helpers/google-map-helper";
import RadioSelectWrapper from "components/radio-select-wrapper";
import { PostageTypeEnum } from "services/smart-automation/smart-automation.types";
import { orderProducUsesLetterUSPSPricing } from "models/enums/ProductTypes";

const loadScriptLibraries: Libraries = ["places"]

const RadiusMailUpsert = () => {
    const {setShowLoader, setTemplateFlow, templateFlow} = useGlobal()
    
    const {postRadiusMail, getRadiusMail, deleteRadiusMailAddress} = RadiusMailService()
    
    const navigate = useNavigate()

    const orderId = parseInt(useParams().id)
    const minimumRecipientCount = 25;

    const [map, setMap] = useState<google.maps.Map>(null)
    const [hasMapLoaded, setHasMapLoaded] = useState<boolean>(false)
    const [geocoder, setGeocoder] = useState(null)

    const [initialCenter, setInitialCenter] = useState<Coordinate>({lat: null, lng: null})
    const [center, setCenter] = useState<Coordinate>({lat: null, lng: null})
    const [zoom, setZoom] = useState<number>(10)

    const [showAddSingleAddressModal, setShowAddSingleAddressModal] = useState<boolean>(false)

    const [showCheckoutModal, setShowCheckoutModal] = useState<boolean>(false)
    const [showSuccessfulCheckoutModal, setShowSuccessfulCheckoutModal] = useState<boolean>(false)

    const [showSubmitErrorModal, setShowSubmitErrorModal] = useState<boolean>(false)
    const [errorModalContent, setErrorModalContent] = useState<React.ReactNode | string>(null)
    const [radiusMailError, setRadiusMailError] = useState<string>()

    const [InfoWindowAddress, setInfoWindowAddress] = useState<RadiusMailAddress>()
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
    const [resendingFilter, setResendingFilter] = useState<boolean>(false)
    const [radiusMail, setRadiusMail] = useState<RadiusMail>({
        id: 0,
        name: null,
        totalStructures: 0,
        pricePerCard: 0,
        canEdit: true,
        status: OrderStatus.Draft,
        addresses: [],
        template: null,
        canDelete: true,
        radiusMailFilters: {
            ownerOrRenterFilter: null,
            propertyTypeFilter: null,
            houseHoldIncomeFilter: null,
            lengthOfResidenceFilter: null,
            yearBuiltFilter: null
        },
        createdDate: null,
        estimatedMailDate: null,
        mailedDate: null,
        resendAfterWeeks: null,
        postageType: PostageTypeEnum.FirstClass
    })
    
    function onMapLoaded() {
        setHasMapLoaded(true)

        setGeocoder(new window.google.maps.Geocoder())

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function (pos: GeolocationPosition) {
                    let lat = pos.coords.latitude
                    let lng = pos.coords.longitude

                    setCenter({lat, lng})
                    setInitialCenter({lat, lng})
                },
                function (error) {
                    setCenter({lat: 33.824386, lng: -118.1358666})
                    setInitialCenter({lat: 33.824386, lng: -118.1358666})
                })
        } else {
            setCenter({lat: 33.824386, lng: -118.1358666})
            setInitialCenter({lat: 33.824386, lng: -118.1358666})
        }
    }

    useEffect(() => {
        if(!isFirstLoad){
            return
        }

        if(orderId){
            if(radiusMail.id && hasMapLoaded){
                setShowLoader(false)
                setIsFirstLoad(false)
            }
            else {
                setShowLoader(true)
            }
        }
        else{
            if (hasMapLoaded) {
                setShowLoader(false)
                setIsFirstLoad(false)
            }
            else {
                setShowLoader(true)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasMapLoaded, radiusMail, orderId, isFirstLoad]);

    useEffect(() => {
        if(hasMapLoaded){
            if(templateFlow.order?.template) {
                setRadiusMail(templateFlow.order)
                selectTemplate(templateFlow.order.template, templateFlow.order)
                setTemplateFlow({})
            } else if(orderId){
                getRadiusMail(orderId).then((response) => {
                    if(response && response.payload){
                        setRadiusMail(response.payload)
                    }
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasMapLoaded])

    const selectTemplate = (template: ITemplate, tempRadiusMail?: RadiusMail) => {
        let postData = {...tempRadiusMail || radiusMail}
        postData.template = template
        setShowLoader(true)
        postRadiusMail(postData).then((response) => {
            setShowLoader(false)
            setRadiusMailError(null);
            
            if (response.errors.length > 0) 
                setRadiusMailError(response.errors[0])
            else {
                setRadiusMail(response.payload)
                navigate("/radius-mailing/order/" + response.payload.id)
            }
        })
    }

    function submit(){
        if(!radiusMail.template){
            setErrorModalContent("Please select a template to be used for this order")
            setShowSubmitErrorModal(true)
            return
        }

        if(!radiusMail.addresses || !radiusMail.addresses.length){
            setErrorModalContent("Please add addresses to your order")
            setShowSubmitErrorModal(true)
            return
        }

        if(radiusMail.radiusMailFilters.ownerOrRenterFilter === null || radiusMail.radiusMailFilters.propertyTypeFilter === null){
            setErrorModalContent("Please select the owner type and property type for your search")
            setShowSubmitErrorModal(true)
            return
        }

        let hasRecipientCountError = false


        let totalStructures = getTotalStructuresValue();

        if (totalStructures < 25) {
            hasRecipientCountError = true;
        }

        if(hasRecipientCountError){
            setErrorModalContent(`The minimum amount of recipients for the search is ${minimumRecipientCount}`)
            setShowSubmitErrorModal(true)
            return
        }

        setShowLoader(true)

        let postData = {...radiusMail};

        for (let e of postData.addresses) {
            if (e.isNew) {
                e.id = 0
            }
        }

        postRadiusMail(postData).then((response) => {
            setShowLoader(false)

            if(response.hasErrors){
                setErrorModalContent(response.errors[0])
                setShowSubmitErrorModal(true)
                return
            }

            setRadiusMail(response.payload)
            setShowCheckoutModal(true)
        })
    }

    function getTotalStructuresValue() {
        return radiusMail.addresses.reduce((sum, address) => sum + (address.structures || 0), 0);
    }

    function hasSomeZeroedStruct() {
        return radiusMail.addresses.some(a => a.structures === 0)
    }

    function getWarnMessage() {
        if(getTotalStructuresValue() < 25 ) {
            return "The total amount of recipients across all addresses cannot be less than 25."
        } else if(hasSomeZeroedStruct()) {
            return "Addresses with 0 recipients are not allowed."
        } else {
            return ''
        }
    }

    function deleteAddress(id: number){
        setShowLoader(true)

        deleteRadiusMailAddress(radiusMail.id, id).then((response) => {
            if(!response.hasErrors){
                setRadiusMail(prevState => {
                    let copy = {...prevState}

                    copy.addresses = copy.addresses.filter(a => a.id !== id)

                    return copy
                })
            }

            setShowLoader(false)
        })
    }

    return <LoadScript googleMapsApiKey="AIzaSyAnZBcPxYjFTJ2p5VykxLnOcqM0gY4uIY0" onLoad={onMapLoaded} libraries={loadScriptLibraries}>
        {hasMapLoaded ?
            <Grid>
                {!radiusMail.canEdit?
                    <MDAlert color={"warning"} dismissible={false} backgroundColorOpacity={0.3} mb={3}>
                        <Grid container justifyContent={"center"} alignItems={"center"}>
                            <Grid item display={"flex"}>
                                <WarningIcon color={"warning"}/>
                            </Grid>

                            <Grid item ml={2}>
                                <MDTypography color={"secondary"} textAlign={"center"} fontWeight={"normal"} sx={{fontSize: 16}}>
                                    This radius mail cannot be edited
                                </MDTypography>
                            </Grid>
                        </Grid>
                    </MDAlert>
                    :
                    null
                }
                
                {radiusMailError ?
                    <MDAlert color={"warning"} dismissible={false} backgroundColorOpacity={0.3} mb={3}>
                        <Grid container justifyContent={"center"} alignItems={"center"}>
                            <Grid item display={"flex"}>
                                <WarningIcon color={"warning"}/>
                            </Grid>

                            <Grid item ml={2}>
                                <MDTypography color={"secondary"} textAlign={"center"} fontWeight={"normal"} sx={{fontSize: 16}}>
                                    {radiusMailError}
                                </MDTypography>
                            </Grid>
                        </Grid>
                    </MDAlert>
                    :
                    null
                }



                <Grid container gap={3} flexWrap={"nowrap"}>
                    <Grid item flex={1} maxWidth={"800px"} minWidth={"600px"}>
                        <MDCard>
                            <Grid p={4} pt={3.5}>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography mb={2} fontSize={24} fontWeight={"bold"} color={"secondary"}>
                                        {orderId ? (radiusMail.canEdit ? "Edit" : "") : "New"} Radius Mail {radiusMail.canEdit ? "" : "Details"}
                                    </Typography>
                                    <Tooltip
                                        componentsProps={{ tooltip: { sx: { width: "300px", maxWidth: "unset" } } }} 
                                        title={"Purchase recipients around addresses and add your filters. Either add addresses one by one or upload a list."}
                                    >
                                        <Box>
                                            <Info2Icon color={"light2"}/>
                                        </Box>
                                    </Tooltip>
                                </Box>
                                <Grid container alignItems={"stretch"} mt={2} gap={3} py={1}>
                                    <StepIndicator stepNumber={1} checked={!!radiusMail.template} />

                                    <Box>
                                        <Divider light={true} orientation={"vertical"}/>
                                    </Box>

                                    <Grid item flex={1}>
                                        <SelectTemplate 
                                            order={radiusMail}
                                            template={radiusMail.template}
                                            onDelete={() => setRadiusMail(prevState => ({...prevState, template: null}))}
                                            onTemplateChange={(template) => selectTemplate(template)}
                                            disabled={!radiusMail.canEdit}
                                            shouldCheckNotSupported
                                            disableEditOption
                                        />
                                    </Grid>
                                </Grid>

                                <Divider light={true}/>

                                <Grid container alignItems={"stretch"} mt={2} gap={3} py={1} sx={{opacity: radiusMail.template? 1 : 0.5, pointerEvents: radiusMail.template? "all" : "none"}}>
                                    <StepIndicator stepNumber={2} checked={!!radiusMail.addresses.length} />

                                    <Box>
                                        <Divider light={true} orientation={"vertical"}/>
                                    </Box>

                                    <Grid item flex={1}>
                                        <Grid container alignItems={"center"} gap={1}>
                                            <Grid item>
                                                <MDButton
                                                    color="primary"
                                                    onClick={() => {
                                                        setShowAddSingleAddressModal(true)
                                                    }}
                                                    disabled={!radiusMail.canEdit}
                                                >
                                                    <Grid container alignItems={"center"} justifyContent={"center"} gap={1}>
                                                        <Grid item display={"flex"}>
                                                            <RadiusIcon/>
                                                        </Grid>
                                                        <Grid item>
                                                            Add Address
                                                        </Grid>
                                                    </Grid>
                                                </MDButton>
                                            </Grid>

                                            <Grid item>
                                                {radiusMail?.id?
                                                    <CSVBoxButton
                                                        licenseKey={process.env.REACT_APP_CSVBOX_RADIUS_MAIL}
                                                        user={{
                                                            user_id: "LettrLabsCsvBoxAdmin",
                                                            order_id: radiusMail.id,
                                                            radius_mail_order_id: radiusMail.id
                                                        }}
                                                        onImport={async function (
                                                            result: boolean,
                                                            data: {
                                                                row_success: string
                                                                rows: any[]
                                                                raw_file: string
                                                                row_count: number
                                                            }
                                                        ) {
                                                            if (!result) {
                                                                alert(
                                                                    "Upload failed. Please try again."
                                                                )

                                                                return
                                                            }
                                                            setShowLoader(true)
                                                            getRadiusMail(radiusMail.id).then((response) => {
                                                                setRadiusMail(response.payload)
                                                                setShowLoader(false);
                                                            })
                                                        }
                                                        }
                                                        render={(
                                                            launch: MouseEventHandler<HTMLButtonElement>,
                                                            isLoading: boolean
                                                        ) => {
                                                            return (
                                                                <MDButton
                                                                    color="primary"
                                                                    onClick={launch}
                                                                    disabled={!radiusMail.canEdit}
                                                                >
                                                                    <Grid container alignItems={"center"} justifyContent={"center"} gap={1}>
                                                                        <Grid item display={"flex"}>
                                                                            <DocumentIcon/>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            Upload
                                                                        </Grid>
                                                                    </Grid>
                                                                </MDButton>
                                                            )
                                                        }}
                                                    />
                                                    :
                                                    <MDButton
                                                        color="primary"
                                                        disabled={!radiusMail.canEdit}
                                                    >
                                                        <Grid container alignItems={"center"} justifyContent={"center"} gap={1}>
                                                            <Grid item display={"flex"}>
                                                                <DocumentIcon/>
                                                            </Grid>
                                                            <Grid item>
                                                                Upload
                                                            </Grid>
                                                        </Grid>
                                                    </MDButton>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Divider light={true}/>

                                <Grid container alignItems={"stretch"} mt={2} gap={3} py={1} sx={{opacity: radiusMail.template? 1 : 0.5, pointerEvents: radiusMail.template? "all" : "none"}}>
                                    <StepIndicator stepNumber={3} checked={radiusMail.radiusMailFilters.propertyTypeFilter !== null && radiusMail.radiusMailFilters.ownerOrRenterFilter !== null} />

                                    <Grid item>
                                        <Divider light={true} orientation={"vertical"}/>
                                    </Grid>

                                    <Grid item flex={1}>
                                        <MDTypography sx={{fontSize: 24}} fontWeight={"bold"}>Filters</MDTypography>

                                        <Box display={"flex"} mt={3} gap={1}>
                                            <FormControl fullWidth={true}>
                                                <InputLabel htmlFor="owner-type-select" shrink={true}>Owner Type</InputLabel>
                                                <Select
                                                    fullWidth={true}
                                                    id={"owner-type-select"}
                                                    label={"Owner Type"}
                                                    value={radiusMail.radiusMailFilters.ownerOrRenterFilter ?? ""}
                                                    displayEmpty={true}
                                                    disabled={!radiusMail.canEdit}
                                                    onChange={(event) => {
                                                        let value = event.target.value

                                                        let newRadiusMail = {
                                                            ...radiusMail,
                                                            status: OrderStatus.Draft,
                                                            radiusMailFilters: {
                                                                ...radiusMail.radiusMailFilters,
                                                                ownerOrRenterFilter: value === null? null : value as OwnerOrRenterFilter
                                                            }
                                                        }

                                                        setRadiusMail(newRadiusMail)

                                                        postRadiusMail(newRadiusMail)
                                                    }}
                                                >
                                                    <MenuItem value={""} disabled={true}>Select an option</MenuItem>
                                                    <MenuItem value={OwnerOrRenterFilter.Owner}>Owner</MenuItem>
                                                    <MenuItem value={OwnerOrRenterFilter.Renter}>Renter</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth={true}>
                                                <InputLabel htmlFor="property-type-select" shrink={true}>Property Type</InputLabel>
                                                <Select
                                                    fullWidth={true}
                                                    id={"property-type-select"}
                                                    label={"Property Type"}
                                                    value={radiusMail.radiusMailFilters.propertyTypeFilter ?? ""}
                                                    displayEmpty={true}
                                                    disabled={!radiusMail.canEdit}
                                                    onChange={(event) => {
                                                        let value = event.target.value

                                                        let newRadiusMail = {
                                                            ...radiusMail,
                                                            status: OrderStatus.Draft,
                                                            radiusMailFilters: {
                                                                ...radiusMail.radiusMailFilters,
                                                                propertyTypeFilter: value === null? null : value as PropertyTypeFilter
                                                            }
                                                        }

                                                        setRadiusMail(newRadiusMail)

                                                        postRadiusMail(newRadiusMail)
                                                    }}
                                                >
                                                    <MenuItem value={""} disabled={true}>Select an option</MenuItem>
                                                    <MenuItem value={PropertyTypeFilter["Single Family Dwelling Unit"]}>Single Family Dwelling Unit</MenuItem>
                                                    <MenuItem value={PropertyTypeFilter["Multi Family Dwelling Unit"]}>Multi Family Dwelling Unit</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>

                                        <Box mt={4} pr={2}>
                                            <Typography sx={{fontSize: 18}} fontWeight={"bold"} color={"secondary"}>Household Income</Typography>

                                            <Box pl={1} mt={1}>
                                                <Slider
                                                    min={0}
                                                    max={250000}
                                                    value={[
                                                        radiusMail.radiusMailFilters.houseHoldIncomeFilter ? radiusMail.radiusMailFilters.houseHoldIncomeFilter.min ?? 0 : 0,
                                                        radiusMail.radiusMailFilters.houseHoldIncomeFilter ? radiusMail.radiusMailFilters.houseHoldIncomeFilter.max ?? 250000 : 250000
                                                    ]}
                                                    valueLabelDisplay="auto"
                                                    valueLabelFormat={(str) => {
                                                        return `$${formatNumber(str)}`
                                                    }}
                                                    disabled={!radiusMail.canEdit}
                                                    step={10000}
                                                    disableSwap={true}
                                                    onChange={(e, value, activeThumb) => {
                                                        value = value as number[]

                                                        let min = value[0];
                                                        let max = value[1];

                                                        const minDistance = 10000

                                                        let oldMin = radiusMail.radiusMailFilters.houseHoldIncomeFilter?.min?? 0
                                                        let oldMax = radiusMail.radiusMailFilters.houseHoldIncomeFilter?.max?? 250000

                                                        if (activeThumb === 0) {
                                                            min = Math.min(min, oldMax - minDistance)
                                                            max = oldMax
                                                        }
                                                        else {
                                                            min = oldMin
                                                            max = Math.max(max, oldMin + minDistance)
                                                        }

                                                        min = min === 0 ? null : min
                                                        max = max === 250000 ? null : max

                                                        setRadiusMail(prevState => {
                                                            return {
                                                                ...prevState,
                                                                status: OrderStatus.Draft,
                                                                radiusMailFilters: {
                                                                    ...prevState.radiusMailFilters,
                                                                    houseHoldIncomeFilter: (min !== null || max !== null) ? {
                                                                        min: min,
                                                                        max: max
                                                                    } : null
                                                                }
                                                            }
                                                        })
                                                    }}
                                                    onChangeCommitted={(e, value) => {
                                                        postRadiusMail(radiusMail)
                                                    }}
                                                    marks={[
                                                        {value: 0, label: "$0"},
                                                        {value: 50000, label: "$50,000"},
                                                        {value: 100000, label: "$100,000"},
                                                        {value: 150000, label: "$150,000"},
                                                        {value: 200000, label: "$200,000"},
                                                        {value: 250000, label: "$250,000+"},
                                                    ]}
                                                />
                                            </Box>
                                        </Box>

                                        <Box mt={2} pr={2}>
                                            <Typography sx={{fontSize: 18}} fontWeight={"bold"} color={"secondary"}>Length of Residency</Typography>

                                            <Box pl={1} mt={1}>
                                                <Slider
                                                    min={0}
                                                    max={15}
                                                    value={[
                                                        radiusMail.radiusMailFilters.lengthOfResidenceFilter ? radiusMail.radiusMailFilters.lengthOfResidenceFilter.min ?? 0 : 0,
                                                        radiusMail.radiusMailFilters.lengthOfResidenceFilter ? radiusMail.radiusMailFilters.lengthOfResidenceFilter.max ?? 15 : 15
                                                    ]}
                                                    valueLabelDisplay="auto"
                                                    disabled={!radiusMail.canEdit}
                                                    step={1}
                                                    disableSwap={true}
                                                    onChange={(e, value, activeThumb) => {
                                                        value = value as number[]

                                                        let min = value[0];
                                                        let max = value[1];

                                                        const minDistance = 1

                                                        let oldMin = radiusMail.radiusMailFilters.lengthOfResidenceFilter?.min?? 0
                                                        let oldMax = radiusMail.radiusMailFilters.lengthOfResidenceFilter?.max?? 15

                                                        if (activeThumb === 0) {
                                                            min = Math.min(min, oldMax - minDistance)
                                                            max = oldMax
                                                        }
                                                        else {
                                                            min = oldMin
                                                            max = Math.max(max, oldMin + minDistance)
                                                        }

                                                        min = min === 0 ? null : min
                                                        max = max === 15 ? null : max

                                                        setRadiusMail((prevState) => {
                                                            return {
                                                                ...radiusMail,
                                                                status: OrderStatus.Draft,
                                                                radiusMailFilters: {
                                                                    ...radiusMail.radiusMailFilters,
                                                                    lengthOfResidenceFilter: (min !== null || max !== null) ? {
                                                                        min: min,
                                                                        max: max
                                                                    } : null
                                                                }
                                                            }
                                                        })
                                                    }}
                                                    onChangeCommitted={(e, value) => {
                                                        postRadiusMail(radiusMail)
                                                    }}
                                                    marks={[
                                                        {value: 0, label: "0"},
                                                        {value: 1, label: "1"},
                                                        {value: 2, label: "2"},
                                                        {value: 3, label: "3"},
                                                        {value: 4, label: "4"},
                                                        {value: 5, label: "5"},
                                                        {value: 6, label: "6"},
                                                        {value: 7, label: "7"},
                                                        {value: 8, label: "8"},
                                                        {value: 9, label: "9"},
                                                        {value: 10, label: "10"},
                                                        {value: 11, label: "11"},
                                                        {value: 12, label: "12"},
                                                        {value: 13, label: "13"},
                                                        {value: 14, label: "14"},
                                                        {value: 15, label: "15+"}
                                                    ]}
                                                />
                                            </Box>
                                        </Box>

                                        <Box mt={2} pr={2}>
                                            <Typography sx={{fontSize: 18}} fontWeight={"bold"} color={"secondary"}>Year Built</Typography>

                                            <Box pl={1} mt={1}>
                                                <Slider
                                                    min={1890}
                                                    max={2010}
                                                    value={[
                                                        radiusMail.radiusMailFilters.yearBuiltFilter ? radiusMail.radiusMailFilters.yearBuiltFilter.min ?? 1890 : 1890,
                                                        radiusMail.radiusMailFilters.yearBuiltFilter ? radiusMail.radiusMailFilters.yearBuiltFilter.max ?? 2010 : 2010
                                                    ]}
                                                    valueLabelDisplay="auto"
                                                    disabled={!radiusMail.canEdit}
                                                    step={10}
                                                    onChange={(e, value, activeThumb) => {
                                                        value = value as number[]

                                                        let min = value[0];
                                                        let max = value[1];

                                                        const minDistance = 10

                                                        let oldMin = radiusMail.radiusMailFilters.yearBuiltFilter?.min?? 1890
                                                        let oldMax = radiusMail.radiusMailFilters.yearBuiltFilter?.max?? 2010

                                                        if (activeThumb === 0) {
                                                            min = Math.min(min, oldMax - minDistance)
                                                            max = oldMax
                                                        }
                                                        else {
                                                            min = oldMin
                                                            max = Math.max(max, oldMin + minDistance)
                                                        }

                                                        setRadiusMail((prevState) => {
                                                            return {
                                                                ...radiusMail,
                                                                status: OrderStatus.Draft,
                                                                radiusMailFilters: {
                                                                    ...radiusMail.radiusMailFilters,
                                                                    yearBuiltFilter: (min !== null || max !== null) ? {
                                                                        min: min,
                                                                        max: max
                                                                    } : null
                                                                }
                                                            }
                                                        })
                                                    }}
                                                    onChangeCommitted={(e, value) => {
                                                        postRadiusMail(radiusMail)
                                                    }}
                                                    marks={[
                                                        {value: 1890, label: "< 1900"},
                                                        {value: 1900, label: "1900"},
                                                        {value: 1910, label: "1910"},
                                                        {value: 1920, label: "1920"},
                                                        {value: 1930, label: "1930"},
                                                        {value: 1940, label: "1940"},
                                                        {value: 1950, label: "1950"},
                                                        {value: 1960, label: "1960"},
                                                        {value: 1970, label: "1970"},
                                                        {value: 1980, label: "1980"},
                                                        {value: 1990, label: "1990"},
                                                        {value: 2000, label: "2000"},
                                                        {value: 2010, label: "> 2000"},
                                                    ]}
                                                />
                                            </Box>
                                        </Box>

                                        <Grid container gap={1} alignItems={"center"} mb={1.5} mt={3} width={"fit-content"} sx={{cursor: "pointer"}} onClick={() => {
                                            if(!radiusMail.canEdit) {
                                                return
                                            }

                                            let newState = !resendingFilter

                                            setRadiusMail(prevState => {
                                                return {...prevState, resendAfterWeeks: newState? 1 : null}
                                            })

                                            setResendingFilter(newState)
                                        }}>
                                            <Grid item>
                                                <MDTypography sx={{fontSize: 14}} fontWeight={"bold"}>
                                                    Re-Sending Settings
                                                </MDTypography>
                                            </Grid>

                                            <Grid item display={"flex"}>
                                                <Switch size={"small"} checked={resendingFilter} disabled={!radiusMail.canEdit}/>
                                            </Grid>

                                            <Grid item>
                                                <Tooltip title={"After how many weeks we can send cards to the same recipients"}>
                                                    <Box display={"flex"}>
                                                        <Info2Icon color={"light2"}/>
                                                    </Box>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>

                                        <Grid container alignItems={"center"} gap={3}>
                                            <Grid item flex={1}>
                                                <Select
                                                    disabled={!resendingFilter || !radiusMail.canEdit}
                                                    fullWidth={true}
                                                    value={radiusMail.resendAfterWeeks}
                                                    displayEmpty={true}
                                                    onChange={(event: any) => {
                                                        let val: number = null
                                                        if (event.target.value) {
                                                            val = parseInt(event.target.value)
                                                        }

                                                        setRadiusMail(prevState => {
                                                            return {...prevState, resendAfterWeeks: val}
                                                        })
                                                    }}
                                                >
                                                    {Array.from({ length: 12 }, (_, i) => i + 1).map(vl =>
                                                        <MenuItem key={vl} value={vl}>
                                                            {vl} {vl > 1 ? 'weeks' : 'week'}
                                                        </MenuItem>
                                                    )}
                                                </Select>
                                            </Grid>

                                            <Grid item flex={1}>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Divider light={true}/>

                                <Grid container alignItems={"stretch"} mt={2} gap={3} py={1} sx={{opacity: radiusMail.template? 1 : 0.5, pointerEvents: radiusMail.template? "all" : "none"}}>
                                    <StepIndicator stepNumber={4} checked />

                                    <Grid item>
                                        <Divider light={true} orientation={"vertical"}/>
                                    </Grid>

                                    <Grid item flex={1}>
                                    <MDTypography sx={{fontSize: 24, mb: 2}} fontWeight={"bold"}>Sending Options</MDTypography>
                                        <FormControl fullWidth disabled={!radiusMail.canEdit}>
                                            <RadioGroup
                                            value={radiusMail.postageType}
                                            onChange={(e) => setRadiusMail((prev) => ({...prev, postageType: Number(e.target.value)}))}
                                            >
                                            <RadioSelectWrapper selected={radiusMail.postageType === PostageTypeEnum.FirstClass}>
                                                <FormControlLabel
                                                control={<Radio />}
                                                value={PostageTypeEnum.FirstClass}
                                                label={
                                                    <Box display={'flex'} alignItems={'center'} gap={1}>
                                                        <Typography variant="h5">First Class Postage</Typography>
                                                        <Typography variant="h5" fontWeight={'normal'}>
                                                            (3-5 day USPS estimated delivery)
                                                        </Typography>
                                                        <Chip
                                                            label={
                                                                radiusMail?.template?.postageTypePrices?.FirstClass
                                                                    ? `+$${radiusMail?.template?.postageTypePrices?.FirstClass?.toFixed(2)}`
                                                                    : orderProducUsesLetterUSPSPricing(radiusMail?.template?.product)
                                                                        ? "+$0.15"
                                                                        : "+$0.05"
                                                            }
                                                        />
                                                    </Box>
                                                }
                                                />
                                            </RadioSelectWrapper>
                                            <Box mt={1} />
                                            <RadioSelectWrapper selected={radiusMail.postageType === PostageTypeEnum.Standard}>
                                                <FormControlLabel
                                                control={<Radio />}
                                                value={PostageTypeEnum.Standard}
                                                label={
                                                    <Box display={'flex'} alignItems={'center'} gap={1}>
                                                    <Typography variant="h5">Standard Postage</Typography>
                                                    <Typography variant="h5" fontWeight={'normal'}>
                                                        (10-14 day USPS estimated delivery)
                                                    </Typography>
                                                    </Box>
                                                }
                                                />
                                            </RadioSelectWrapper>
                                            </RadioGroup>
                                        </FormControl>  
                                    </Grid>
                                </Grid>
                            </Grid>
                        </MDCard>
                    </Grid>

                    <Grid item flex={1} minWidth={"600px"}>
                        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{height: "600px"}}>
                            <GoogleMap
                                onCenterChanged={() => {
                                    let newCenter = map.getCenter()

                                    setCenter({lng: newCenter.lng(), lat: newCenter.lat()})
                                }}
                                onLoad={(map) => {
                                    setMap(map)
                                }}
                                mapContainerStyle={mapContainerStyle}
                                center={initialCenter}
                                zoom={zoom}
                                onZoomChanged={() => {
                                    if (map) {
                                        setZoom(map.getZoom())
                                    }
                                }}
                                options={mapOptions}
                            >
                                {radiusMail.addresses.map((address, index) => {
                                    return <Marker
                                        key={index}
                                        position={{
                                            lat: address.coordinate.lat, 
                                            lng: address.coordinate.lng
                                        }}
                                        onClick={() => setInfoWindowAddress(address)}
                                    />
                                })}
                                {InfoWindowAddress && <InfoWindow 
                                    onCloseClick={() => setInfoWindowAddress(undefined)}
                                    position={{
                                        lat: InfoWindowAddress.coordinate.lat,
                                        lng: InfoWindowAddress.coordinate.lng
                                    }}
                                    options={{ pixelOffset: new window.google.maps.Size(0, -25) }}
                                >
                                    <Box display="flex" alignItems="center">
                                        <Typography>
                                            {InfoWindowAddress.address}
                                        </Typography>
                                        {radiusMail.canEdit &&
                                            <IconButton 
                                                color="error" 
                                                size="small" 
                                                sx={{marginLeft: 1}} 
                                                onClick={() => {
                                                    setInfoWindowAddress(undefined)
                                                    deleteAddress(InfoWindowAddress.id)
                                                }}>
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        }   
                                    </Box>
                                </InfoWindow>}
                            </GoogleMap>
                        </Box>

                        <Box mt={2}>
                            <MDCard>
                                <Box p={4}>
                                    <MDTypography sx={{fontSize: 24}} fontWeight={"bold"} color={"secondary"}>Service Area</MDTypography>

                                    <Box mt={3}>
                                        <Grid container>
                                            <Grid item flex={1}>
                                                <MDTypography fontWeight={"bold"} sx={{fontSize: 17}} color={"secondary"}>
                                                    Address
                                                </MDTypography>
                                            </Grid>

                                            <Grid item flex={1}>
                                                <MDTypography fontWeight={"bold"} sx={{fontSize: 17}} align={"center"} color={"secondary"}>
                                                    Recipients
                                                </MDTypography>
                                            </Grid>

                                            <Grid item flex={1}>
                                                <MDTypography fontWeight={"bold"} sx={{fontSize: 17}} align={"center"} color={"secondary"}>
                                                    Price per Card
                                                </MDTypography>
                                            </Grid>

                                            <Grid item flex={1}>
                                                <MDTypography fontWeight={"bold"} sx={{fontSize: 17}} align={"center"} color={"secondary"}>
                                                    Total
                                                </MDTypography>
                                            </Grid>

                                            <Grid item flex={1}></Grid>
                                        </Grid>

                                        {radiusMail.addresses.map((address, index) => {
                                            return <Grid container mt={1} alignItems={"center"} key={index}>
                                                <Grid item flex={1}>
                                                    <MDTypography sx={{fontSize: 17}}>{address.address}</MDTypography>
                                                </Grid>

                                                <Grid item flex={1} textAlign={"center"}>
                                                    <MDInput
                                                        sx={{
                                                            width: "100px",
                                                        }}
                                                        inputProps={{
                                                            sx: {
                                                                textAlign: "center"
                                                            }
                                                        }}
                                                        size={"small"}
                                                        value={address.structures}
                                                        disabled={!radiusMail.canEdit}
                                                        onChange={(e: any) => {
                                                            if(isNaN(e.target.value)) {
                                                                return
                                                            }

                                                            let val = e.target.value

                                                            if(val) {
                                                                val = parseInt(e.target.value)
                                                            }
                                                            else {
                                                                val = 0
                                                            }

                                                            setRadiusMail((prevState: RadiusMail) => {
                                                                let newEntry = {...prevState}

                                                                for (let a of newEntry.addresses) {
                                                                    if (a.id === address.id) {
                                                                        a.structures = parseInt(val)
                                                                        break
                                                                    }
                                                                }

                                                                return newEntry
                                                            })
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item flex={1} alignItems={"center"}>
                                                    <MDTypography sx={{fontSize: 17}} align={"center"}>{formatPrice(radiusMail.pricePerCard)}</MDTypography>
                                                </Grid>

                                                <Grid item flex={1}>
                                                    <MDTypography sx={{fontSize: 17}} align={"center"}>{formatPrice(address.structures * radiusMail.pricePerCard)}</MDTypography>
                                                </Grid>

                                                <Grid item flex={1}>
                                                    <Grid container alignItems={"center"} gap={1} justifyContent={"flex-end"}>
                                                        <Grid item>
                                                            <Tooltip title={"See on map"}>
                                                                <MDIconButton backgroundColor={"light"} sx={{
                                                                    padding: 0,
                                                                    width: "42px",
                                                                    minWidth: "unset"
                                                                }} onClick={() => {
                                                                    map.setCenter({
                                                                        lat: address.coordinate.lat,
                                                                        lng: address.coordinate.lng
                                                                    })
                                                                    setZoom(12)
                                                                    setInfoWindowAddress(address)
                                                                    window.scrollTo({ top: 0, behavior: 'smooth' });                                                            
                                                                }}>
                                                                    <RadiusIcon color={"primary"}/>
                                                                </MDIconButton>
                                                            </Tooltip>
                                                        </Grid>

                                                        {radiusMail.canEdit?
                                                            <Grid item>
                                                                <Tooltip title={"Delete"}>
                                                                    <MDIconButton backgroundColor={"light"} sx={{
                                                                        padding: 0,
                                                                        width: "42px",
                                                                        minWidth: "unset"
                                                                    }} onClick={() => {deleteAddress(address.id)}}>
                                                                        <DeleteIcon color={"error"}/>
                                                                    </MDIconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                            :
                                                            null
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        })}
                                    </Box>

                                    <Divider light={true}/>

                                    <Box>
                                        {
                                            (hasSomeZeroedStruct() || getTotalStructuresValue() < 25) && (
                                                <Alert severity="error" sx={{ marginBottom: '1rem' }}>
                                                        { getWarnMessage() }
                                                </Alert>
                                            )
                                        }
                                        <MDButton
                                            fullWidth={true}
                                            onClick={submit}
                                            disabled={!radiusMail.canEdit || (hasSomeZeroedStruct() || getTotalStructuresValue() < 25)}
                                        >
                                            Checkout
                                        </MDButton>
                                    </Box>
                                </Box>
                            </MDCard>
                        </Box>
                    </Grid>
                </Grid>

                <AddSingleAddressModal
                    show={showAddSingleAddressModal}
                    setShow={setShowAddSingleAddressModal}
                    radiusMail={radiusMail}
                    setRadiusMail={setRadiusMail}
                    geocoder={geocoder}
                    showCenter={center}
                    showZoom={zoom}
                    mainPageMap={map}
                />

                <InfoModal
                    show={showSubmitErrorModal}
                    setShow={setShowSubmitErrorModal}
                    headerText={errorModalContent}
                    showCancelButton={false}
                    showConfirmButton={true}
                    confirmButtonOnClick={() => {
                        setShowSubmitErrorModal(false)
                    }}
                />

                <CheckoutModal
                    show={showCheckoutModal}
                    setShow={setShowCheckoutModal}
                    radiusMail={radiusMail}
                    setRadiusMail={setRadiusMail}
                    showSuccessfulCheckoutModal={showSuccessfulCheckoutModal}
                    setShowSuccessfulCheckoutModal={setShowSuccessfulCheckoutModal}
                />

                <InfoModal
                    show={showSuccessfulCheckoutModal}
                    setShow={setShowSuccessfulCheckoutModal}
                    headerText={"Thank you for placing your order. Look out for an email confirmation shortly."}
                    showCancelButton={false}
                    showConfirmButton={true}
                    confirmButtonOnClick={() => {
                        setShowSuccessfulCheckoutModal(false)
                        navigate('/radius-mailing')
                    }}
                />
            </Grid>
            :
            null
        }
    </LoadScript>
}

export default RadiusMailUpsert