import React, { ChangeEvent, useState, createContext, useContext, useEffect, useCallback } from 'react';

import { SelectChangeEvent } from '@mui/material';
import { debounce } from 'lodash';
import { IShopifyDataSourceFilter, IShopifySourceViewModel, IUserCreatedShopifyFilter } from 'services/smart-automation/smart-automation.types';

interface IFiltersProviderProps {
  vmData: IShopifySourceViewModel;
  children?: React.ReactNode;
}

interface IFiltersContext {
  shopifyIntegrationOrderFilters: { integrationOrderFilters: IUserCreatedShopifyFilter[] }[];
  filterOptions: IShopifyDataSourceFilter[];
  handleAddFilter: () => void;
  handleAddAnd: (parentIndex: number) => void;
  handleAddOr: () => void;
  handleRemoveFilter: (parentIndex: number, groupIndex: number) => void;
  handleColumnChange: (parentIndex: number, groupIndex: number, event: SelectChangeEvent<number>) => void;
  handleOperatorChange: (parentIndex: number, groupIndex: number, event: SelectChangeEvent<number>) => void;
  handleValueChange: (parentIndex: number, groupIndex: number, event: ChangeEvent<HTMLInputElement>, aggregateIndex?: number) => void;
  handleAggregateColumnChange: (parentIndex: number, groupIndex: number, aggregateIndex: number, idx: number, event: SelectChangeEvent<number>) => void;
  handleAggregateValueChange: (parentIndex: number, groupIndex: number, aggregateIndex: number, idx: number, event: ChangeEvent<HTMLInputElement>) => void;
  handleAggregateOperatorChange: (parentIndex: number, groupIndex: number, aggregateIndex: number, idx: number, event: SelectChangeEvent<number>) => void;
  handleRemoveAggregate: (parentIndex: number, groupIndex: number, aggregateIndex: number, idx: number) => void;
  setShopifyIntegrationOrderFilters: (data: { integrationOrderFilters: IUserCreatedShopifyFilter[] }[]) => void;
  handleAddAggregateAnd: (parentIndex: number, groupIndex: number, aggregateIndex: number) => void;
  handleAddAggregateOr: (parentIndex: number, groupIndex: number) => void
}

const FiltersContext = createContext<IFiltersContext | undefined>(undefined);

const FiltersProvider: React.FC<IFiltersProviderProps> = ({ vmData, children }) => {
  const [shopifyIntegrationOrderFilters, setShopifyIntegrationOrderFilters] = useState<{ integrationOrderFilters: IUserCreatedShopifyFilter[] }[]>([]);
  const [filterOptions, setFilterOptions] = useState<IShopifyDataSourceFilter[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleAddFilter = useCallback(() => {
    setShopifyIntegrationOrderFilters((prev) => [
      ...prev,
      { integrationOrderFilters: [{ dataSourceFilter: undefined, operator: undefined, value: '' }] },
    ]);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleAddAnd = useCallback((parentIndex: number) => {
    setShopifyIntegrationOrderFilters((prev) =>
      prev.map((group, idx) =>
        idx === parentIndex
          ? {
              ...group,
              integrationOrderFilters: [
                ...group.integrationOrderFilters,
                { dataSourceFilter: undefined, operator: undefined, value: '' },
              ],
            }
          : group
      )
    );
  }, []);
  
  const handleAddOr = () => {
    handleAddFilter();
  };

  const handleRemoveFilter = (parentIndex: number, groupIndex: number) => {
    setShopifyIntegrationOrderFilters((prev) => {
      const updatedState = prev
        .map((filterGroup, idx) => {
          if (idx === parentIndex) {
            const updatedFilters = filterGroup.integrationOrderFilters.filter((_, i) => i !== groupIndex);
            return { ...filterGroup, integrationOrderFilters: updatedFilters };
          }
          return filterGroup;
        })
        .filter(group => group.integrationOrderFilters.length > 0);
  
        sessionStorage.setItem('tmpIntegrationOrderFilters', JSON.stringify(updatedState));
  
      return updatedState;
    });
  };
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleColumnChange = useCallback(
    debounce((groupIndex: number, filterIndex: number, event: SelectChangeEvent<number>) => {
      const SINGLE_PURCHASE_CUSTOMER_OPTION = 'SinglePurchaseCustomer';
      const IS_WITHIN_LAST_DAYS = 'IsWithinLastDays';
      const IS_PRIOR_LAST_DAYS = 'IsPriorToLastDays';
  
      const selectedFilter = vmData.filterOptions.find(
        (filter) => filter.id === Number(event.target.value)
      );

      const fixedDateFilter = vmData.filterOptions.find((filter) => filter.name === 'FixedDate');
  
      if (!selectedFilter) {
        console.warn('Selected filter not found!');
        return;
      }
  
      setShopifyIntegrationOrderFilters((prev) =>
        prev.map((group, idx) =>
          idx === groupIndex
            ? {
                ...group,
                integrationOrderFilters: group.integrationOrderFilters.map((filter, i) => {
                  if (i === filterIndex) {
                    if (
                      selectedFilter.name === SINGLE_PURCHASE_CUSTOMER_OPTION &&
                      selectedFilter.isAggregateFilter
                    ) {
                      return {
                        ...filter,
                        dataSourceFilter: {
                          ...selectedFilter,
                          aggregateFilters: [
                            {
                              integrationOrderFilters: [
                                {
                                  dataSourceFilter: fixedDateFilter || undefined,
                                  operator: undefined,
                                  value: '',
                                },
                              ],
                            },
                          ],
                        },
                        operator: {
                          id: 1,
                          operator: "Equals",
                          operatorShortName: "is equal to",
                          showUi: true,
                        },
                        value: '1',
                      };
                    } else if (selectedFilter.name === IS_PRIOR_LAST_DAYS || selectedFilter.name === IS_WITHIN_LAST_DAYS) {
                      return {
                        ...filter,
                        dataSourceFilter: selectedFilter,
                        operator: {
                          id: 1,
                          operator: "Equals",
                          operatorShortName: "is equal to",
                          showUi: true,
                        },
                        value: '',
                      };
                    } else if (selectedFilter.isAggregateFilter && selectedFilter.name === SINGLE_PURCHASE_CUSTOMER_OPTION) {
                      return {
                        ...filter,
                        dataSourceFilter: selectedFilter,
                        operator: {
                          id: 1,
                          operator: "Equals",
                          operatorShortName: "is equal to",
                          showUi: true,
                        },
                        value: '1',
                      };
                    } else if (selectedFilter.isAggregateFilter) {
                      return {
                        ...filter,
                        dataSourceFilter: {
                          ...selectedFilter,
                          aggregateFilters: [
                            {
                              integrationOrderFilters: [
                                {
                                  dataSourceFilter: fixedDateFilter || undefined,
                                  operator: undefined,
                                  value: '',
                                },
                              ],
                            },
                          ],
                        },
                        operator: undefined,
                        value: '',
                      };
                    }
  
                    return {
                      ...filter,
                      dataSourceFilter: selectedFilter,
                      operator: undefined,
                      value: '',
                    };
                  }
                  return filter;
                }),
              }
            : group
        )
      );
    }, 300),
    [vmData]
  );
  
  const handleOperatorChange = (groupIndex: number, filterIndex: number, event: SelectChangeEvent<number>) => {
    setShopifyIntegrationOrderFilters((prev) =>
      prev.map((group, idx) => {
        if (idx === groupIndex) {
          return {
            ...group,
            integrationOrderFilters: group.integrationOrderFilters.map((filter, i) => {
              if (i === filterIndex) {
                const selectedOperator = filter?.dataSourceFilter?.operatorOptions.find(
                  (op) => op.id === Number(event.target.value)
                );

                return {
                  ...filter,
                  operator: selectedOperator || undefined,
                  value: selectedOperator?.operator === 'AllTime' || selectedOperator?.operator === 'IsNotNull' ? '' : filter.value,
                };
              }
              return filter;
            }),
          };
        }
        return group;
      })
    );
  };

  
  const handleValueChange = (parentIndex: number, groupIndex: number, event: ChangeEvent<HTMLInputElement>, aggregateIndex?: number) => {
    setShopifyIntegrationOrderFilters((prev) =>
      prev.map((filterGroup, idx) => {
        if (idx === parentIndex) {
          const updatedFilters = filterGroup.integrationOrderFilters.map((filter, i) => {
            if (i === groupIndex) {
              if (aggregateIndex !== undefined && filter.dataSourceFilter?.aggregateFilters) {
                const updatedAggregates = filter.dataSourceFilter.aggregateFilters.map((aggFilter, aggIdx) =>
                  aggIdx === aggregateIndex ? { ...aggFilter, value: event.target.value } : aggFilter
                );
                return {
                  ...filter,
                  dataSourceFilter: {
                    ...filter.dataSourceFilter,
                    aggregateFilters: updatedAggregates,
                  },
                };
              }
              return { ...filter, value: event.target.value };
            }
            return filter;
          });
          return { ...filterGroup, integrationOrderFilters: updatedFilters };
        }
        return filterGroup;
      })
    );
  };

  const handleRemoveAggregate = (
    parentIndex: number,
    groupIndex: number,
    aggregateIndex: number,
    idx: number
  ) => {
    setShopifyIntegrationOrderFilters((prev) =>
      prev.map((filterGroup, groupIdx) => {
        if (groupIdx === parentIndex) {
          return {
            ...filterGroup,
            integrationOrderFilters: filterGroup.integrationOrderFilters.map((filter, filterIdx) => {
              if (filterIdx === groupIndex && filter.dataSourceFilter?.aggregateFilters) {
                const updatedAggregates = filter.dataSourceFilter.aggregateFilters
                  .map((aggGroup, aggIdx) => {
                    if (aggIdx === aggregateIndex) {
                      const updatedIntegrationOrderFilters = aggGroup.integrationOrderFilters.filter(
                        (_, fIdx) => fIdx !== idx
                      );
  
                      return updatedIntegrationOrderFilters.length > 0
                        ? { ...aggGroup, integrationOrderFilters: updatedIntegrationOrderFilters }
                        : undefined;
                    }
                    return aggGroup;
                  })
                  .filter((aggGroup): aggGroup is { integrationOrderFilters: IUserCreatedShopifyFilter[] } =>
                    aggGroup !== undefined
                  );
  
                return {
                  ...filter,
                  dataSourceFilter: {
                    ...filter.dataSourceFilter,
                    aggregateFilters: updatedAggregates,
                  },
                };
              }
              return filter;
            }),
          };
        }
        return filterGroup;
      })
    );
  };  
  
  const handleAggregateColumnChange = (
    parentIndex: number,
    groupIndex: number,
    aggregateIndex: number,
    idx: number,
    event: SelectChangeEvent<number>
  ) => {
    const IS_WITHIN_LAST_DAYS = 'IsWithinLastDays';
    const IS_PRIOR_LAST_DAYS = 'IsPriorToLastDays';
  
    const selectedFilter = filterOptions.find((filter) => filter.id === Number(event.target.value));
  
    if (!selectedFilter) return;
  
    setShopifyIntegrationOrderFilters((prev) =>
      prev.map((filterGroup, groupIdx) => {
        if (groupIdx === parentIndex) {
          return {
            ...filterGroup,
            integrationOrderFilters: filterGroup.integrationOrderFilters.map((filter, filterIdx) => {
              if (filterIdx === groupIndex && filter.dataSourceFilter?.aggregateFilters) {
                const updatedAggregates = filter.dataSourceFilter.aggregateFilters.map((aggGroup, aggIdx) => {
                  if (aggIdx === aggregateIndex) {
                    const updatedIntegrationOrderFilters = aggGroup.integrationOrderFilters.map((f, fIdx) => {
                      if (fIdx === idx) {
                        if (selectedFilter.name === IS_PRIOR_LAST_DAYS || selectedFilter.name === IS_WITHIN_LAST_DAYS) {
                          return {
                            ...f,
                            dataSourceFilter: selectedFilter,
                            operator: {
                              id: 1,
                              operator: "Equals",
                              operatorShortName: "is equal to",
                              showUi: true,
                            },
                            value: '',
                          };
                        }
  
                        return {
                          ...f,
                          dataSourceFilter: selectedFilter,
                          operator: undefined,
                          value: '',
                        };
                      }
                      return f;
                    });
  
                    return {
                      ...aggGroup,
                      integrationOrderFilters: updatedIntegrationOrderFilters,
                    };
                  }
                  return aggGroup;
                });
  
                return {
                  ...filter,
                  dataSourceFilter: {
                    ...filter.dataSourceFilter,
                    aggregateFilters: updatedAggregates,
                  },
                };
              }
              return filter;
            }),
          };
        }
        return filterGroup;
      })
    );
  };
  
  const handleAggregateOperatorChange = (
    parentIndex: number,
    groupIndex: number,
    aggregateIndex: number,
    idx: number,
    event: SelectChangeEvent<number>
  ) => {
    setShopifyIntegrationOrderFilters((prev) =>
      prev.map((filterGroup, groupIdx) => {
        if (groupIdx === parentIndex) {
          return {
            ...filterGroup,
            integrationOrderFilters: filterGroup.integrationOrderFilters.map((filter, filterIdx) => {
              if (filterIdx === groupIndex && filter.dataSourceFilter?.aggregateFilters) {
                const updatedAggregates = filter.dataSourceFilter.aggregateFilters.map((aggGroup, aggIdx) => {
                  if (aggIdx === aggregateIndex) {
                    const updatedIntegrationOrderFilters = aggGroup.integrationOrderFilters.map((f, fIdx) => {
                      if (fIdx === idx) {
                        const selectedOperator = f?.dataSourceFilter?.operatorOptions.find(
                          (op) => op.id === Number(event.target.value)
                        );
                        
                        return {
                          ...f,
                          operator: selectedOperator || undefined,
                          value: selectedOperator?.operator === 'AllTime' || selectedOperator?.operator === 'IsNotNull' ? '' : f.value,
                        };
                      }
                      return f;
                    });
  
                    return {
                      ...aggGroup,
                      integrationOrderFilters: updatedIntegrationOrderFilters,
                    };
                  }
                  return aggGroup;
                });
  
                return {
                  ...filter,
                  dataSourceFilter: {
                    ...filter.dataSourceFilter,
                    aggregateFilters: updatedAggregates,
                  },
                };
              }
              return filter;
            }),
          };
        }
        return filterGroup;
      })
    );
  };
  
  const handleAggregateValueChange = (
    parentIndex: number,
    groupIndex: number,
    aggregateIndex: number,
    idx: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setShopifyIntegrationOrderFilters((prev) =>
      prev.map((filterGroup, groupIdx) => {
        if (groupIdx === parentIndex) {
          return {
            ...filterGroup,
            integrationOrderFilters: filterGroup.integrationOrderFilters.map((filter, filterIdx) => {
              if (filterIdx === groupIndex && filter.dataSourceFilter?.aggregateFilters) {
                const updatedAggregates = filter.dataSourceFilter.aggregateFilters.map((aggGroup, aggIdx) => {
                  if (aggIdx === aggregateIndex) {
                    const updatedIntegrationOrderFilters = aggGroup.integrationOrderFilters.map((f, fIdx) => {
                      if (fIdx === idx) {
                        return {
                          ...f,
                          value: event.target.value,
                        };
                      }
                      return f;
                    });
  
                    return {
                      ...aggGroup,
                      integrationOrderFilters: updatedIntegrationOrderFilters,
                    };
                  }
                  return aggGroup;
                });
  
                return {
                  ...filter,
                  dataSourceFilter: {
                    ...filter.dataSourceFilter,
                    aggregateFilters: updatedAggregates,
                  },
                };
              }
              return filter;
            }),
          };
        }
        return filterGroup;
      })
    );
  };
  
  const handleAddAggregateAnd = (parentIndex: number, groupIndex: number, aggregateIndex: number) => {
    setShopifyIntegrationOrderFilters((prev) =>
      prev.map((filterGroup, grpIdx) => {
        if (grpIdx === parentIndex) {
          return {
            ...filterGroup,
            integrationOrderFilters: filterGroup.integrationOrderFilters.map((filter, idx) => {
              if (idx === groupIndex && filter.dataSourceFilter?.aggregateFilters) {
                const updatedAggregates = filter.dataSourceFilter.aggregateFilters.map((aggFilter, aggIdx) => {
                  if (aggIdx === aggregateIndex) {
                    return {
                      ...aggFilter,
                      integrationOrderFilters: [
                        ...(aggFilter.integrationOrderFilters || []),
                        {
                          dataSourceFilter: undefined,
                          operator: undefined,
                          value: '',
                        },
                      ],
                    };
                  }
                  return aggFilter;
                });
                return {
                  ...filter,
                  dataSourceFilter: {
                    ...filter.dataSourceFilter,
                    aggregateFilters: updatedAggregates,
                  },
                };
              }
              return filter;
            }),
          };
        }
        return filterGroup;
      })
    );
  };
  
  const handleAddAggregateOr = (parentIndex: number, groupIndex: number) => {
    const fixedDateFilter = vmData.filterOptions.find((filter) => filter.name === 'FixedDate') || {
      id: null,
      name: '',
      shortName: '',
      valueType: 'String',
      operatorOptions: [],
      aggregateFilters: [],
    };
  
    setShopifyIntegrationOrderFilters((prev: any) =>
      prev.map((filterGroup: any, grpIdx: number) => {
        if (grpIdx === parentIndex) {
          return {
            ...filterGroup,
            integrationOrderFilters: filterGroup.integrationOrderFilters.map((filter: any, idx: number) => {
              if (idx === groupIndex) {
                const existingAggregates = filter.dataSourceFilter?.aggregateFilters || [];
                return {
                  ...filter,
                  dataSourceFilter: {
                    ...filter.dataSourceFilter,
                    aggregateFilters: [
                      ...existingAggregates,
                      {
                        integrationOrderFilters: [
                          {
                            dataSourceFilter: fixedDateFilter,
                            operator: undefined,
                            value: '',
                          },
                        ],
                      },
                    ],
                  },
                };
              }
              return filter;
            }),
          };
        }
        return filterGroup;
      })
    );
  };
  
  useEffect(() => {
    if (vmData) {
      setFilterOptions(vmData.filterOptions);
    }
  }, [vmData]);

  useEffect(() => {
    if (shopifyIntegrationOrderFilters && shopifyIntegrationOrderFilters.length > 0) {
      sessionStorage.setItem('tmpIntegrationOrderFilters', JSON.stringify(shopifyIntegrationOrderFilters));
    }
  }, [shopifyIntegrationOrderFilters]);

  return (
    <FiltersContext.Provider
      value={{
        shopifyIntegrationOrderFilters,
        setShopifyIntegrationOrderFilters,
        filterOptions,
        handleAddFilter,
        handleAddAnd,
        handleAddOr,
        handleRemoveFilter,
        handleColumnChange,
        handleOperatorChange,
        handleValueChange,
        handleAggregateColumnChange,
        handleAggregateValueChange,
        handleAggregateOperatorChange,
        handleRemoveAggregate,
        handleAddAggregateAnd,
        handleAddAggregateOr
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

const useFilters = () => {
  const context = useContext(FiltersContext);
  if (!context) {
    throw new Error('useFilters must be used within a FiltersProvider');
  }
  return context;
};

export { FiltersProvider, useFilters };
